import React, { Fragment } from "react";

const Input = (props) => {
  const changeInput = async (e) => {
    await props.onChangeInput(e.target.value, props.id);
  };
  return (
    <Fragment>
      <div className="position-relative wrapper__on-input-close">
        <input
          className="wrapper__input-review select"
          type="text"
          value={props.value}
          onChange={changeInput}
        />
        <img
          src={process.env.REACT_APP_URL + "images/xzczx.png"}
          className="close pointer"
          alt=""
          onClick={(e) => props.removeInput(props.id)}
        />
      </div>
      <p className="color__red mb-4 lh-1 font__size--14">
        {props.error ? "The field is required." : null}
      </p>
    </Fragment>
  );
};

export default Input;
