import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { $ } from "react-jquery-plugin";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Redirect, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SvgLine4 from "./svgLine4";

export default class forgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: false,
      image: "./../images/dsadasdasd.png",
      redirect: false,
      forgot: {
        email: "",
      },
    };
  }
  componentDidMount() {}

  componentDidUpdate() {}

  showPassword = () => {
    this.setState({
      password: !this.state.password,
    });

    if (this.state.password) {
      this.setState({
        image: "./../images/dsadasdasd.png",
      });
    } else {
      this.setState({
        image: "./../images/sadsad.png",
      });
    }
  };

  onChangeInput = (e) => {
    let loginInput = { ...this.state.forgot };
    loginInput[e.target.name] = e.target.value;

    this.setState({
      forgot: loginInput,
    });
  };

  btnSubmit = () => {
    axios
      .post(process.env.REACT_APP_API + `forgot-password`, this.state.forgot)
      .then(
        (res) => {
          if (res.data.success == "success") {
            toast.success("Check your email to change password", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          } else {
            $.each(res.data.errors, function (index, item) {
              toast.error(item[0], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
            });
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>WowSee Vault - Forgot Password</title>
        </Helmet>
        <ToastContainer />
        <section className="height__100 min-height-100 p-0 w-100">
          <div className="row justify-content-center justify-content-lg-start h-100 w-100 m-0">
            <div className="col-lg-6 px-0 position-relative">
              <NavLink to="/" className="path__1 d-none d-xl-block">
                <img
                  src="./../images/Full Logo - Transparent - Black.png"
                  alt=""
                />
              </NavLink>
              <div className="h-100 py-5 d-flex justify-content-center align-items-center">
                <div className="row w-100 justify-content-center">
                  <div className="col-md-11">
                    <div className="container px-0 position-relative">
                      <div className="d-block mb-5 d-xl-none">
                        <NavLink to="/">
                          <img
                            src="./../images/Full Logo - Transparent - Black.png"
                            alt=""
                          />
                        </NavLink>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3 className=" semi-bold font__size--28 text__28-1024 text__28-sm text__28-xxs color__darkblue mb-2">
                            Forgot Password
                          </h3>
                          <h5 className="medium lh-2 font__size--16 text__16-1024 text__16-sm color__darkblue opacity__4 mb-4">
                            Don't worry we will help you to get your account
                            back, you just need to send us an email according to
                            your guide and we will do the rest!
                          </h5>

                          <div className="mb-0">
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                              >
                                E-mail Address
                              </label>
                              <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                <input
                                  type="email"
                                  name="email"
                                  onChange={this.onChangeInput}
                                  className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                                  placeholder="Insert your email here"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="d-md-flex justify-content-between align-items-center">
                            <NavLink
                              to="/login"
                              className="d-flex justify-content-start align-items-center my-3 my-md-0"
                            >
                              <img src="./../images/adsasa.png" alt="" />{" "}
                              <span className="font__size--12 semi-bold color__softblue ml-2">
                                Back to login
                              </span>
                            </NavLink>
                            <button
                              onClick={() => this.btnSubmit()}
                              className="font__size--18 text__18-1024 semi-bold color__white btn btn__blue-2 shadow btn__login"
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-lg-block position-relative px-0 bg__darkblue min-height-100">
              <SvgLine4 />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
