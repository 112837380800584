import React, { Component, Fragment } from "react";

const Recognition = (props) => {
  return (
    <Fragment>
      <h5 className="semi-bold font__size--16 text__16-1024 mb-4">
        Recognition Warnings
      </h5>
      <div className="wrapper__recogition-image mb-4">
        <h5 className="semi-bold font__size--14 text__14-1024 mb-4">
          Recognition Warnings
        </h5>
        <hr className="hr__min-1" />
        <h5 className="semi-bold font__size--14 text__14-1024 opacity__5">
          No Warnings
        </h5>
      </div>
      <div className="wrapper__recogition-image mb-4">
        <h5 className="semi-bold font__size--14 text__14-1024 mb-4">
          Text Recognition
        </h5>
        <hr className="hr__min-1" />
        <div className="row mb-2">
          <div className="col-5 my-auto col-md-3">
            <h5 className="semi-bold font__size--14 text__14-1024 opacity__9 m-0">
              Title
            </h5>
          </div>
          <div className="col-7 col-md-9">
            <p className="m-0 font__size--14 text__14-1024 opacity__6">
              No Warnings
            </p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-5 my-auto col-md-3">
            <h5 className="semi-bold font__size--14 text__14-1024 opacity__9 m-0">
              Game Notes
            </h5>
          </div>
          <div className="col-7 col-md-9">
            <p className="m-0 font__size--14 text__14-1024 opacity__6">
              No Warnings
            </p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-5 my-auto col-md-3">
            <h5 className="semi-bold font__size--14 text__14-1024 opacity__9 m-0">
              Description
            </h5>
          </div>
          <div className="col-7 col-md-9">
            <p className="m-0 font__size--14 text__14-1024 opacity__6">
              No Warnings
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Recognition;
