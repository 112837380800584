import React, { Component, Fragment } from "react";
import QaDetail from "./QaDetail";
import { NavLink } from "react-router-dom";
const QaResult = (props) => {
  return (
    <Fragment>
      <div className="">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="semi-bold font__size--14 text__14-1024 m-0">
            APK Version: {props.version.version}
          </h5>
          {props.loading != true ? (
            props.reviewStatus() ? (
              <div className="">
                <div
                  onClick={(e) => props.toogleModal(props.version.id)}
                  className="pointer d-flex justify-content-end align-items-center font__size--14 color__red ml-3"
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/weeewa.png"} className="mr-1"
                    alt=""
                  />
                  <span className="semi-bold lh-1 mt-1">Delete</span>
                </div>
              </div>
            ) : null
          ) : null}
        </div>
        <hr className="" />
        <div className="mb-3">
          <h5 className="semi-bold font__size--14 text__14-1024">
            Changelog :
          </h5>
          {props.review_data.map((rev) => {
            if (rev.apk_id == props.version.id) {
              return props.changelog_data.map((itemWrap) => {
                return itemWrap.map((item, i) => {
                  if (item.apk_review_id == rev.id) {
                    return (
                      <p className="font__size--12 text__12-1024 opacity__7 mb-0">
                        {i + 1}. {item.text}
                      </p>
                    );
                  }
                });
              });
            }
          })}
        </div>
        <h5 className="semi-bold font__size--14 text__14-1024 mb-3">
          Results :
        </h5>

        {props.review_data.map((rev) => {
          if (rev.apk_id == props.version.id) {
            return props.result_data.map((item) => {
              return item.map((data) => {
                if (data.apk_review_id == rev.id) {
                  return (
                    <QaDetail
                      result={data}
                      version_id={props.version.id}
                      slug={props.slug}
                      contentDetail_data={props.contentDetail_data}
                      toogleModalResult={(e) => props.toogleModalResult(e)}
                    />
                  );
                }
              });
            });
          }
        })}
      </div>
      <div className="wrapper__spacing-qa"></div>
    </Fragment>
  );
};

export default QaResult;
