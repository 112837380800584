import React, { Fragment, useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const PreviewImageBoxQa = (props) => {
  const [click, setClick] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    setImage(props.images);
  }, []);
  return (
    <Fragment>
      {props.type == "file" ? (
        <div className="wrapper__document-download text-center d-flex justify-content-center align-items-center">
          <div>
            <img src={process.env.REACT_APP_URL + "images/Vector.png"} alt="" />
            <h5 className="semi-bold my-3 font__size--14 text__14-1024">
              Documentation
            </h5>
            <a
              href={props.images}
              className="btn__download font__size--12 semi-bold color__blue pointer d-inline-block"
            >
              <div className="d-flex justify-content-center align-items-center">
                Download File
                <svg
                  className="ml-2"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75"
                    stroke="#1D4ED8"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.08337 5.83325L7.00004 8.74992L9.91671 5.83325"
                    stroke="#1D4ED8"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 8.75V1.75"
                    stroke="#1D4ED8"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      ) : (
        <Fragment>
          <img
            src={props.images}
            onClick={() => setClick(!click)}
            className="images__content-media-QA  my-3 pointer"
            alt=""
          />
        </Fragment>
      )}

      {click && (
        <Lightbox
          mainSrc={props.images}
          onCloseRequest={() => setClick(!click)}
        />
      )}
    </Fragment>
  );
};

export default PreviewImageBoxQa;
