import React, { Fragment } from "react";

const tagGame = (props) => {
  const listImage = [
    {
      name: "action",
      logo: "images/crossed 1.png",
    },
    {
      name: "adventure",
      logo: "images/compass 1.png",
    },
    {
      name: "educational",
      logo: "images/college-graduation 1.png",
    },
    {
      name: "puzzle",
      logo: "images/extension 1.png",
    },
    {
      name: "racing",
      logo: "images/checkered-flag 1.png",
    },
    {
      name: "sport",
      logo: "images/ball-of-basketball 1.png",
    },
    {
      name: "story",
      logo: "images/baby-book 1.png",
    },
    {
      name: "strategy",
      logo: "images/chess 1.png",
    },
  ];

  const getLogo = (e) => {
    return listImage.filter((val) => val.name.includes(e))[0].logo;
  };

  return (
    <Fragment>
      <div
        className={
          "wrapper__tagGame d-flex justify-content-start align-items-center " +
          (props.type ? props.type : "")
        }
      >
        <img
          src={process.env.REACT_APP_URL + getLogo(props.name.toLowerCase())}
          alt=""
        />
        <span className="semi-bold font__size--12 text__12-1024 color__softblue ml-2 text-capitalize">
          {props.name}
        </span>
      </div>
    </Fragment>
  );
};

export default tagGame;
