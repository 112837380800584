import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";

const Loading = (props) => {
  return (
    <Fragment>
      {props.status == "card" ? (
        <div className="wrapper__list-game position-relative w-100">
          <div className="loader">
            <ContentLoader
              speed={2}
              width={400}
              height={400}
              viewBox="0 0 400 400"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="7" y="1" rx="3" ry="3" width="386" height="192" />
              <rect x="7" y="251" rx="3" ry="3" width="312" height="12" />
              <rect x="7" y="216" rx="3" ry="3" width="354" height="13" />
              <rect x="6" y="289" rx="11" ry="11" width="145" height="40" />
              <rect x="179" y="300" rx="3" ry="3" width="2158" height="12" />
              <rect x="7" y="356" rx="11" ry="11" width="145" height="40" />
            </ContentLoader>
          </div>
        </div>
      ) : (
        ""
      )}

      {props.status == "lineProfile" ? (
        <div className="wrapper__card-list w-100 mb-3">
          <div className="row">
            <div className="my-auto col-md-4">
              <div className="wrapper__line-profile profile">
                <ContentLoader
                  speed={2}
                  width={476}
                  height={124}
                  viewBox="0 0 476 124"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="140" y="61" rx="3" ry="3" width="206" height="9" />
                  <rect x="140" y="89" rx="3" ry="3" width="122" height="9" />
                  <circle cx="62" cy="76" r="47" />
                </ContentLoader>
              </div>
            </div>
            <div className="my-auto col-2">
              <div className="wrapper__line-profile line">
                <ContentLoader
                  speed={2}
                  width={476}
                  height={124}
                  viewBox="0 0 476 124"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="5" y="57" rx="3" ry="3" width="470" height="21" />
                </ContentLoader>
              </div>
            </div>
            <div className="my-auto col">
              <div className="wrapper__line-profile line">
                <ContentLoader
                  speed={2}
                  width={476}
                  height={124}
                  viewBox="0 0 476 124"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="5" y="57" rx="3" ry="3" width="470" height="21" />
                </ContentLoader>
              </div>
            </div>
            <div className="my-auto col">
              <div className="wrapper__line-profile line">
                <ContentLoader
                  speed={2}
                  width={476}
                  height={124}
                  viewBox="0 0 476 124"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="5" y="57" rx="3" ry="3" width="470" height="21" />
                </ContentLoader>
              </div>
            </div>
            <div className="my-auto col">
              <div className="wrapper__line-profile line">
                <ContentLoader
                  speed={2}
                  width={476}
                  height={124}
                  viewBox="0 0 476 124"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="5" y="57" rx="3" ry="3" width="470" height="21" />
                </ContentLoader>
              </div>
            </div>
            <div className="my-auto col-1">
              <div className="wrapper__line-profile line">
                <ContentLoader
                  speed={2}
                  width={476}
                  height={124}
                  viewBox="0 0 476 124"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <circle cx="62" cy="76" r="47" />
                </ContentLoader>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {props.status == "line" ? (
        <ContentLoader
          speed={2}
          width={476}
          height={16}
          viewBox="0 0 476 16"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="12" rx="3" ry="3" width="410" height="6" />
        </ContentLoader>
      ) : null}

      {props.status == "need" ? (
        <div className="wrapper__list-game position-relative w-100 mb-3">
          <div className="loader__need">
            <ContentLoader
              speed={2}
              width={476}
              height={200}
              viewBox="0 0 476 200"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="4" y="6" rx="3" ry="3" width="129" height="102" />
              <rect x="160" y="19" rx="3" ry="3" width="204" height="31" />
              <rect x="160" y="73" rx="3" ry="3" width="162" height="31" />
              <rect x="5" y="149" rx="16" ry="16" width="171" height="53" />
              <rect x="201" y="147" rx="16" ry="16" width="171" height="53" />
            </ContentLoader>
          </div>
        </div>
      ) : null}

      {props.status == "hero" ? (
        <div className="wrapper__loader-hero position-relative">
          <ContentLoader
            speed={2}
            width={1000}
            height={400}
            viewBox="0 0 1000 400"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="0" y="0" rx="0" ry="0" width="1000" height="400" />
          </ContentLoader>
        </div>
      ) : null}
      {props.status == "apk" ? (
        <div className="wrapper__loader-apk position-relative">
          <ContentLoader
            speed={2}
            width={410}
            height={81}
            viewBox="0 0 410 81"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="6" y="0" rx="0" ry="0" width="140" height="81" />
            <rect x="161" y="2" rx="0" ry="0" width="169" height="81" />
            <rect x="344" y="4" rx="0" ry="0" width="169" height="81" />
          </ContentLoader>
        </div>
      ) : null}
    </Fragment>
  );
};

export default Loading;
