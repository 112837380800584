import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "./../../../component/dashboard/sidebar/index";
import Navbar from "./../../../component/dashboard/navbar/index";
import Footer from "./../../../component/dashboard/footer/index";

export default class account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
      loading: false,
      modal: false,
      userData: {
        name: "",
        email: "",
        username: "",
        image: [],
        oneChange: [],
      },
    };
    this.textInput = React.createRef();
  }

  imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let inputData = { ...this.state.userData };
      inputData["image"] = e.target.files[0];
      inputData["oneChange"] = URL.createObjectURL(e.target.files[0]);

      this.setState({
        userData: inputData,
      });
    }
  };

  clickImage = () => {
    this.textInput.current.click();
  };

  getDataUser = (token) => {
    axios
      .get(process.env.REACT_APP_API + `user`, {
        headers: {
          Authorization: "Bearer " + token,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(
        (res) => {
          let inputData = { ...this.state.userData };
          inputData["name"] = res.data.name;
          inputData["email"] = res.data.email;
          inputData["username"] = res.data.username;
          inputData["image"] = res.data.image;

          this.setState({
            userData: inputData,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  toogleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  updateUser = () => {
    const cookies = new Cookies();

    const data = new FormData();
    data.append("image", this.state.userData.image);
    data.append("name", this.state.userData.name);
    data.append("username", this.state.userData.username);
    data.append("email", this.state.userData.email);

    axios
      .post(process.env.REACT_APP_API + `account/data/update`, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(
        (res) => {
          if (res.data.success == "success") {
            toast.success("your account has been successfully updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            localStorage.setItem("userData", JSON.stringify(res.data.data));
            window.location.reload();
          } else {
            if (res.data.success == false) {
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
            } else {
              $.each(res.data.errors, function (index, item) {
                toast.error(item[0], {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                });
              });
            }
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  deleteAccount = () => {
    const cookies = new Cookies();
    axios
      .post(
        process.env.REACT_APP_API + `account/delete`,
        { email: JSON.parse(localStorage.getItem("userData")).email },
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      )
      .then(
        (res) => {
          if (res.data.success == "success") {
            toast.success("Your account has been deleted successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            const cookies = new Cookies();
            localStorage.clear();
            cookies.remove("jwt", { path: "/" });
            window.location.replace(process.env.REACT_APP_URL + "/");
          } else {
            if (res.data.success == false) {
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
            } else {
              $.each(res.data.errors, function (index, item) {
                toast.error(item[0], {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                });
              });
            }
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  onChangeInput = (e) => {
    let loginInput = { ...this.state.userData };
    loginInput[e.target.name] = e.target.value;

    this.setState({
      userData: loginInput,
    });
  };

  componentDidMount() {
    const cookies = new Cookies();
    this.getDataUser(cookies.get("jwt"));
  }

  componentDidUpdate() {}

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className="overflow-hidden dashboard">
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Account"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-5 col-lg-4 mb-4 mb-md-0">
                      <div className="wrapper__wrap-setting">
                        <h5 className="semi-bold font__size--18 text__18-1024">
                          Settings
                        </h5>
                        <NavLink
                          to="/dashboard/setting/account"
                          className="list d-inline-block w-100 color__black pointer"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="semi-bold opacity__7">
                              Account Settings
                            </span>
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "images/row x5F 7 1.png"
                              }
                              alt=""
                            />
                          </div>
                        </NavLink>
                        <NavLink
                          to="/dashboard/setting/password"
                          className="list d-inline-block w-100 color__black pointer"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="semi-bold opacity__7">
                              Password
                            </span>
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "images/row x5F 7 1.png"
                              }
                              alt=""
                            />
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-md-7 col-xl-6">
                      <div className="wrapper__form-setting">
                        <h4 className="font__size--24 text__24-1024 semi-bold">
                          Account Settings
                        </h4>
                        <p className="font__size--14 lh-1 m-0">
                          People visiting your profile will see the following
                          info
                        </p>
                        <hr />
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                          >
                            Photo
                          </label>
                          <div className="position-relative wrapper__input-log profile d-flex justify-content-start align-items-center">
                            {this.state.userData.image == null ||
                            this.state.userData.image == "" ? (
                              <img
                                src={
                                  process.env.REACT_APP_URL +
                                  "images/29213195-male-silhouette-avatar-profile-picture.jpg"
                                }
                                className="image mr-3"
                                alt=""
                              />
                            ) : this.state.userData.oneChange.length > 0 ? (
                              <img
                                src={URL.createObjectURL(
                                  this.state.userData.image
                                )}
                                className="image mr-3"
                                alt=""
                              />
                            ) : (
                              <img
                                src={this.state.userData.image}
                                className="image mr-3"
                                alt=""
                              />
                            )}
                            <input
                              type="file"
                              name="image"
                              ref={this.textInput}
                              onChange={this.imageChange}
                              className="form-control wrapper__hide input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                              placeholder="Insert your text here"
                            />
                            <button
                              onClick={this.clickImage}
                              className="semi-bold font__size--12 text__12-1024 btn__change"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                          >
                            Full Name
                          </label>
                          <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                            <input
                              type="text"
                              name="name"
                              onChange={this.onChangeInput}
                              value={this.state.userData.name}
                              className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                              placeholder="Insert your text here"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                          >
                            Username
                          </label>
                          <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                            <input
                              type="text"
                              name="username"
                              onChange={this.onChangeInput}
                              value={this.state.userData.username}
                              className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                              placeholder="Insert your text here"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                          >
                            Email
                          </label>
                          <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                            <input
                              type="email"
                              name="email"
                              onChange={this.onChangeInput}
                              value={this.state.userData.email}
                              className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                              placeholder="Insert your text here"
                            />
                          </div>
                        </div>
                        <hr className="mt-5" />
                        <div className="d-lg-flex justify-content-between align-content-center">
                          <div>
                            <h5 className="bold font__size--14 mb-1">
                              Delete account
                            </h5>
                            <p className="font__size--14 m-0 lh-1">
                              Delete your account and account data
                            </p>
                          </div>
                          <a
                            href="#!"
                            onClick={this.toogleModal}
                            className="ponter d-inline-block mt-3 mt-lg-0 btn__delete font__size--12 text__12-1024 semi-bold"
                          >
                            Delete Account
                          </a>
                        </div>
                        <hr className="mb-5" />
                        <button
                          onClick={this.updateUser}
                          className="semi-bold font__size--18 text__18-1024 color__white btn__save"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>

          <div
            className={
              "modal wrapper__modal-app fade " +
              (this.state.modal ? "show" : "")
            }
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h5 className="font__size--16 semi-bold mb-3">
                    Are you sure you want to delete your account?
                  </h5>
                  <div className="d-flex justify-content-center align-items-center">
                    <a
                      onClick={this.toogleModal}
                      href="#!"
                      className="btn btn__modal btn__softblue color__white shadow font__size--14 mr-3"
                    >
                      Cancel
                    </a>
                    <a
                      href="#!"
                      onClick={this.deleteAccount}
                      className="btn btn__modal btn__red color__white shadow font__size--14"
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
