import React, { Fragment, useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import UserContext from "../../../context/UserContext";
import CardSearch from "../card/CardSearch";

const Index = (props) => {
  const logout = () => {
    const cookies = new Cookies();
    localStorage.clear();
    cookies.remove("jwt", { path: "/" });
    window.location.replace(process.env.REACT_APP_URL + "/");
  };

  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [navbarSearch, setNavbarSearch] = useState(false);
  const [newGame, setNewGame] = useState();
  const [searchGame, setSearchGame] = useState();
  const [search, setSearch] = useState();

  const getDataUser = (token) => {
    axios
      .get(process.env.REACT_APP_API + `user`, {
        headers: {
          Authorization: "Bearer " + token,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(
        (res) => {
          setImage(res.data.image);
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  const getNewGame = (token) => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API + `game/search/new-game`, {
        headers: {
          Authorization: "Bearer " + token,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(
        (res) => {
          setNewGame(res.data.data);
          setLoading(false);
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  const searchItem = () => {
    const cookies = new Cookies();
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_API + `game/search/gameList`,
        {
          search: search,
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      )
      .then(
        (res) => {
          setSearchGame(res.data.data);
          setLoading(false);
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  useEffect(() => {
    const cookies = new Cookies();
    getDataUser(cookies.get("jwt"));
    getNewGame(cookies.get("jwt"));
  }, []);

  const DataUser = () => {
    const value = useContext(UserContext);
    return value.user;
  };

  const onSearch = (e) => {
    if (props.onSearch) {
      props.onSearch(e.target.value);
    } else {
      setSearch(e.target.value);
      searchItem();
    }
  };
  return (
    <Fragment>
      <div className="wrapper__navbar">
        <div className="container">
          <div className="text-center d-block d-xl-none pt-3 pb-1">
            <NavLink activeClassName="on" to="/dashboard">
              <img
                src={
                  process.env.REACT_APP_URL +
                  "images/Full Logo - Transparent - Black.png"
                }
                alt=""
              />
            </NavLink>
          </div>
          <div className="menu">
            <div className="items position-relative">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                      onClick={() => props.toogleSidenav()}
                      src={process.env.REACT_APP_URL + "images/Icon - Menu.png"}
                      className="pointer mr-3 d-block d-xl-none"
                      alt=""
                    />
                    <div className="d-flex justify-content-start align-items-center wrapper__nav-point font__size--14 text__14-1024">
                      <a href="#!">Home</a>
                      <a href="#!">{props.breadcrumb}</a>
                    </div>
                  </div>
                  <div
                    onClick={() => props.toogleSearch()}
                    className="ml-3 wrapper__search d-flex justify-content-start align-items-center position-relative"
                  >
                    <img
                      src={process.env.REACT_APP_URL + "images/Icon.png"}
                      className=""
                      alt=""
                    />{" "}
                    <input
                      type="text"
                      name="search"
                      required="true"
                      autoComplete={"off"}
                      onChange={onSearch}
                      value={props.valSearch}
                      placeholder="Search"
                      className="semi-bold wrapper__search-form-input font__size--12 color__softblue ml-2 d-none d-md-block"
                    />
                    {props.type == "list" ? (
                      <div className="wrapper__list-search">
                        <div className="position-relative w-100 h-100">
                          {loading ? (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "images/output-onlinegiftools.gif"
                              }
                              className="loading"
                              alt=""
                            />
                          ) : search ? (
                            searchGame.length > 0 ? (
                              searchGame.map((obj) => {
                                return <CardSearch item={obj} />;
                              })
                            ) : (
                              <span className="notfound opacity__7">
                                Game Not Found
                              </span>
                            )
                          ) : (
                            <Fragment>
                              <h5 className="font__size--16 semi-bold mb-3">
                                New Game
                              </h5>
                              {newGame.map((obj) => {
                                return <CardSearch item={obj} />;
                              })}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="">
                  <div className="wrapper__nav-right d-flex justify-content-end align-items-center">
                    {DataUser().role == "admin" ||
                    DataUser().type == "creator" ? (
                      <NavLink
                        activeClassName="on"
                        to="/dashboard/upload"
                        className="grid btn btn__blue btn__upload shadow color__white"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src={process.env.REACT_APP_URL + "images/assa.png"}
                            alt=""
                          />
                          <span className="d-none d-lg-block semi-bold font__size--12 color__white ml-2">
                            Upload Game
                          </span>
                        </div>
                      </NavLink>
                    ) : null}

                    <div className="grid">
                      <div className="dropdown">
                        <div
                          className="wrapper__profile-menu d-flex justify-content-end align-items-center pointer"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="mr-2 text-right d-xl-block d-none">
                            <h5 className="semi-bold lh-1 font__size--12 mb-0 text-capitalize">
                              {DataUser().username}
                            </h5>
                            <p className="font__size--10 m-0 lh-1 text-capitalize">
                              {DataUser().role == "admin"
                                ? DataUser().role
                                : DataUser().type}
                            </p>
                          </div>

                          {image == "" || image == null ? (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "images/29213195-male-silhouette-avatar-profile-picture.jpg"
                              }
                              className="profile mr-2"
                              alt=""
                            />
                          ) : (
                            <img src={image} className="profile mr-2" alt="" />
                          )}
                          <img
                            src={process.env.REACT_APP_URL + "images/asa.png"}
                            className="arrow"
                            alt=""
                          />
                        </div>
                        <div
                          className="dropdown-menu animate__menu dropdown-menu-right"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {DataUser().role == "admin" ||
                          DataUser().type == "creator" ? (
                            <Fragment>
                              <NavLink
                                activeClassName="on"
                                className="dropdown-item"
                                to="/dashboard/my-game"
                              >
                                <div className="d-flrx justify-content-center align-items-center">
                                  <img
                                    src={
                                      process.env.REACT_APP_URL +
                                      "images/ssaff.png"
                                    }
                                    alt=""
                                  />
                                  <span className="semi-bold font__size--12 ml-2">
                                    My Game
                                  </span>
                                </div>
                              </NavLink>
                              <hr className="border-0" />
                            </Fragment>
                          ) : null}

                          <NavLink
                            activeClassName="on"
                            className="dropdown-item"
                            to="/dashboard/setting/account"
                          >
                            <div className="d-flrx justify-content-center align-items-center">
                              <img
                                src={
                                  process.env.REACT_APP_URL + "images/sdasd.png"
                                }
                                alt=""
                              />
                              <span className="semi-bold font__size--12 ml-2">
                                Settings
                              </span>
                            </div>
                          </NavLink>
                          <hr />
                          <a
                            href="#!"
                            onClick={() => logout()}
                            className="dropdown-item logout"
                            to="/login"
                          >
                            <div className="d-flrx justify-content-center align-items-center">
                              <img
                                src={
                                  process.env.REACT_APP_URL +
                                  "images/sasasas.png"
                                }
                                alt=""
                              />
                              <span className="semi-bold font__size--12 ml-2">
                                Logeout
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "wrapper__search-form d-block d-md-none p-0 " +
                  (props.search ? "active" : "")
                }
              >
                <div className="position-relative" style={{ padding: "1rem" }}>
                  <div className="search d-flex justify-content-start align-items-center">
                    <img
                      src={process.env.REACT_APP_URL + "images/Icon.png"}
                      className=""
                      alt=""
                    />{" "}
                    <input
                      type="text"
                      placeholder="Search"
                      name="search"
                      onChange={onSearch}
                      value={props.valSearch}
                      required="true"
                      autoComplete={"off"}
                      className="semi-bold wrapper__search-form-input font__size--12 color__softblue ml-2"
                    />
                    {props.type == "list" ? (
                      <div className="wrapper__list-search mobile">
                        <div className="position-relative w-100 h-100">
                          {loading ? (
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "images/output-onlinegiftools.gif"
                              }
                              className="loading"
                              alt=""
                            />
                          ) : search ? (
                            searchGame.length > 0 ? (
                              searchGame.map((obj) => {
                                return <CardSearch item={obj} />;
                              })
                            ) : (
                              <span className="notfound opacity__7">
                                Game Not Found
                              </span>
                            )
                          ) : (
                            <Fragment>
                              <h5 className="font__size--16 semi-bold mb-3">
                                New Game
                              </h5>
                              {newGame.map((obj) => {
                                return <CardSearch item={obj} />;
                              })}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Index;
