import React, { useState, Fragment } from "react";
import dateFormat from "dateformat";
import PreviewImageBox from "../image/PreviewImageBox";
import { NavLink } from "react-router-dom";

const QaDetail = (props) => {
  const [showing, setShowing] = useState(props.status ? props.status : false);

  return (
    <Fragment>
      <div className="wrapper__qa-detail mb-3">
        <div
          className={
            "tab__qa d-flex justify-content-between align-items-center pointer " +
            (showing ? "showing" : "")
          }
          onClick={() => setShowing(!showing)}
        >
          <div className="detail d-flex justify-content-start align-items-center">
            <div className="item">
              <h5 className="semi-bold font__size--14 text__14-1024 opacity__9">
                Date
              </h5>
              <p className="font__size--14 text__14-1024 m-0 opacity__7">
                {dateFormat(props.result.created_at, "yyyy-mm-dd")}
              </p>
            </div>
            <div className="item d-none d-sm-block">
              <h5 className="semi-bold font__size--14 text__14-1024 opacity__9">
                Device
              </h5>
              <p className="font__size--14 text__14-1024 m-0 opacity__7">
                {props.result.device}
              </p>
            </div>
            <div className="item d-none d-sm-block">
              <h5 className="semi-bold font__size--14 text__14-1024 opacity__9">
                Android Version
              </h5>
              <p className="font__size--14 text__14-1024 m-0 opacity__7 ">
                {props.result.android}
              </p>
            </div>
            <div className="item">
              <div
                className={
                  "btn__status text-center semi-bold font__size--12 text__12-1024 color__white text-capitalize " +
                  (props.result.status == "pass" ? "pass" : "fail")
                }
              >
                {props.result.status}
              </div>
            </div>
            <div className="item">
              <div className="d-flex justify-content-end align-items-center">
                <NavLink
                  to={
                    "/dashboard/qa/edit-review/" +
                    props.slug +
                    "/" +
                    props.version_id +
                    "/" +
                    props.result.id
                  }
                  className="pointer"
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/dfdsfds.png"}
                    className="mr-1"
                    alt=""
                  />
                </NavLink>
                <div
                  onClick={(e) => props.toogleModalResult(props.result.id)}
                  className="pointer color__red ml-3"
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/sdfdsf.png"}
                    className="mr-1"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            src={process.env.REACT_APP_URL + "images/asa.png"}
            className="arrow"
            alt=""
          />
        </div>
        <div className="des__qa">
          <div className="qa__ mt-3 table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th
                    className="semi-bold font__size--12 text__12-1024 opacity__9"
                    scope="col"
                  >
                    Item{" "}
                  </th>
                  <th
                    className="semi-bold font__size--12 text__12-1024 opacity__9"
                    scope="col"
                  >
                    Result
                  </th>
                  <th
                    className="semi-bold font__size--12 text__12-1024 opacity__9"
                    scope="col"
                  >
                    Media
                  </th>
                  <th
                    className="semi-bold font__size--12 text__12-1024 opacity__9"
                    scope="col"
                  >
                    Comment
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.contentDetail_data.map((item) => {
                  return item.map((data) => {
                    if (data.results_id == props.result.id) {
                      return (
                        <tr>
                          <th
                            className="font__size--10 normal align-middle"
                            scope="row"
                          >
                            {data.item}
                          </th>
                          <td className="font__size--10 normal align-middle">
                            <div
                              className={
                                "btn__status small-table text-center semi-bold font__size--10 color__white text-capitalize " +
                                (data.status == "pass" ? "pass" : "fail")
                              }
                            >
                              {data.status}
                            </div>
                          </td>
                          <td className="font__size--10 normal align-middle">
                            {data.type_file == "file" ? (
                              <a href={data.media} className="font__size--10 color__blue pointer d-inline-block">
                                <u>Download Document</u>
                              </a>
                            ) : data.media ? (
                              <PreviewImageBox
                                status="text"
                                text="Show"
                                images={data.media}
                              />
                            ) : null}
                          </td>
                          <td className="font__size--10 normal align-middle">
                            {data.comment}
                          </td>
                        </tr>
                      );
                    }
                  });
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QaDetail;
