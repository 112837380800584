import React, { Component, Fragment, useEffect, useState, useRef } from "react";
import Slider from "react-slick";

const PreviewImage = (props) => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  //i dont seem to need this
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    focusOnSelect: true,
    slidesToScroll: 4,
  };
  return (
    <Fragment>
      <div
        className={
          "modal wrapper__modal-app fade " + (props.showing ? "show" : "")
        }
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl wrapper__dialog-modal modal-dialog-centered"
          role="document"
        >
          <div className="modal-content wrapper__preview-game">
            <div className="modal-body body position-relative">
              <div
                className="icon-close bg__white pointer"
                onClick={() => props.tooglePrivew()}
              >
                <img
                  src={process.env.REACT_APP_URL + "images/sadad.png"}
                  alt=""
                />
              </div>
              <Slider {...settings} asNavFor={nav2} ref={slider1}>
                {props.item.map(function (object, i) {
                  return object.type == "screenshoot" ? (
                    <div>
                      <img src={object.url} alt="" className="preview" />
                    </div>
                  ) : (
                    ""
                  );
                })}
              </Slider>
              <div className="mt-2 wrapper__nav-preview">
                <Slider {...settings2} asNavFor={nav1} ref={slider2}>
                  {props.item.map(function (object, i) {
                    return object.type == "screenshoot" ? (
                      <div>
                        <div className="items">
                          <div className="">
                            <img src={object.url} alt="" className="preview" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PreviewImage;
