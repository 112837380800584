import React, { Component, Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { $ } from "react-jquery-plugin";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Redirect, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

import SvgLine from "./svgLine";
import UserContext from "../../context/UserContext";

export default class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: false,
      image: "./../images/dsadasdasd.png",
      redirect: false,
      type: "",
      role: "",
      login: {
        email: "",
        password: "",
      },
    };
  }

  setUser = () => {
    UserContext.setUser();
  };

  componentDidMount() {
    AOS.init({
      once: true,
    });
  }

  showPassword = () => {
    this.setState({
      password: !this.state.password,
    });

    if (this.state.password) {
      this.setState({
        image: "./../images/dsadasdasd.png",
      });
    } else {
      this.setState({
        image: "./../images/sadsad.png",
      });
    }
  };

  onChangeInput = (e) => {
    let loginInput = { ...this.state.login };
    loginInput[e.target.name] = e.target.value;

    this.setState({
      login: loginInput,
    });
  };

  btnLogin = () => {
    axios.post(process.env.REACT_APP_API + `login`, this.state.login).then(
      (res) => {
        if (res.data.success == "success") {
          toast.success("you have successfully logged in", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });

          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userData", JSON.stringify(res.data.data));

          const cookies = new Cookies();
          cookies.set("jwt", res.data.token);

          this.setState({
            type: res.data.data["type"],
            role: res.data.data["role"],
          });

          this.setState({
            redirect: true,
          });
        } else {
          if (res.data.success == false) {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          } else {
            $.each(res.data.errors, function (index, item) {
              toast.error(item[0], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
            });
          }
        }
      },
      (err) => {
        console.log("error ", err);
      }
    );
  };

  render() {
    if (this.state.redirect) {
      if (this.state.type == null && this.state.role != "admin") {
        return <Redirect to="/type-user" />;
      }

      if (this.state.role == "user" && this.state.type == "viwer") {
        return <Redirect to="/dashboard/library" />;
      }

      return <Redirect to="/dashboard" />;
    }
    return (
      <Fragment>
        <Helmet>
          <title>WowSee Vault - Login</title>
        </Helmet>
        <ToastContainer />
        <section className="height__100 min-height-100 p-0 w-100">
          <div className="row justify-content-center justify-content-lg-start h-100 w-100 m-0">
            <div className="col-lg-6 px-0 position-relative">
              <NavLink to="/" className="path__1 d-none d-xl-block">
                <img
                  src="./../images/Full Logo - Transparent - Black.png"
                  alt=""
                />
              </NavLink>
              <div className="h-100 my-lg-4 py-5 d-flex justify-content-center align-items-center">
                <div className="row w-100 justify-content-center">
                  <div className="col-md-11">
                    <div className="container px-0 position-relative">
                      <div className="d-block mb-5 d-xl-none">
                        <NavLink to="/">
                          <img
                            src="./../images/Full Logo - Transparent - Black.png"
                            alt=""
                          />
                        </NavLink>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3 className=" semi-bold font__size--28 text__28-1024 text__28-sm text__28-xxs color__darkblue mb-2">
                            Sign In to your account
                          </h3>
                          <h5 className="medium lh-2 font__size--16 text__16-1024 text__16-sm color__darkblue opacity__4 mb-4">
                            Please a in the data below to enter your account
                          </h5>

                          <div className="mb-0">
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                              >
                                Email
                              </label>
                              <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                <input
                                  type="email"
                                  onChange={this.onChangeInput}
                                  name="email"
                                  value={this.state.login.email}
                                  className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                                  placeholder="Insert your email here"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                              >
                                Password
                              </label>
                              <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                <input
                                  onChange={this.onChangeInput}
                                  type={
                                    this.state.password ? "text" : "password"
                                  }
                                  autoComplete="off"
                                  name="password"
                                  className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 "
                                  placeholder="Insert your password here"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center my-3">
                            <div className="wrapper__remember d-flex justify-content-start align-items-center">
                              <input
                                type="checkbox"
                                id="remember"
                                name="remember"
                                value="remember"
                              />
                              <label
                                htmlFor="remember"
                                className="ml-2 font__size--14"
                              >
                                Remember me
                              </label>
                            </div>
                            <NavLink
                              to="/forgot-password"
                              className="font__size--12 semi-bold color__black"
                            >
                              Forgot Password?
                            </NavLink>
                          </div>
                          <button
                            onClick={() => this.btnLogin()}
                            className=" font__size--18 text__18-1024 semi-bold color__white btn btn__blue-2 shadow btn__login"
                          >
                            Sign in
                          </button>
                          <div className="font__size--14 mt-4">
                            Don’t have an account?{" "}
                            <NavLink to="/sign-up">Sign Up</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-lg-block position-relative px-0 bg__darkblue min-height-100">
              <SvgLine />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
