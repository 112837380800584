import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "./../../../component/dashboard/sidebar/index";
import Navbar from "./../../../component/dashboard/navbar/index";
import Footer from "./../../../component/dashboard/footer/index";

export default class password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
      userData: {
        oldpassword: "",
        password: "",
        password_confirmation: "",
      },
    };
  }
  componentDidMount() {}

  componentDidUpdate() {}

  updateUser = () => {
    const cookies = new Cookies();
    axios
      .post(
        process.env.REACT_APP_API + `password/data/update`,
        this.state.userData,
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      )
      .then(
        (res) => {
          if (res.data.success == "success") {
            toast.success("Your password has been successfully updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            window.location.reload();
          } else {
            if (res.data.success == false) {
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
            } else {
              $.each(res.data.errors, function (index, item) {
                toast.error(item[0], {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                });
              });
            }
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  onChangeInput = (e) => {
    let loginInput = { ...this.state.userData };
    loginInput[e.target.name] = e.target.value;

    this.setState({
      userData: loginInput,
    });
  };

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }
  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className="overflow-hidden dashboard">
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Password"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-5 col-lg-4 mb-4 mb-md-0">
                      <div className="wrapper__wrap-setting">
                        <h5 className="semi-bold font__size--18 text__18-1024">
                          Settings
                        </h5>
                        <NavLink
                          to="/dashboard/setting/account"
                          className="list d-inline-block w-100 color__black pointer"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="semi-bold opacity__7">
                              Account Settings
                            </span>
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "images/row x5F 7 1.png"
                              }
                              alt=""
                            />
                          </div>
                        </NavLink>
                        <NavLink
                          to="/dashboard/setting/password"
                          className="list d-inline-block w-100 color__black pointer"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="semi-bold opacity__7">
                              Password
                            </span>
                            <img
                              src={
                                process.env.REACT_APP_URL +
                                "images/row x5F 7 1.png"
                              }
                              alt=""
                            />
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-md-7 col-xl-6">
                      <div className="wrapper__form-setting">
                        <h4 className="font__size--24 text__24-1024 semi-bold">
                          Password
                        </h4>
                        <p className="font__size--14 lh-1 m-0">
                          Manage your password
                        </p>
                        <hr />
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                          >
                            Old Password
                          </label>
                          <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                            <input
                              type="password"
                              name="oldpassword"
                              onChange={this.onChangeInput}
                              value={this.state.userData.oldpassword}
                              className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                              placeholder="Insert your text here"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                          >
                            New Password
                          </label>
                          <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                            <input
                              type="password"
                              name="password"
                              onChange={this.onChangeInput}
                              value={this.state.userData.password}
                              className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                              placeholder="Insert your text here"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                          >
                            Confirm Password
                          </label>
                          <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                            <input
                              type="password"
                              name="password_confirmation"
                              onChange={this.onChangeInput}
                              value={this.state.userData.password_confirmation}
                              className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                              placeholder="Insert your text here"
                            />
                          </div>
                        </div>
                        <hr className="my-5" />
                        <button
                          onClick={this.updateUser}
                          className="semi-bold font__size--18 text__18-1024 color__white btn__save"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
