import React, { Fragment } from "react";

const modalConfirm = (props) => {
  return (
    <Fragment>
      <div
        className={
          "modal wrapper__modal-app fade " +
          (props.toogleStatus == "new"
            ? props.modal
              ? "show"
              : ""
            : props.modal
            ? "show"
            : "")
        }
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              {props.modalValidation ? (
                <h5 className="font__size--16 semi-bold mb-3">
                  delete in progress...
                </h5>
              ) : (
                <Fragment>
                  <h5 className="font__size--16 semi-bold mb-3">
                    {props.title}
                  </h5>
                  <div className="d-flex justify-content-center align-items-center">
                    <a
                      onClick={() => props.toogleModal()}
                      href="#!"
                      className="btn btn__modal btn__softblue color__white shadow font__size--14 mr-3"
                    >
                      Cancel
                    </a>
                    <a
                      href="#!"
                      onClick={() => props.goto()}
                      className="btn btn__modal btn__red color__white shadow font__size--14"
                    >
                      {props.button ? props.button : "Yes"}
                    </a>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default modalConfirm;
