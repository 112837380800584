import React, { Fragment } from "react";
import TagGame from "../button/tagGame";
import { NavLink } from "react-router-dom";
const CardSearch = (props) => {
  return (
    <Fragment>
      <div className="wrapper__card-search mb-3">
        <div className="d-flex align-items-center">
          <img src={props.item.url} className="image" alt="" />
          <div className="ml-3">
            <NavLink
              to={`/dashboard/detail/${props.item.slug}`}
              className="mb-2 d-inline-block w-100 semi-bold font__size--14 text-capitalize text__14-1024 color__black text__1024 lh__5"
            >
              {props.item.game_name}
            </NavLink>
            <div className="d-inline-block">
              <TagGame name={props.item.name_category} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardSearch;
