import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";

import Status from "./../button/Status";
import TagGame from "./../button/tagGame";

const GameList = (props) => {
  const [action, setAction] = useState(true);

  return (
    <Fragment>
      <div className="wrapper__card-list w-100 mb-3">
        <div className="row">
          <div className="my-auto col-10 col-md-4">
            <div className="desc d-flex justify-content-start align-items-center">
              <img src={props.item.url} alt="" />
              <NavLink
                to={`/dashboard/detail/${props.item.slug}`}
                className="semi-bold d-inline-block color__black font__size--14 ml-2 text-capitalize lh__5"
              >
                {props.item.game_name}
              </NavLink>
            </div>
          </div>
          <div className="my-auto col-2 d-none d-md-block">
            <span className="semi-bold font__size--12 color__softblue">
              {props.item.name_vendor}
            </span>
          </div>
          <div className="my-auto col d-none d-md-block">
            <TagGame name={props.item.name_category} type={"small"} />
          </div>
          <div className="my-auto col d-none d-md-block">
            <span className="semi-bold font__size--12 color__softblue">
              {dateFormat(props.item.created_at, "yyyy-mm-dd")}
            </span>
          </div>
          <div className="my-auto col d-none d-md-block">
            <Status
              typeStatus={props.typeStatus}
              slug={props.item.slug}
              toogleModalStatus={(e, a) => props.toogleModalStatus(e, a)}
              status={props.item.name_status}
              type={"small"}
            />
          </div>
          <div className="my-auto col col-md-1">
            {props.menu == true ? (
              <div className="dropdown show wrapper__action-menu">
                <div
                  className="pointer z-3 position-relative"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/dsasda.png"}
                    alt=""
                  />
                </div>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <NavLink
                    to={`/dashboard/detail/${props.item.slug}`}
                    className="dropdown-item semi-bold font__size--12"
                  >
                    <div className="f-flex justify-content-start align-items-start">
                      <img
                        src={process.env.REACT_APP_URL + "images/dsfe.png"}
                        className="mr-2"
                        alt=""
                      />
                      Detail
                    </div>
                  </NavLink>
                  <NavLink
                    to={`/dashboard/my-game/edit/${props.item.slug}`}
                    className="dropdown-item semi-bold font__size--12"
                    href="#!"
                  >
                    <div className="f-flex justify-content-start align-items-start">
                      <img
                        src={process.env.REACT_APP_URL + "images/fgfdg.png"}
                        className="mr-2"
                        alt=""
                      />
                      Edit
                    </div>
                  </NavLink>
                  <a
                    onClick={(e) => props.toogleModal(props.item.slug)}
                    className="dropdown-item semi-bold font__size--12"
                    href="#!"
                  >
                    <div className="f-flex justify-content-start align-items-start">
                      <img
                        src={process.env.REACT_APP_URL + "images/fgdg.png"}
                        className="mr-2"
                        alt=""
                      />
                      Remove
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              <NavLink
                to={`/dashboard/detail/${props.item.slug}`}
                className="pointer z-3 position-relative"
              >
                <img
                  src={
                    process.env.REACT_APP_URL +
                    "images/visibility_black_24dp (1) 1.png"
                  }
                  alt=""
                />
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GameList;
