import React, { useEffect, Fragment } from "react";
import Anime from "animejs/lib/anime.es.js";

const SvgLine = (params) => {
  useEffect(() => {
    const path = Anime.path("path");
    Anime({
      targets: ".move__lineH",
      strokeDashoffset: [Anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      direction: "alternate",
      delay: function (el, i) {
        return i * 100;
      },
      loop: true,
    });
  }, []);
  return (
    <Fragment>
      <svg
        style={{ width: "100%" }}
        width="407"
        height="156"
        viewBox="0 0 407 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M162.562 114.52H142.802V140H115.416V114.52H50.0689V95.6267L107.269 18.6667H136.736L83.6955 91.64H116.282V68.9333H142.802V91.64H162.562V114.52ZM216.027 142.08C205.974 142.08 197.019 139.596 189.161 134.627C181.303 129.658 175.121 122.493 170.614 113.133C166.223 103.658 164.027 92.3911 164.027 79.3333C164.027 66.2755 166.223 55.0667 170.614 45.7067C175.121 36.2311 181.303 29.0089 189.161 24.04C197.019 19.0711 205.974 16.5867 216.027 16.5867C226.081 16.5867 235.036 19.0711 242.894 24.04C250.752 29.0089 256.876 36.2311 261.267 45.7067C265.774 55.0667 268.027 66.2755 268.027 79.3333C268.027 92.3911 265.774 103.658 261.267 113.133C256.876 122.493 250.752 129.658 242.894 134.627C235.036 139.596 226.081 142.08 216.027 142.08ZM216.027 118.333C223.423 118.333 229.201 115.156 233.361 108.8C237.636 102.444 239.774 92.6222 239.774 79.3333C239.774 66.0444 237.636 56.2222 233.361 49.8667C229.201 43.5111 223.423 40.3333 216.027 40.3333C208.747 40.3333 202.97 43.5111 198.694 49.8667C194.534 56.2222 192.454 66.0444 192.454 79.3333C192.454 92.6222 194.534 102.444 198.694 108.8C202.97 115.156 208.747 118.333 216.027 118.333ZM385.844 114.52H366.084V140H338.697V114.52H273.351V95.6267L330.551 18.6667H360.017L306.977 91.64H339.564V68.9333H366.084V91.64H385.844V114.52Z"
          fill="#19456B"
        />
        <path
          d="M91.2891 128.2L123.388 95.9111H132.213L100.383 128.2H91.2891Z"
          fill="#319CC9"
        />
        <path
          d="M112.314 128.2L144.413 95.9111H153.239L121.409 128.2H112.314Z"
          fill="white"
        />
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M132.889 128.2L164.988 95.9111H173.813L141.983 128.2H132.889Z"
          stroke="#319CC9"
          stroke-width="1.15556"
        />
        <path
          d="M154.365 128.2L186.464 95.9111H195.289L163.459 128.2H154.365Z"
          fill="#319CC9"
        />
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M0 95.9111H49.8619L93.6 36.9778H147.911"
          stroke="#16C79A"
          stroke-width="2.31111"
        />
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M397.511 36.9778H347.649L303.911 95.9111H249.6"
          stroke="#319CC9"
          stroke-width="2.31111"
        />
        <g filter="url(#filter0_i)">
          <path
            d="M221.867 52.5266L244.364 30.0444L279.902 30.0444L257.405 52.5266L221.867 52.5266Z"
            fill="white"
          />
        </g>
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M262.648 52.5266L284.998 30.0444H291.143L268.981 52.5266H262.648Z"
          stroke="#16C79A"
          stroke-width="1.15556"
        />
        <path
          d="M273.105 52.5266L295.455 30.0444H301.6L279.437 52.5266H273.105Z"
          fill="#16C79A"
        />
        <rect
          x="147.536"
          y="30"
          width="9.52074"
          height="9.52074"
          transform="rotate(45 147.536 30)"
          fill="#16C79A"
        />
        <rect
          x="249.732"
          y="89"
          width="9.52074"
          height="9.52074"
          transform="rotate(45 249.732 89)"
          fill="#319CC9"
        />
        <defs>
          <filter
            id="filter0_i"
            x="216.089"
            y="24.2667"
            width="63.8132"
            height="28.26"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-5.77778" dy="-5.77778" />
            <feGaussianBlur stdDeviation="4.62222" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
        </defs>
      </svg>
    </Fragment>
  );
};

export default SvgLine;
