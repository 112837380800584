import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import { NavLink } from "react-router-dom";
import axios from "axios";

import Sidebar from "../../../component/dashboard/sidebar/index";
import Navbar from "../../../component/dashboard/navbar/index";
import Footer from "../../../component/dashboard/footer/index";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
    };
  }
  componentDidMount() {}

  componentDidUpdate() {}

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  render() {
    return (
      <Fragment>
        <div className="overflow-hidden dashboard">
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Games"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <h5 className="font__size--16 semi-bold">Privacy Policy</h5>
                  <div className="wrapper__privacy mt-3">
                    <div className="item pb-0">
                      <h4 className="bold font__size--20 text__20-1024">
                        Privacy Policy
                      </h4>
                      <p className="m-0 semi-bold font__size--12 text__12-1024 opacity__7">
                        Last Updated : 01 - 08 - 2021
                      </p>
                    </div>
                    <hr />
                    <div className="item pt-0">
                      <div class="text">
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Protecting your private information is our priority.
                          This Statement of Privacy applies to www.wowsee.me,
                          and WowSee LLC and governs data collection and usage.
                          For the purposes of this Privacy Policy, unless
                          otherwise noted, all references to WowSee LLC include
                          www.wowsee.me and WowSee. The WowSee website is a
                          ecommerce site. By using the WowSee website, you
                          consent to the data practices described in this
                          statement.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Collection of your Personal Information
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          In order to better provide you with products and
                          services offered, WowSee may collect personally
                          identifiable information, such as your:
                        </p>
                        <ul class="list__policy font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          <li>- First and Last Name</li>
                          <li>- E-mail Address</li>
                          <li>- Phone Number</li>
                        </ul>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          We do not collect any personal information about you
                          unless you voluntarily provide it to us. However, you
                          may be required to provide certain personal
                          information to us when you elect to use certain
                          products or services. These may include: (a)
                          registering for an account; (b) entering a sweepstakes
                          or contest sponsored by us or one of our partners; (c)
                          signing up for special offers from selected third
                          parties; (d) sending us an email message; (e)
                          submitting your credit card or other payment
                          information when ordering and purchasing products and
                          services. To wit, we will use your information for,
                          but not limited to, communicating with you in relation
                          to services and/or products you have requested from
                          us. We also may gather additional personal or
                          non-personal information in the future.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Use of your Personal Information
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee collects and uses your personal information to
                          operate and deliver the services you have requested.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee may also use your personally identifiable
                          information to inform you of other products or
                          services available from WowSee and its affiliates.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Sharing Information with Third Parties
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee does not sell, rent or lease its customer lists
                          to third parties.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee may share data with trusted partners to help
                          perform statistical analysis, send you email or postal
                          mail, provide customer support, or arrange for
                          deliveries. All such third parties are prohibited from
                          using your personal information except to provide
                          these services to WowSee, and they are required to
                          maintain the confidentiality of your information.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee may disclose your personal information, without
                          notice, if required to do so by law or in the good
                          faith belief that such action is necessary to: (a)
                          conform to the edicts of the law or comply with legal
                          process served on WowSee or the site; (b) protect and
                          defend the rights or property of WowSee; and/or (c)
                          act under exigent circumstances to protect the
                          personal safety of users of WowSee, or the public.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Automatically Collected Information
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Information about your computer hardware and software
                          may be automatically collected by WowSee. This
                          information can include: your IP address, browser
                          type, domain names, access times and referring website
                          addresses. This information is used for the operation
                          of the service, to maintain quality of the service,
                          and to provide general statistics regarding use of the
                          WowSee website.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Use of Cookies
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          The WowSee website may use "cookies" to help you
                          personalize your online experience. A cookie is a text
                          file that is placed on your hard disk by a web page
                          server. Cookies cannot be used to run programs or
                          deliver viruses to your computer. Cookies are uniquely
                          assigned to you, and can only be read by a web server
                          in the domain that issued the cookie to you.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          One of the primary purposes of cookies is to provide a
                          convenience feature to save you time. The purpose of a
                          cookie is to tell the Web server that you have
                          returned to a specific page. For example, if you
                          personalize WowSee pages, or register with WowSee site
                          or services, a cookie helps WowSee to recall your
                          specific information on subsequent visits. This
                          simplifies the process of recording your personal
                          information, such as billing addresses, shipping
                          addresses, and so on. When you return to the same
                          WowSee website, the information you previously
                          provided can be retrieved, so you can easily use the
                          WowSee features that you customized.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          You have the ability to accept or decline cookies.
                          Most Web browsers automatically accept cookies, but
                          you can usually modify your browser setting to decline
                          cookies if you prefer. If you choose to decline
                          cookies, you may not be able to fully experience the
                          interactive features of the WowSee services or
                          websites you visit.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Links
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          This website contains links to other sites. Please be
                          aware that we are not responsible for the content or
                          privacy practices of such other sites. We encourage
                          our users to be aware when they leave our site and to
                          read the privacy statements of any other site that
                          collects personally identifiable information
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Right to Deletion
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Subject to certain exceptions set out below, on
                          receipt of a verifiable request from you, we will:
                        </p>
                        <ul class="list__policy font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          <li>
                            • Delete your personal information from our records;
                            and
                          </li>
                          <li>
                            • Direct any service providers to delete your
                            personal information from their records.
                          </li>
                        </ul>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Please note that we may not be able to comply with
                          requests to delete your personal information if it is
                          necessary to:
                        </p>
                        <ul class="list__policy font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          <li>
                            • Complete the transaction for which the personal
                            information was collected, fulfill the terms of a
                            written warranty or product recall conducted in
                            accordance with federal law, provide a good or
                            service requested by you, or reasonably anticipated
                            within the context of our ongoing business
                            relationship with you, or otherwise perform a
                            contract between you and us;
                          </li>
                          <li>
                            • Detect security incidents, protect against
                            malicious, deceptive, fraudulent, or illegal
                            activity; or prosecute those responsible for that
                            activity;
                          </li>
                          <li>
                            • Debug to identify and repair errors that impair
                            existing intended functionality;
                          </li>
                          <li>
                            • Exercise free speech, ensure the right of another
                            consumer to exercise his or her right of free
                            speech, or exercise another right provided for by
                            law;
                          </li>
                          <li>
                            • Comply with the California Electronic
                            Communications Privacy Act;
                          </li>
                          <li>
                            • Engage in public or peer-reviewed scientific,
                            historical, or statistical research in the public
                            interest that adheres to all other applicable ethics
                            and privacy laws, when our deletion of the
                            information is likely to render impossible or
                            seriously impair the achievement of such research,
                            provided we have obtained your informed consent;
                          </li>
                          <li>
                            • Enable solely internal uses that are reasonably
                            aligned with your expectations based on your
                            relationship with us;
                          </li>
                          <li>
                            • Comply with an existing legal obligation; or
                          </li>
                          <li>
                            • Otherwise use your personal information,
                            internally, in a lawful manner that is compatible
                            with the context in which you provided the
                            information.
                          </li>
                        </ul>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Children Under Thirteen
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee collects personally identifiable information
                          from children under the age of thirteen. WowSee
                          collects this information for the following reason(s):
                          WowSee collects any information submitted through our
                          online form regardless of the age of the person
                          submitting it..
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          If you are under the age of thirteen, you must ask
                          your parent or guardian for permission to use this
                          website. If you are a parent and you have questions
                          regarding our data collection practices, please
                          contact us using the information provided at the end
                          of this Statement of Privacy.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          E-mail Communications
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          From time to time, WowSee may contact you via email
                          for the purpose of providing announcements,
                          promotional offers, alerts, confirmations, surveys,
                          and/or other general communication. In order to
                          improve our Services, we may receive a notification
                          when you open an email from WowSee or click on a link
                          therein.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          If you would like to stop receiving marketing or
                          promotional communications via email from WowSee, you
                          may opt out of such communications by clicking on the
                          UNSUBSCRIBE button..
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          External Data Storage Sites
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          We may store your data on servers provided by third
                          party hosting vendors with whom we have contracted.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Changes to this Statement
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee reserves the right to change this Privacy
                          Policy from time to time. We will notify you about
                          significant changes in the way we treat personal
                          information by sending a notice to the primary email
                          address specified in your account, by placing a
                          prominent notice on our website, and/or by updating
                          any privacy information. Your continued use of the
                          website and/or Services available after such
                          modifications will constitute your: (a) acknowledgment
                          of the modified Privacy Policy; and (b) agreement to
                          abide and be bound by that Policy.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Contact Information
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee welcomes your questions or comments regarding
                          this Statement of Privacy. If you believe that WowSee
                          has not adhered to this Statement, please contact
                          WowSee at:
                        </p>
                        <div class="row mb-4">
                          <div class="col-md-4">
                            <div class="images__logo-terms mb-4">
                              <img src="./../../images/Location.png" alt="" />
                            </div>
                            <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                              WowSee LLC 1309 Coffeen Avenue Suite 3301
                              Sheridan, Wyoming 82801
                            </p>
                          </div>
                          <div class="col-md-4">
                            <div class="images__logo-terms mb-4">
                              <img src="./../../images/Message.png" alt="" />
                            </div>
                            <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                              Email Address: info@wowsee.me
                            </p>
                          </div>
                          <div class="col-md-4">
                            <div class="images__logo-terms mb-4">
                              <img src="./../../images/Call.png" alt="" />
                            </div>
                            <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                              Telephone number: 9495052361
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
