import React, { Component, Fragment } from "react";
import Status from "../../../component/dashboard/button/Status";
import dateFormat from "dateformat";
const information = (props) => {
  return (
    <Fragment>
      <h5 className="semi-bold font__size--16 text__16-1024 mb-3">
        Information
      </h5>
      <table className="wrapper__tabel-info">
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Status
          </td>
          <td>
            {!props.loading ? (
              <Status status={props.detail.name_status} />
            ) : null}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Vendor
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {props.detail.name_vendor}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Package Name
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {props.detail.packge}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Engine
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {props.detail.engine}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Uploaded
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {dateFormat(props.detail.created_at, "yyyy-mm-dd")}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Category
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {props.detail.name_category}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Content Rating
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {props.detail.name_content}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            MSRP
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {props.detail.msrp}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Compatible
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            {props.compatible}
          </td>
        </tr>
        <tr>
          <td className="semi-bold font__size--14 text__14-1024 color__darkblue opacity__7">
            Resource
          </td>
          <td className="font__size--14 text__14-1024 opacity__5">
            <a
              href={props.apkResouces.resources_url}
              style={{ textDecoration: "underline" }}
            >
              Downlaod Resource
            </a>
          </td>
        </tr>
      </table>
    </Fragment>
  );
};

export default information;
