import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import dateFormat from "dateformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { COLUMNS } from "../../component/dashboard/tabel/colum";

import Sidebar from "../../component/dashboard/sidebar/index";
import Navbar from "../../component/dashboard/navbar/index";
import Footer from "../../component/dashboard/footer/index";
import Tabel from "../../component/dashboard/tabel/tabel";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
      detail: [],
      loading: true,
      apkVersion: [],
      apkResouces: [],
      compatible: [],
      gameDetail: [],
      gameImages: [],
      version_data: [],
      review_data: [],
      changelog_data: [],
      result_data: [],
      contentDetail_data: [],
      apply: [],
      preview: false,
      modal: false,
      modalResult: false,
      modalValidation: false,
      modalStatus: false,
      reviewId: "",
      resultId: "",
    };
  }
  componentDidMount() {
    this.getUserApply();
  }

  componentDidUpdate() {
    console.log(this.state.apply);
  }

  getUserApply = () => {
    this.setState({
      loading: true,
    });
    axios.get(`https://api.wowsee.me/api/apply/get`).then(
      (res) => {
        this.setState({
          apply: res.data.data,
          loading: false,
        });
      },
      (err) => {
        console.log("error ", err);
      }
    );
  };

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  tooglePrivew() {
    this.setState({
      preview: !this.state.preview,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className="overflow-hidden dashboard">
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Detail"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <div className="wrapper__tabel-user table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" className="semi-bold font__size--14">
                            Name
                          </th>
                          <th scope="col" className="semi-bold font__size--14">
                            Email
                          </th>
                          <th scope="col" className="semi-bold font__size--14">
                            Prototype
                          </th>
                          <th scope="col" className="semi-bold font__size--14">
                            Studio
                          </th>
                          <th scope="col" className="semi-bold font__size--14">
                            Type
                          </th>
                          <th scope="col" className="semi-bold font__size--14">
                            Contact
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.apply.map((obj) => {
                          return (
                            <tr>
                              <th className="normal font__size--14" scope="row">
                                {obj.name}
                              </th>
                              <td className="normal font__size--14">
                                {obj.email}
                              </td>
                              <td className="normal font__size--14">
                                <a href={obj.game_link} target="_blank">Click Here</a>
                              </td>
                              <td className="normal font__size--14">
                                {obj.studio_name}
                              </td>
                              <td className="normal font__size--14">
                                {obj.type}
                              </td>
                              <td className="normal font__size--14">
                                {obj.contact}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(User);
