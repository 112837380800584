import React, { Fragment, useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import UserContext from "../../../context/UserContext";
import axios from "axios";

const Index = (props) => {
  const [count, setCount] = useState(0);
  const [countApproved, setCountApproved] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const logout = () => {
    const cookies = new Cookies();
    localStorage.clear();
    cookies.remove("jwt", { path: "/" });
    window.location.replace(process.env.REACT_APP_URL + "/");
  };

  const getAllGame = async () => {
    const cookies = new Cookies();
    await axios
      .get(process.env.REACT_APP_API + `game/all-data/count`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          setCount(res.data.data.gameDetail.length);
          setCountApproved(res.data.data.approved.length);
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };
  const getAllUser = async () => {
    const cookies = new Cookies();
    await axios
      .get(`https://api.wowsee.me/api/apply/get`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          setUserCount(res.data.data.length);
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  useEffect(() => {
    getAllGame();
    getAllUser();
  }, []);

  const DataUser = () => {
    const value = useContext(UserContext);
    return value.user;
  };
  return (
    <Fragment>
      <div className={"sidenav " + (props.menu ? "show" : "")}>
        <NavLink to="/dashboard" className="logo text-center w-100">
          <img
            src={
              process.env.REACT_APP_URL +
              "images/Full Logo - Transparent - Black.png"
            }
            alt=""
          />
        </NavLink>
        <ul className="wrapper__menu-sidenav mt-4">
          {DataUser().role == "admin" || DataUser().type == "creator" ? (
            <li className="position-relative">
              <NavLink
                exact
                to="/dashboard"
                className="d-flex justify-content-start align-items-center"
              >
                <img
                  src={process.env.REACT_APP_URL + "images/sada.png"}
                  alt=""
                />{" "}
                <span className="semi-bold color__black font__size--12 ml-2">
                  Active ({count})
                </span>
              </NavLink>
            </li>
          ) : null}

          <li className="position-relative">
            <NavLink
              to="/dashboard/library"
              className="d-flex justify-content-start align-items-center"
            >
              <img
                src={process.env.REACT_APP_URL + "images/Icon (1).png"}
                alt=""
              />{" "}
              <span className="semi-bold color__black font__size--12 ml-2">
                Game Library ({countApproved})
              </span>
            </NavLink>
          </li>
          {DataUser().role == "admin" || DataUser().type == "creator" ? (
            <li className="position-relative">
              <NavLink
                className="d-flex justify-content-start align-items-center"
                to="/dashboard/my-game"
              >
                <img
                  src={process.env.REACT_APP_URL + "images/ssaff.png"}
                  alt=""
                />
                <span className="semi-bold color__black font__size--12 ml-2">
                  My Game
                </span>
              </NavLink>
            </li>
          ) : null}
          {DataUser().role == "admin" ? (
            <li className="position-relative">
              <NavLink
                className="d-flex justify-content-start align-items-center"
                to="/dashboard/user/apply"
              >
                <img
                  src={process.env.REACT_APP_URL + "images/3sdf.png"}
                  alt=""
                />
                <span className="semi-bold color__black font__size--12 ml-2">
                  Applied Users ({userCount})
                </span>
              </NavLink>
            </li>
          ) : null}
        </ul>
        <a
          href="#!"
          onClick={() => logout()}
          className="d-inline-block mt-5 ml__2"
        >
          <div className="d-flex justify-content-start align-items-center">
            <img
              src={process.env.REACT_APP_URL + "images/Frame (2).png"}
              alt=""
            />{" "}
            <span className="semi-bold color__red font__size--12 ml-2">
              Logout
            </span>
          </div>
        </a>
      </div>
      <span
        className="shadow-menu"
        onClick={() => props.toogleSidenav()}
      ></span>
    </Fragment>
  );
};

export default Index;
