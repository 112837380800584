import React, { Fragment, useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const PreviewImageBox = (props) => {
  const [click, setClick] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    setImage(props.images);
  }, []);
  return (
    <Fragment>
      {props.status == "text" ? (
        <div
          className="font__size--10 color__blue pointer d-inline-block"
          onClick={() => setClick(!click)}
        >
          <u>{props.text}</u>
        </div>
      ) : (
        <img
          src={props.images}
          onClick={() => setClick(!click)}
          className="images__content-media my-3 pointer"
          alt=""
        />
      )}

      {click && (
        <Lightbox
          mainSrc={props.images}
          onCloseRequest={() => setClick(!click)}
        />
      )}
    </Fragment>
  );
};

export default PreviewImageBox;
