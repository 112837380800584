import React, { Component, Fragment, useRef } from "react";

const ImageUpload = (props) => {
  const select = useRef(null);
  const clickToChange = () => {
    select.current.click();
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      props.UplaodImage(props.name, e.target.files);
    }
  };

  const removeInput = () => {
    select.current.value = "";
    props.RemoveImage(props.name);
  };
  return (
    <Fragment>
      <div className="wrapper__upload-form d-flex justify-content-center align-items-center position-relative">
        <div
          onClick={removeInput}
          className={
            "close__upload pointer " +
            (props.image.length > 0 || props.value != "" ? "showing" : "")
          }
        >
          <img src={process.env.REACT_APP_URL + "images/sssdw.png"} alt="" />
        </div>
        <div onClick={clickToChange} className="pointer">
          <input
            type="file"
            name={props.name}
            ref={select}
            onChange={imageChange}
            className="form-control wrapper__hide input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
            placeholder="Insert your text here"
          />
          {props.image.length > 0 ? (
            <img
              src={URL.createObjectURL(props.image[0])}
              className="preview"
              alt=""
            />
          ) : props.value != "" ? (
            <img src={props.value} className="preview" alt="" />
          ) : (
            <div className="text-center pointer">
              <img
                src={process.env.REACT_APP_URL + "images/dfffs.png"}
                alt=""
              />
              <h5 className="semi-bold font__size--12 text__12-1024 mt-2 mb-1">
                Click to upload your Image
              </h5>
              <p className="font__size--10 m-0 lh-1">Max Size : 2 MB</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ImageUpload;
