import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import { NavLink } from "react-router-dom";
import axios from "axios";

import Sidebar from "../../../component/dashboard/sidebar/index";
import Navbar from "../../../component/dashboard/navbar/index";
import Footer from "../../../component/dashboard/footer/index";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
    };
  }
  componentDidMount() {}

  componentDidUpdate() {}

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  render() {
    return (
      <Fragment>
        <div className="overflow-hidden dashboard">
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Games"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <h5 className="font__size--16 semi-bold">
                    Terms & Conditions
                  </h5>
                  <div className="wrapper__privacy mt-3">
                    <div className="item pb-0">
                      <h4 className="bold font__size--20 text__20-1024">
                        Terms & Conditions
                      </h4>
                      <p className="m-0 semi-bold font__size--12 text__12-1024 opacity__7">
                        Last Updated : 01 - 08 - 2021
                      </p>
                    </div>
                    <hr />
                    <div className="item pt-0">
                      <div class="text">
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Agreement between User and www.wowsee.me
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Welcome to www.wowsee.me. The www.wowsee.me website
                          (the "Site") is comprised of various web pages
                          operated by WowSee LLC ("WowSee"). www.wowsee.me is
                          offered to you conditioned on your acceptance without
                          modification of the terms, conditions, and notices
                          contained herein (the "Terms"). Your use of
                          www.wowsee.me constitutes your agreement to all such
                          Terms. Please read these terms carefully, and keep a
                          copy of them for your reference
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          www.wowsee.me is an E-Commerce Site.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee provides distribution and development of
                          digital media for exclusive private markets.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Privacy
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Your use of www.wowsee.me is subject to WowSee's
                          Privacy Policy. Please review our Privacy Policy,
                          which also governs the Site and informs users of our
                          data collection practices.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Electronic Communications
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Visiting www.wowsee.me or sending emails to WowSee
                          constitutes electronic communications. You consent to
                          receive electronic communications and you agree that
                          all agreements, notices, disclosures and other
                          communications that we provide to you electronically,
                          via email and on the Site, satisfy any legal
                          requirement that such communications be in writing.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Children Under Thirteen
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee collects personally identifiable information
                          from children under the age of thirteen. WowSee
                          collects this information for the following reason(s):
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Our website collects any data submitted to us through
                          the online forms on our website.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          If you are under the age of thirteen, you must ask
                          your parent or guardian for permission to use this
                          website. If you are a parent and you have questions
                          regarding our data collection practices, please
                          contact us using the information provided at the end
                          of this Agreement. If you are under 18, you may use
                          www.wowsee.me only with permission of a parent or
                          guardian.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Links to Third Party Sites/Third Party Services
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          www.wowsee.me may contain links to other websites
                          ("Linked Sites"). The Linked Sites are not under the
                          control of WowSee and WowSee is not responsible for
                          the contents of any Linked Site, including without
                          limitation any link contained in a Linked Site, or any
                          changes or updates to a Linked Site. WowSee is
                          providing these links to you only as a convenience,
                          and the inclusion of any link does not imply
                          endorsement by WowSee of the site or any association
                          with its operators.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Certain services made available via www.wowsee.me are
                          delivered by third party sites and organizations. By
                          using any product, service or functionality
                          originating from the www.wowsee.me domain, you hereby
                          acknowledge and consent that WowSee may share such
                          information and data with any third party with whom
                          WowSee has a contractual relationship to provide the
                          requested product, service or functionality on behalf
                          of www.wowsee.me users and customers.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          No Unlawful or Prohibited Use/Intellectual Property
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          You are granted a non-exclusive, non-transferable,
                          revocable license to access and use www.wowsee.me
                          strictly in accordance with these terms of use. As a
                          condition of your use of the Site, you warrant to
                          WowSee that you will not use the Site for any purpose
                          that is unlawful or prohibited by these Terms. You may
                          not use the Site in any manner which could damage,
                          disable, overburden, or impair the Site or interfere
                          with any other party's use and enjoyment of the Site.
                          You may not obtain or attempt to obtain any materials
                          or information through any means not intentionally
                          made available or provided for through the Site.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          All content included as part of the Service, such as
                          text, graphics, logos, images, as well as the
                          compilation thereof, and any software used on the
                          Site, is the property of WowSee or its suppliers and
                          protected by copyright and other laws that protect
                          intellectual property and proprietary rights. You
                          agree to observe and abide by all copyright and other
                          proprietary notices, legends or other restrictions
                          contained in any such content and will not make any
                          changes thereto.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          You will not modify, publish, transmit, reverse
                          engineer, participate in the transfer or sale, create
                          derivative works, or in any way exploit any of the
                          content, in whole or in part, found on the Site.
                          WowSee content is not for resale. Your use of the Site
                          does not entitle you to make any unauthorized use of
                          any protected content, and in particular you will not
                          delete or alter any proprietary rights or attribution
                          notices in any content. You will use protected content
                          solely for your personal use, and will make no other
                          use of the content without the express written
                          permission of WowSee and the copyright owner. You
                          agree that you do not acquire any ownership rights in
                          any protected content. We do not grant you any
                          licenses, express or implied, to the intellectual
                          property of WowSee or our licensors except as
                          expressly authorized by these Terms.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          International Users
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          The Service is controlled, operated and administered
                          by WowSee from our offices within the USA. If you
                          access the Service from a location outside the USA,
                          you are responsible for compliance with all local
                          laws. You agree that you will not use the WowSee
                          Content accessed through www.wowsee.me in any country
                          or in any manner prohibited by any applicable laws,
                          restrictions or regulations.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Indemnification{" "}
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          You agree to indemnify, defend and hold harmless
                          WowSee, its officers, directors, employees, agents and
                          third parties, for any losses, costs, liabilities and
                          expenses (including reasonable attorney's fees)
                          relating to or arising out of your use of or inability
                          to use the Site or services, any user postings made by
                          you, your violation of any terms of this Agreement or
                          your violation of any rights of a third party, or your
                          violation of any applicable laws, rules or
                          regulations. WowSee reserves the right, at its own
                          cost, to assume the exclusive defense and control of
                          any matter otherwise subject to indemnification by
                          you, in which event you will fully cooperate with
                          WowSee in asserting any available defenses.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Arbitration
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          In the event the parties are not able to resolve any
                          dispute between them arising out of or concerning
                          these Terms and Conditions, or any provisions hereof,
                          whether in contract, tort, or otherwise at law or in
                          equity for damages or any other relief, then such
                          dispute shall be resolved only by final and binding
                          arbitration pursuant to the Federal Arbitration Act,
                          conducted by a single neutral arbitrator and
                          administered by the American Arbitration Association,
                          or a similar arbitration service selected by the
                          parties, in a location mutually agreed upon by the
                          parties. The arbitrator's award shall be final, and
                          judgment may be entered upon it in any court having
                          jurisdiction. In the event that any legal or equitable
                          action, proceeding or arbitration arises out of or
                          concerns these Terms and Conditions, the prevailing
                          party shall be entitled to recover its costs and
                          reasonable attorney's fees. The parties agree to
                          arbitrate all disputes and claims in regards to these
                          Terms and Conditions or any disputes arising as a
                          result of these Terms and Conditions, whether directly
                          or indirectly, including Tort claims that are a result
                          of these Terms and Conditions. The parties agree that
                          the Federal Arbitration Act governs the interpretation
                          and enforcement of this provision. The entire dispute,
                          including the scope and enforceability of this
                          arbitration provision shall be determined by the
                          Arbitrator. This arbitration provision shall survive
                          the termination of these Terms and Conditions.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Class Action Waiver
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Any arbitration under these Terms and Conditions will
                          take place on an individual basis; class arbitrations
                          and class/representative/collective actions are not
                          permitted. THE PARTIES AGREE THAT A PARTY MAY BRING
                          CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL
                          CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                          ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
                          PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
                          GENERAL ACTION AGAINST THE OTHER. Further, unless both
                          you and WowSee agree otherwise, the arbitrator may not
                          consolidate more than one person's claims, and may not
                          otherwise preside over any form of a representative or
                          class proceeding.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Liability Disclaimer
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
                          INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
                          INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE
                          PERIODICALLY ADDED TO THE INFORMATION HEREIN. WOWSEE
                          LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
                          CHANGES IN THE SITE AT ANY TIME
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WOWSEE LLC AND/OR ITS SUPPLIERS MAKE NO
                          REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                          AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
                          INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                          GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
                          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
                          INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                          GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR
                          CONDITION OF ANY KIND. WOWSEE LLC AND/OR ITS SUPPLIERS
                          HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
                          REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS,
                          SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
                          WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
                          FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN
                          NO EVENT SHALL WOWSEE LLC AND/OR ITS SUPPLIERS BE
                          LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
                          SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                          WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
                          LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY
                          WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE,
                          WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED
                          SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
                          SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
                          SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE
                          SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE,
                          WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                          LIABILITY OR OTHERWISE, EVEN IF WOWSEE LLC OR ANY OF
                          ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
                          DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
                          ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
                          CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                          LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
                          DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY
                          OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY
                          IS TO DISCONTINUE USING THE SITE.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Termination/Access Restriction
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee reserves the right, in its sole discretion, to
                          terminate your access to the Site and the related
                          services or any portion thereof at any time, without
                          notice. To the maximum extent permitted by law, this
                          agreement is governed by the laws of the State of
                          Wyoming and you hereby consent to the exclusive
                          jurisdiction and venue of courts in Wyoming in all
                          disputes arising out of or relating to the use of the
                          Site. Use of the Site is unauthorized in any
                          jurisdiction that does not give effect to all
                          provisions of these Terms, including, without
                          limitation, this section.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          You agree that no joint venture, partnership,
                          employment, or agency relationship exists between you
                          and WowSee as a result of this agreement or use of the
                          Site. WowSee's performance of this agreement is
                          subject to existing laws and legal process, and
                          nothing contained in this agreement is in derogation
                          of WowSee's right to comply with governmental, court
                          and law enforcement requests or requirements relating
                          to your use of the Site or information provided to or
                          gathered by WowSee with respect to such use. If any
                          part of this agreement is determined to be invalid or
                          unenforceable pursuant to applicable law including,
                          but not limited to, the warranty disclaimers and
                          liability limitations set forth above, then the
                          invalid or unenforceable provision will be deemed
                          superseded by a valid, enforceable provision that most
                          closely matches the intent of the original provision
                          and the remainder of the agreement shall continue in
                          effect.
                        </p>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Unless otherwise specified herein, this agreement
                          constitutes the entire agreement between the user and
                          WowSee with respect to the Site and it supersedes all
                          prior or contemporaneous communications and proposals,
                          whether electronic, oral or written, between the user
                          and WowSee with respect to the Site. A printed version
                          of this agreement and of any notice given in
                          electronic form shall be admissible in judicial or
                          administrative proceedings based upon or relating to
                          this agreement to the same extent and subject to the
                          same conditions as other business documents and
                          records originally generated and maintained in printed
                          form. It is the express wish to the parties that this
                          agreement and all related documents be written in
                          English.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Changes to Terms
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          Changes to Terms WowSee reserves the right, in its
                          sole discretion, to change the Terms under which
                          www.wowsee.me is offered. The most current version of
                          the Terms will supersede all previous versions. WowSee
                          encourages you to periodically review the Terms to
                          stay informed of our updates.
                        </p>
                        <h5 class="bold font__size--24 text__24-1024 color__black mb-4 lh-2">
                          Contact Us
                        </h5>
                        <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                          WowSee welcomes your questions or comments regarding
                          the Terms:
                        </p>
                        <div class="row mb-4">
                          <div class="col-md-4">
                            <div class="images__logo-terms mb-4">
                              <img src="./../../images/Location.png" alt="" />
                            </div>
                            <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                              WowSee LLC 1309 Coffeen Avenue Suite 3301
                              Sheridan, Wyoming 82801
                            </p>
                          </div>
                          <div class="col-md-4">
                            <div class="images__logo-terms mb-4">
                              <img src="./../../images/Message.png" alt="" />
                            </div>
                            <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                              Email Address: info@wowsee.me
                            </p>
                          </div>
                          <div class="col-md-4">
                            <div class="images__logo-terms mb-4">
                              <img src="./../../images/Call.png" alt="" />
                            </div>
                            <p class="font__size--14 text__14-1024 opacity__7 mb-4 lh-2">
                              Telephone number: 9495052361
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
