import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import ReactQuill from "react-quill";
import { withRouter } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../../../../component/dashboard/sidebar/index";
import Navbar from "../../../../component/dashboard/navbar/index";
import Footer from "../../../../component/dashboard/footer/index";
import ImageUpload from "../../../../component/dashboard/image/ImageUpload";
import ApkUpload from "../../../../component/dashboard/image/ApkUpload";
import LoadingUpload from "../../../../component/dashboard/modal/loadingUpload";
import ModalConfirm from "../../../../component/dashboard/modal/modalConfirm";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      next: 2,
      cancle: 0,
      menu: false,
      showing: "grid",
      search: false,
      description: "",
      type: "",
      role: "",
      upload: false,
      percentage: 0,
      validation: false,
      error: [],
      loading: true,
      rating: [
        "Everyone",
        "Everyone 10+",
        "Teen",
        "Mature 17+",
        "Adults only 18+",
        "Rating Pending",
      ],
      category: [
        "Action",
        "Adventure",
        "Educational",
        "Puzzle",
        "Racing",
        "Sport",
        "Story",
        "Strategy",
      ],
      status: ["Need Action", "Approved", "Submitted", "Rejected", "in QA"],
      dataGame: {
        game_name: "",
        category: "",
        vendor: "",
        engine: "",
        content_rating: "",
        msrp: "",
        game_note: "",
        description: "",
        packge: "",
        status: "",
        thumbnail: [],
        hero: [],
        screenshoot1: [],
        screenshoot2: [],
        screenshoot3: [],
        screenshoot4: [],
        version: "",
        compatible: [],
        apk: [],
        apkType: "",
        resources: [],
        resourcesType: "",
      },
      apkVersion: [],
      apkResources: [],
      version: [],
      file_name: "",
      size_name: "",
      file_resource: "",
      size_resource: "",
      selectedVersion: "",
      gameDetail: [],
      gameImage: {
        thumbnail: [],
        hero: [],
        screenshoot1: [],
        screenshoot2: [],
        screenshoot3: [],
        screenshoot4: [],
      },
      modal: false,
      modalValidation: false,
    };
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;
    // console.log("slug " + slug);
    this.setState({
      type: JSON.parse(localStorage.getItem("userData")).type,
      role: JSON.parse(localStorage.getItem("userData")).role,
    });

    this.getDetail(slug);
  }

  async getDetail(slug) {
    const cookies = new Cookies();
    await axios
      .get(process.env.REACT_APP_API + `game/detail/edit/${slug}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          let gameInput = { ...this.state.dataGame };
          gameInput["game_name"] = res.data.data.gameDetail.game_name;
          gameInput["category"] = res.data.data.gameDetail.name_category;
          gameInput["vendor"] = res.data.data.gameDetail.name_vendor;
          gameInput["engine"] = res.data.data.gameDetail.engine;
          gameInput["content_rating"] = res.data.data.gameDetail.name_content;
          gameInput["msrp"] = res.data.data.gameDetail.msrp;
          gameInput["game_note"] = res.data.data.gameDetail.game_note;
          gameInput["description"] = res.data.data.gameDetail.description;
          gameInput["packge"] = res.data.data.gameDetail.packge;
          gameInput["status"] = res.data.data.gameDetail.name_status;
          gameInput["compatible"] = res.data.data.compatible;
          gameInput["version"] = res.data.data.apkVersion[0].version;

          let gameImage = { ...this.state.gameImage };
          gameImage["thumbnail"] = res.data.data.gameImages.thumbnail;
          gameImage["hero"] = res.data.data.gameImages.hero;
          gameImage["screenshoot1"] = res.data.data.gameImages.screenshoot1;
          gameImage["screenshoot2"] = res.data.data.gameImages.screenshoot2;
          gameImage["screenshoot3"] = res.data.data.gameImages.screenshoot3;
          gameImage["screenshoot4"] = res.data.data.gameImages.screenshoot4;

          this.setState({
            dataGame: gameInput,
            gameImage: gameImage,
            apkVersion: res.data.data.apkVersion,
            apkResources: res.data.data.apkResources,
            version: res.data.data.version,
            gameDetail: res.data.data.gameDetail,
            loading: false,
            file_name: res.data.data.apkVersion[0].name,
            size_name: res.data.data.apkVersion[0].size,
            file_resource: res.data.data.apkResources[0].resources_name,
            size_resource: res.data.data.apkResources[0].resources_size,
            selectedVersion: res.data.data.apkVersion[0].id,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  }

  componentDidUpdate() {}

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  onEditorStateChange = (a) => {
    let gameInput = { ...this.state.dataGame };
    gameInput["description"] = a;

    this.setState({
      description: a,
      dataGame: gameInput,
    });
  };

  onChangeInput = (e) => {
    let gameInput = { ...this.state.dataGame };
    gameInput[e.target.name] = e.target.value;

    this.setState({
      dataGame: gameInput,
    });
  };

  onChangeComplete = async (e) => {
    let gameInput = { ...this.state.dataGame };

    function checkCompability(compability) {
      return compability === e.target.value;
    }

    if (gameInput["compatible"].find(checkCompability) == e.target.value) {
      gameInput["compatible"].splice(
        gameInput["compatible"].indexOf(e.target.value),
        1
      );
    } else {
      gameInput["compatible"].push(e.target.value);
    }
  };

  onSelectComplete = (e) => {
    let gameInput = { ...this.state.dataGame };

    function checkCompability(compability) {
      return compability === e;
    }

    if (gameInput["compatible"].find(checkCompability) == e) {
      return true;
    } else {
      return false;
    }
  };

  UplaodImage = async (title, e) => {
    let gameInput = { ...this.state.dataGame };
    gameInput[title] = e;

    let gameImage = { ...this.state.gameImage };
    gameImage[title] = "";

    if (title == "apk") {
      gameInput["apkType"] = e[0].type;
    }

    if (title == "resources") {
      gameInput["resourcesType"] = e[0].type;
    }

    await this.setState({
      dataGame: gameInput,
      gameImage: gameImage,
    });
  };

  RemoveImage = async (title) => {
    let gameInput = { ...this.state.dataGame };
    gameInput[title] = [];

    let gameImage = { ...this.state.gameImage };
    gameImage[title] = "";

    await this.setState({
      dataGame: gameInput,
      gameImage: gameImage,
    });
  };

  uploadGame = () => {
    const cookies = new Cookies();

    this.setState({
      upload: true,
      error: [],
    });

    const slug = this.props.match.params.slug;

    const data = new FormData();
    data.append("game_name", this.state.dataGame.game_name);
    data.append("category", this.state.dataGame.category);
    data.append("vendor", this.state.dataGame.vendor);
    data.append("engine", this.state.dataGame.engine);
    data.append("content_rating", this.state.dataGame.content_rating);
    data.append("msrp", this.state.dataGame.msrp);
    data.append("game_note", this.state.dataGame.game_note);
    data.append("description", this.state.dataGame.description);
    data.append("packge", this.state.dataGame.packge);
    data.append("version", this.state.dataGame.version);
    data.append("compatible", this.state.dataGame.compatible);
    data.append("status", this.state.dataGame.status);
    data.append("selectedVersion", this.state.selectedVersion);

    if (this.state.dataGame.thumbnail.length > 0) {
      data.append("thumbnail", this.state.dataGame.thumbnail[0]);
    }
    if (this.state.dataGame.hero.length > 0) {
      data.append("hero", this.state.dataGame.hero[0]);
    }
    if (this.state.dataGame.screenshoot1.length > 0) {
      data.append("screenshoot1", this.state.dataGame.screenshoot1[0]);
    }
    if (this.state.dataGame.screenshoot2.length > 0) {
      data.append("screenshoot2", this.state.dataGame.screenshoot2[0]);
    }
    if (this.state.dataGame.screenshoot3.length > 0) {
      data.append("screenshoot3", this.state.dataGame.screenshoot3[0]);
    }
    if (this.state.dataGame.screenshoot4.length > 0) {
      data.append("screenshoot4", this.state.dataGame.screenshoot4[0]);
    }
    if (this.state.dataGame.apk.length > 0) {
      data.append("apk", this.state.dataGame.apk[0]);
      data.append("apkType", this.state.dataGame.apkType);
    }
    if (this.state.dataGame.resources.length > 0) {
      data.append("resources", this.state.dataGame.resources[0]);
      data.append("resourcesType", this.state.dataGame.resourcesType);
    }

    axios
      .post(process.env.REACT_APP_API + `update/game/creator/` + slug, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.round((loaded * 100) / total);
          // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
          this.setState({
            percentage: percent,
          });

          if (percent == 100) {
            this.setState({
              validation: true,
            });
          }
        },
      })
      .then(
        (res) => {
          if (res.data.success == "success") {
            window.location.replace(process.env.REACT_APP_URL);
          } else {
            toast.error("there is an error please check your input", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });

            this.setState({
              error: res.data.errors,
              upload: false,
              percentage: 0,
              validation: false,
              modal: !this.state.modal,
            });
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  selectVersion = (e) => {
    function findVersion(object) {
      return object.version === e.target.value;
    }
    function findVersionResources(object) {
      return object.version === e.target.value;
    }
    const findData = this.state.apkVersion.find(findVersion);
    const findDataResource = this.state.apkResources.find(findVersionResources);
    let gameInput = { ...this.state.dataGame };
    gameInput["version"] = findData.version;
    gameInput["apk"] = "";
    gameInput["apkType"] = "";
    gameInput["resources"] = "";
    gameInput["resourcesType"] = "";

    this.setState({
      file_name: findData.name,
      size_name: findData.size,
      file_resource: findDataResource.resources_name,
      size_resource: findDataResource.resources_size,
      selectedVersion: findData.id,
      dataGame: gameInput,
    });
  };

  addNewVersion = () => {
    let gameInput = { ...this.state.dataGame };
    gameInput["version"] = "";
    gameInput["apk"] = "";
    gameInput["apkType"] = "";
    gameInput["resources"] = "";
    gameInput["resourcesType"] = "";
    this.setState({
      file_name: "",
      size_name: "",
      file_resource: "",
      size_resource: "",
      selectedVersion: "",
      dataGame: gameInput,
    });
  };

  toogleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  nextStep() {
    if (this.state.next + 1 <= 5) {
      this.setState({
        next: this.state.next + 1,
        cancle: this.state.cancle + 1,
      });
    }
    if (this.state.next == 5) {
      this.toogleModal();
    }
  }

  cancleStep() {
    if (this.state.cancle >= 1) {
      this.setState({
        next: this.state.next - 1,
        cancle: this.state.cancle - 1,
      });
    }
  }

  onStep(e) {
    const a = 2;
    const b = 0;

    this.setState({
      next: a + e,
      cancle: b + e,
    });
  }

  render() {
    const modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [("link", "image")],
        ["clean"],
      ],
    };

    return (
      <Fragment>
        <ToastContainer />

        <div className="overflow-hidden dashboard">
          <div className="wrapper__dashboard position-relative">
            <ModalConfirm
              modal={this.state.modal}
              modalValidation={this.state.modalValidation}
              toogleModal={() => this.toogleModal()}
              title={"Are you sure you want to update your game?"}
              button={"Yes"}
              goto={() => this.uploadGame()}
            />
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Upload"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 col-xl-10">
                      <div className="wrapper__step-number d-flex justify-content-center align-items-center">
                        <div
                          onClick={() => this.onStep(0)}
                          className={
                            "list d-flex pointer flex-wrap flex-lg-nowrap justify-content-lg-start justify-content-center text-center text-lg-left align-items-center " +
                            (this.state.next > 1 ? "active" : "") +
                            (this.state.next - 1 > 1 ? " success" : "")
                          }
                        >
                          <div className="number flex-shrink-0 d-flex justify-content-center align-items-center semi-bold font__size--14">
                            <span>1</span>
                            <img
                              src={
                                process.env.REACT_APP_URL + "images/check.png"
                              }
                              alt=""
                            />
                          </div>
                          <span className="semi-bold font__size--14 text__14-1024 text__14-xx ml-lg-2 mt-1 mt-lg-0 w-100 d-lg-inline-block">
                            Game{" "}
                            <span className="d-none d-lg-inline-block">
                              Overview
                            </span>
                          </span>
                        </div>
                        <div
                          onClick={() => this.onStep(1)}
                          className={
                            "list d-flex pointer flex-wrap flex-lg-nowrap justify-content-lg-start justify-content-center text-center text-lg-left align-items-center " +
                            (this.state.next > 2 ? "active" : "") +
                            (this.state.next - 1 > 2 ? " success" : "")
                          }
                        >
                          <div className="number flex-shrink-0 d-flex justify-content-center align-items-center semi-bold font__size--14 text__14-1024">
                            <span>2</span>
                            <img
                              src={
                                process.env.REACT_APP_URL + "images/check.png"
                              }
                              alt=""
                            />
                          </div>
                          <span className="semi-bold font__size--14 text__14-1024 text__14-xx ml-lg-2 mt-1 mt-lg-0 w-100 d-lg-inline-block">
                            <span className="d-none d-lg-inline-block">
                              Game
                            </span>{" "}
                            Description
                          </span>
                        </div>
                        <div
                          onClick={() => this.onStep(2)}
                          className={
                            "list d-flex pointer flex-wrap flex-lg-nowrap justify-content-lg-start justify-content-center text-center text-lg-left align-items-center " +
                            (this.state.next > 3 ? "active" : "") +
                            (this.state.next - 1 > 3 ? " success" : "")
                          }
                        >
                          <div className="number flex-shrink-0 d-flex justify-content-center align-items-center semi-bold font__size--14 text__14-1024">
                            <span>3</span>
                            <img
                              src={
                                process.env.REACT_APP_URL + "images/check.png"
                              }
                              alt=""
                            />
                          </div>
                          <span className="semi-bold font__size--14 text__14-1024 text__14-xx ml-lg-2 mt-1 mt-lg-0 w-100 d-lg-inline-block">
                            <span className="d-none d-lg-inline-block">
                              Game
                            </span>{" "}
                            Screenshots
                          </span>
                        </div>
                        <div
                          onClick={() => this.onStep(3)}
                          className={
                            "list d-flex pointer flex-wrap flex-lg-nowrap justify-content-lg-start justify-content-center text-center text-lg-left align-items-center " +
                            (this.state.next > 4 ? "active" : "") +
                            (this.state.next - 1 > 4 ? " success" : "")
                          }
                        >
                          <div className="number flex-shrink-0 d-flex justify-content-center align-items-center semi-bold font__size--14 text__14-1024">
                            <span>4</span>
                            <img
                              src={
                                process.env.REACT_APP_URL + "images/check.png"
                              }
                              alt=""
                            />
                          </div>
                          <span className="semi-bold font__size--14 text__14-1024 text__14-xx ml-lg-2 mt-1 mt-lg-0 w-100 d-lg-inline-block">
                            Upload File
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <div className="col-md-11 col-xl-9">
                      <div
                        className={
                          "wrapper__conten-upload-form overflow-hidden " +
                          (this.state.next == 5 ? "bg__transparent" : null)
                        }
                      >
                        <div
                          className={
                            "bg__white " +
                            (this.state.next - 1 == 1 ? " current " : "") +
                            (this.state.next > 1 ? " cancle " : " next ")
                          }
                        >
                          <div className="head">
                            <h5 className="semi-bold font__size--12 text__12-1024 color__softblue mb-1">
                              Step 1/4
                            </h5>
                            <h4 className="semi-bold font__size--14 text__14-1024">
                              Game Overview
                            </h4>
                          </div>
                          <hr className="m-0" />
                          <div className="upload__form">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                >
                                  Game Name
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <input
                                    type="text"
                                    name="game_name"
                                    onChange={this.onChangeInput}
                                    value={this.state.dataGame.game_name}
                                    className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="Input your game name here"
                                  />
                                </div>

                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.game_name}
                                </p>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div
                                    className={
                                      "form-group " +
                                      (this.state.role == "admin"
                                        ? "col-md-6"
                                        : this.state.dataGame.status !==
                                            "Submitted" &&
                                          this.state.dataGame.status !== "in QA"
                                        ? "col-md-6"
                                        : "col-md-12")
                                    }
                                  >
                                    <label
                                      htmlFor=""
                                      className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                    >
                                      Category
                                    </label>
                                    <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                      <select
                                        name="category"
                                        onChange={this.onChangeInput.bind(this)}
                                        className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                        placeholder="eg. Action"
                                        id=""
                                      >
                                        <option disabled selected hidden value>
                                          eg. Action
                                        </option>
                                        {this.state.category.map(function (
                                          object,
                                          i
                                        ) {
                                          return (
                                            <option
                                              selected={
                                                this.state.dataGame.category ==
                                                object
                                                  ? true
                                                  : false
                                              }
                                              value={object}
                                            >
                                              {object}
                                            </option>
                                          );
                                        },
                                        this)}
                                      </select>
                                    </div>
                                    <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                      {this.state.error.category}
                                    </p>
                                  </div>
                                  {this.state.role == "admin" ? (
                                    <div className="col-md-6 form-group">
                                      <label
                                        htmlFor=""
                                        className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                      >
                                        Status
                                      </label>
                                      <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                        <select
                                          name="status"
                                          onChange={this.onChangeInput}
                                          className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                          placeholder="eg. Action"
                                          id=""
                                        >
                                          <option
                                            disabled
                                            selected
                                            hidden
                                            value
                                          >
                                            eg. In QA
                                          </option>
                                          {this.state.status.map(function (
                                            object,
                                            i
                                          ) {
                                            return (
                                              <option
                                                selected={
                                                  this.state.dataGame.status ==
                                                  object
                                                    ? true
                                                    : false
                                                }
                                                value={object}
                                              >
                                                {object}
                                              </option>
                                            );
                                          },
                                          this)}
                                        </select>
                                      </div>
                                      <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                        {this.state.error.status}
                                      </p>
                                    </div>
                                  ) : this.state.type == "creator" ? (
                                    this.state.dataGame.status !==
                                      "Submitted" &&
                                    this.state.dataGame.status !== "in QA" ? (
                                      <div className="col-md-6 form-group">
                                        <label
                                          htmlFor=""
                                          className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                        >
                                          Status
                                        </label>
                                        <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                          <select
                                            name="status"
                                            onChange={this.onChangeInput}
                                            className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                            placeholder="eg. Action"
                                            id=""
                                          >
                                            <option
                                              disabled
                                              selected
                                              hidden
                                              value
                                            >
                                              eg. In QA
                                            </option>
                                            {this.state.status.map(function (
                                              object,
                                              i
                                            ) {
                                              if (object == "Submitted") {
                                                return (
                                                  <option value={object}>
                                                    {object}
                                                  </option>
                                                );
                                              }
                                            },
                                            this)}
                                          </select>
                                        </div>
                                        <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                          {this.state.error.status}
                                        </p>
                                      </div>
                                    ) : null
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                >
                                  Vendor
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <input
                                    type="text"
                                    name="vendor"
                                    onChange={this.onChangeInput}
                                    value={this.state.dataGame.vendor}
                                    className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="Input your Vendor name here"
                                  />
                                </div>
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.vendor}
                                </p>
                              </div>
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                >
                                  Engine
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <input
                                    type="text"
                                    name="engine"
                                    onChange={this.onChangeInput}
                                    value={this.state.dataGame.engine}
                                    className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="Input your Engine name here"
                                  />
                                </div>
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.engine}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                >
                                  Content Rating
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <select
                                    name="content_rating"
                                    onChange={this.onChangeInput.bind(this)}
                                    className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="eg. Action"
                                    id=""
                                  >
                                    <option disabled selected hidden value>
                                      eg. Everyone
                                    </option>
                                    {this.state.rating.map(function (
                                      object,
                                      i
                                    ) {
                                      return (
                                        <option
                                          selected={
                                            this.state.dataGame
                                              .content_rating == object
                                              ? true
                                              : false
                                          }
                                          value={object}
                                        >
                                          {object}
                                        </option>
                                      );
                                    },
                                    this)}
                                  </select>
                                </div>
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.content_rating}
                                </p>
                              </div>
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                >
                                  MSRP
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <input
                                    type="text"
                                    name="msrp"
                                    onChange={this.onChangeInput}
                                    value={this.state.dataGame.msrp}
                                    className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="Input your MSRP here"
                                  />
                                </div>
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.msrp}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            "bg__white " +
                            (this.state.next - 1 == 2 ? " current " : "") +
                            (this.state.next > 2 ? " cancle " : " next ")
                          }
                        >
                          <div className="head">
                            <h5 className="semi-bold font__size--12 text__12-1024 color__softblue mb-1">
                              Step 2/4
                            </h5>
                            <h4 className="semi-bold font__size--14 text__14-1024">
                              Game Description
                            </h4>
                          </div>
                          <hr className="m-0" />
                          <div className="upload__form">
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                              >
                                Game Notes
                              </label>
                              <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                <input
                                  type="text"
                                  name="game_note"
                                  onChange={this.onChangeInput}
                                  className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                                  placeholder="Input your game name here"
                                  value={this.state.dataGame.game_note}
                                />
                              </div>
                              <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                {this.state.error.game_note}
                              </p>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                              >
                                Description
                              </label>
                              <ReactQuill
                                theme="snow"
                                value={this.state.dataGame.description}
                                onChange={this.onEditorStateChange}
                                modules={modules}
                              />
                              <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                {this.state.error.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            "bg__white " +
                            (this.state.next - 1 == 3 ? " current " : "") +
                            (this.state.next > 3 ? " cancle " : " next ")
                          }
                        >
                          <div className="head">
                            <h5 className="semi-bold font__size--12 text__12-1024 color__softblue mb-1">
                              Step 3/4
                            </h5>
                            <h4 className="semi-bold font__size--14">
                              Game Screenshots
                            </h4>
                          </div>
                          <hr className="m-0" />
                          <div className="upload__form">
                            <div className="row">
                              <div className="col-md-5 mb-4">
                                <label
                                  htmlFor=""
                                  className="semi-bold font__size--14 text__14-1024 color__darkblue"
                                >
                                  Thumbnail / Icon Image
                                </label>
                                <ImageUpload
                                  image={this.state.dataGame.thumbnail}
                                  name="thumbnail"
                                  value={this.state.gameImage.thumbnail}
                                  RemoveImage={(e) => this.RemoveImage(e)}
                                  UplaodImage={(a, e) => this.UplaodImage(a, e)}
                                />
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.thumbnail}
                                </p>
                              </div>
                              <div className="col-md-7 mb-4">
                                <label
                                  htmlFor=""
                                  className="semi-bold font__size--14 text__14-1024 color__darkblue"
                                >
                                  Hero Image
                                </label>
                                <ImageUpload
                                  image={this.state.dataGame.hero}
                                  name="hero"
                                  value={this.state.gameImage.hero}
                                  RemoveImage={(e) => this.RemoveImage(e)}
                                  UplaodImage={(a, e) => this.UplaodImage(a, e)}
                                />
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.hero}
                                </p>
                              </div>
                            </div>
                            <label
                              htmlFor=""
                              className="semi-bold font__size--14 text__14-1024 color__darkblue"
                            >
                              Screenshoot
                            </label>
                            <div className="row">
                              <div className="col-md-6 mb-4">
                                <ImageUpload
                                  image={this.state.dataGame.screenshoot1}
                                  name="screenshoot1"
                                  value={this.state.gameImage.screenshoot1}
                                  RemoveImage={(e) => this.RemoveImage(e)}
                                  UplaodImage={(a, e) => this.UplaodImage(a, e)}
                                />
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.screenshoot1}
                                </p>
                              </div>
                              <div className="col-md-6 mb-4">
                                <ImageUpload
                                  image={this.state.dataGame.screenshoot2}
                                  name="screenshoot2"
                                  value={this.state.gameImage.screenshoot2}
                                  RemoveImage={(e) => this.RemoveImage(e)}
                                  UplaodImage={(a, e) => this.UplaodImage(a, e)}
                                />
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.screenshoot2}
                                </p>
                              </div>
                              <div className="col-md-6 mb-4">
                                <ImageUpload
                                  image={this.state.dataGame.screenshoot3}
                                  name="screenshoot3"
                                  value={this.state.gameImage.screenshoot3}
                                  RemoveImage={(e) => this.RemoveImage(e)}
                                  UplaodImage={(a, e) => this.UplaodImage(a, e)}
                                />
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.screenshoot3}
                                </p>
                              </div>
                              <div className="col-md-6 mb-4">
                                <ImageUpload
                                  image={this.state.dataGame.screenshoot4}
                                  name="screenshoot4"
                                  value={this.state.gameImage.screenshoot4}
                                  RemoveImage={(e) => this.RemoveImage(e)}
                                  UplaodImage={(a, e) => this.UplaodImage(a, e)}
                                />
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.screenshoot4}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            (this.state.next - 1 == 4 ? " current " : "") +
                            (this.state.next > 4 ? " cancle " : " next ")
                          }
                        >
                          <div className="bg__white">
                            <div className="head">
                              <h5 className="semi-bold font__size--12 text__12-1024 color__softblue mb-1">
                                Step 4/4
                              </h5>
                              <h4 className="semi-bold font__size--14">
                                Upload Game File
                              </h4>
                            </div>
                            <hr className="m-0" />
                            <div className="upload__form">
                              <div className="row">
                                <div className="col-md-12 form-group">
                                  <label
                                    htmlFor=""
                                    className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                  >
                                    Package Name
                                  </label>
                                  <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                    <input
                                      type="text"
                                      name="packge"
                                      onChange={this.onChangeInput}
                                      value={this.state.dataGame.packge}
                                      className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                      placeholder="Input your Package name here"
                                    />
                                  </div>
                                  <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                    {this.state.error.packge}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-wrap justify-content-start align-items-center">
                                <div className="wrapper__check-box mb-2 mr-3 d-flex justify-content-start align-items-center">
                                  <input
                                    type="checkbox"
                                    id="jps"
                                    name="jps"
                                    value="JP5 Compatible"
                                    defaultChecked={
                                      this.state.loading != true
                                        ? this.state.dataGame.compatible.find(
                                            (e) => {
                                              return e == "JP5 Compatible";
                                            }
                                          ) == "JP5 Compatible"
                                          ? true
                                          : false
                                        : null
                                    }
                                    onClick={this.onChangeComplete}
                                  />
                                  <label
                                    onChange={this.onChangeComplete}
                                    htmlFor="jps"
                                    className="ml-2 font__size--14"
                                  >
                                    JP5 Compatible
                                  </label>
                                </div>
                                <div className="wrapper__check-box mb-2 mr-3 d-flex justify-content-start align-items-center">
                                  <input
                                    type="checkbox"
                                    id="jpsMini"
                                    name="jpsMini"
                                    value="JP5 Mini Compatible"
                                    defaultChecked={
                                      this.state.loading != true
                                        ? this.state.dataGame.compatible.find(
                                            (e) => {
                                              return e == "JP5 Mini Compatible";
                                            }
                                          ) == "JP5 Mini Compatible"
                                          ? true
                                          : false
                                        : null
                                    }
                                    onClick={this.onChangeComplete}
                                  />
                                  <label
                                    onChange={this.onChangeComplete}
                                    htmlFor="jpsMini"
                                    className="ml-2 font__size--14"
                                  >
                                    JP5 Mini Compatible
                                  </label>
                                </div>
                                <div className="wrapper__check-box mb-2 d-flex justify-content-start align-items-center">
                                  <input
                                    type="checkbox"
                                    id="jp6"
                                    name="jp6"
                                    value="JP6 Compatible"
                                    defaultChecked={
                                      this.state.loading != true
                                        ? this.state.dataGame.compatible.find(
                                            (e) => {
                                              return e == "JP6 Compatible";
                                            }
                                          ) == "JP6 Compatible"
                                          ? true
                                          : false
                                        : null
                                    }
                                    onClick={this.onChangeComplete}
                                  />
                                  <label
                                    onChange={this.onChangeComplete}
                                    htmlFor="jp6"
                                    className="ml-2 font__size--14"
                                  >
                                    JP6 Compatible
                                  </label>
                                </div>
                              </div>
                              <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                {this.state.error.compatible}
                              </p>
                            </div>
                          </div>
                          <div className="my-4">
                            <div className="d-sm-flex justify-content-between align-items-center">
                              <h5 className="m-0 semi-bold font__size--16">
                                Your game version
                              </h5>
                              {this.state.role == "admin" ? (
                                this.state.loading != true ? (
                                  JSON.parse(
                                    localStorage.getItem("userData").role ==
                                      "admin"
                                  ) || this.state.gameDetail.status_id != 3 ? (
                                    <div className="d-flex justify-content-end align-items-center mt-4 mt-sm-0">
                                      <select
                                        onChange={this.selectVersion}
                                        class="wrapper__select-verison mr-3 font__size--12 text__12-1024 semi-bold"
                                        value={
                                          this.state.selectedVersion
                                            ? this.state.dataGame.version
                                            : "version"
                                        }
                                      >
                                        <option
                                          disabled
                                          selected
                                          hidden
                                          value="version"
                                        >
                                          Version
                                        </option>
                                        {this.state.loading != true
                                          ? this.state.version.map(function (
                                              object,
                                              i
                                            ) {
                                              return (
                                                <option value={object}>
                                                  {object}
                                                </option>
                                              );
                                            },
                                            this)
                                          : null}
                                      </select>
                                      <div
                                        onClick={this.addNewVersion}
                                        className="btn btn__update-verison btn__blue color__white shadow font__size--12 text__12-1024"
                                      >
                                        <div className="d-flex justify-content-center align-items-center">
                                          Add New{" "}
                                          <img
                                            src={
                                              process.env.REACT_APP_URL +
                                              "images/sadsa.png"
                                            }
                                            className="ml-2"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                ) : null
                              ) : this.state.dataGame.status !== "Submitted" &&
                                this.state.dataGame.status !== "in QA" ? (
                                this.state.loading != true ? (
                                  JSON.parse(
                                    localStorage.getItem("userData").role ==
                                      "admin"
                                  ) || this.state.gameDetail.status_id != 3 ? (
                                    <div className="d-flex justify-content-end align-items-center mt-4 mt-sm-0">
                                      <select
                                        onChange={this.selectVersion}
                                        class="wrapper__select-verison mr-3 font__size--12 text__12-1024 semi-bold"
                                        value={
                                          this.state.selectedVersion
                                            ? this.state.dataGame.version
                                            : "version"
                                        }
                                      >
                                        <option
                                          disabled
                                          selected
                                          hidden
                                          value="version"
                                        >
                                          Version
                                        </option>
                                        {this.state.loading != true
                                          ? this.state.version.map(function (
                                              object,
                                              i
                                            ) {
                                              return (
                                                <option value={object}>
                                                  {object}
                                                </option>
                                              );
                                            },
                                            this)
                                          : null}
                                      </select>
                                      <div
                                        onClick={this.addNewVersion}
                                        className="btn btn__update-verison btn__blue color__white shadow font__size--12 text__12-1024"
                                      >
                                        <div className="d-flex justify-content-center align-items-center">
                                          Add New{" "}
                                          <img
                                            src={
                                              process.env.REACT_APP_URL +
                                              "images/sadsa.png"
                                            }
                                            className="ml-2"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            "wrapper__btn-upload  mt-3 mb-3 " +
                            (this.state.next == 5 ? "bg__white pb-3" : null)
                          }
                        >
                          {this.state.next == 5 ? (
                            <div className="mb-3 pt-3">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6"
                                >
                                  Version
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <input
                                    type="text"
                                    name="version"
                                    onChange={this.onChangeInput}
                                    value={this.state.dataGame.version}
                                    className="form-control input font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="Input your Version here"
                                  />
                                </div>
                                <p className="font__size--12 color__softblue m-0">
                                  (*) If updating the APK, the version number
                                  must be updated
                                </p>
                                <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                  {this.state.error.version}
                                </p>
                              </div>
                              <ApkUpload
                                image={this.state.dataGame.apk}
                                name="apk"
                                varsion={this.state.version_name}
                                file={this.state.file_name}
                                size={this.state.size_name}
                                RemoveImage={(e) => this.RemoveImage(e)}
                                UplaodImage={(a, e) => this.UplaodImage(a, e)}
                              />
                              <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                {this.state.error.apk}
                              </p>
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 text__14-1024 color__darkblue opacity__6 mt-3"
                              >
                                Resource Game
                              </label>
                              <ApkUpload
                                image={this.state.dataGame.resources}
                                name="resources"
                                varsion={this.state.version_name}
                                file={this.state.file_resource}
                                size={this.state.size_resource}
                                RemoveImage={(e) => this.RemoveImage(e)}
                                UplaodImage={(a, e) => this.UplaodImage(a, e)}
                              />
                              <p className="color__red font__size--14 lh-2 m-0 text__14-1024">
                                {this.state.error.resources}
                              </p>
                            </div>
                          ) : null}

                          <div className="d-flex justify-content-end align-items-center">
                            <div
                              onClick={this.cancleStep.bind(this)}
                              className="pointer semi-bold font__size--18 text__18-1024 text__18-xs color__blue mr-3"
                            >
                              Back
                            </div>
                            <div
                              onClick={this.nextStep.bind(this)}
                              className="pointer semi-bold font__size--18 text__18-1024 text__18-xs btn btn__blue color__white shadow btn__next"
                            >
                              {this.state.next == 5 ? "Submit" : "Next Step"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
          <LoadingUpload
            upload={this.state.upload}
            percentage={this.state.percentage}
            validation={this.state.validation}
          />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Index);
