import React, { Fragment, useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

import QaResult from "./../../../component/dashboard/card/QaResult";

const Qa = (props) => {
  useEffect(() => {});
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h5 className="semi-bold font__size--16 text__16-1024">QA Result</h5>
        {props.loading != true ? (
          props.reviewStatus() ? (
            <NavLink
              to={"/dashboard/qa/add-review/" + props.slug}
              className="btn btn__blue color__white semi-bold font__size--12 shadow btn__add-review"
            >
              Add Review
            </NavLink>
          ) : null
        ) : null}
      </div>
      {props.version_data.map((item) => {
        return props.review_data.map((rev) => {
          if (item.id == rev.apk_id) {
            return (
              <QaResult
                slug={props.slug}
                version={item}
                review_data={props.review_data}
                changelog_data={props.changelog_data}
                result_data={props.result_data}
                contentDetail_data={props.contentDetail_data}
                toogleModal={(e) => props.toogleModal(e)}
                reviewStatus={() => props.reviewStatus()}
                loading={props.loading}
                toogleModalResult={(e) => props.toogleModalResult(e)}
              />
            );
          }
        });
      })}
    </Fragment>
  );
};

export default Qa;
