import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import dateFormat from "dateformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

import Sidebar from "../../../component/dashboard/sidebar/index";
import Navbar from "../../../component/dashboard/navbar/index";
import TagGame from "../../../component/dashboard/button/tagGame";
import Overview from "./overview";
import Information from "./information";
import QA from "./qa";
import DetailGame from "../../../component/dashboard/card/DetailGame";
import Recognition from "./Recognition";
import Footer from "../../../component/dashboard/footer/index";
import Loading from "../../../component/dashboard/card/loading";
import PreviewImage from "../../../component/dashboard/modal/previewImage";
import ModalConfirm from "../../../component/dashboard/modal/modalConfirm";
import ModalStatus from "../../../component/dashboard/modal/modalStatus";

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
      detail: [],
      loading: true,
      apkVersion: [],
      apkResouces: [],
      compatible: [],
      gameDetail: [],
      gameImages: [],
      version_data: [],
      review_data: [],
      changelog_data: [],
      result_data: [],
      contentDetail_data: [],
      preview: false,
      modal: false,
      modalResult: false,
      modalValidation: false,
      modalStatus: false,
      reviewId: "",
      resultId: "",
    };
  }
  componentDidMount() {
    const slug = this.props.match.params.slug;
    this.getDetail(slug);
  }

  async getDetail(slug) {
    const cookies = new Cookies();
    this.setState({
      loading: true,
    });
    await axios
      .get(process.env.REACT_APP_API + `game/detail/${slug}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          console.log(res.data.data);
          this.setState({
            loading: false,
            apkVersion: res.data.data.apkVersion,
            apkResouces: res.data.data.apkResouces,
            compatible: res.data.data.compatible,
            gameDetail: res.data.data.gameDetail,
            gameImages: res.data.data.gameImages,
            version_data: res.data.data.version_data,
            review_data: res.data.data.review_data,
            changelog_data: res.data.data.changelog_data,
            result_data: res.data.data.result_data,
            contentDetail_data: res.data.data.contentDetail_data,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  }

  deleteReview = () => {
    const cookies = new Cookies();
    this.setState({
      modalValidation: true,
    });
    axios
      .get(
        process.env.REACT_APP_API +
          `game/qa/review/delete/${this.state.reviewId}`,
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(
        (res) => {
          this.getDetail(this.props.match.params.slug);
          this.setState({
            modalValidation: false,
            modal: false,
            modalResult: false,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  deleteResult = () => {
    const cookies = new Cookies();
    this.setState({
      modalValidation: true,
    });
    axios
      .get(
        process.env.REACT_APP_API +
          `game/qa/review/result/edit/update/${this.state.resultId}`,
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(
        (res) => {
          this.getDetail(this.props.match.params.slug);
          this.setState({
            modalValidation: false,
            modal: false,
            modalResult: false,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  updateStatus = async (e) => {
    const cookies = new Cookies();
    await axios
      .post(
        process.env.REACT_APP_API +
          `game/status/${this.props.match.params.slug}`,
        {
          status: e,
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(
        (res) => {
          toast.success("Status updated successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          this.getDetail(this.props.match.params.slug);
          this.setState({
            modalStatus: false,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  componentDidUpdate() {}

  reviewStatus = () => {
    if (
      this.state.gameDetail.status_id == 5 &&
      JSON.parse(localStorage.getItem("userData")).role == "admin"
    ) {
      return true;
    } else {
      return false;
    }
  };

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  tooglePrivew() {
    this.setState({
      preview: !this.state.preview,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  toogleModalStatus() {
    this.setState({
      modalStatus: !this.state.modalStatus,
    });
  }

  toogleModal = (e) => {
    this.setState({
      modal: !this.state.modal,
      reviewId: e,
    });
  };
  toogleModalResult = (e) => {
    this.setState({
      modalResult: !this.state.modalResult,
      resultId: e,
    });
  };

  render() {
    const apkVersion = [];

    for (var i = 0; i < 2; i++) {
      apkVersion.push(
        <div
          className="wrapper__content-desc bg__white mb-4 position-relative"
          style={{ padding: "1rem" }}
        >
          <Loading status="apk" />
        </div>
      );
    }
    return (
      <Fragment>
        <ToastContainer />
        <div className="overflow-hidden dashboard">
          <PreviewImage
            tooglePrivew={() => this.tooglePrivew()}
            showing={this.state.preview}
            item={this.state.gameImages}
          />
          <ModalConfirm
            modal={this.state.modal}
            modalValidation={this.state.modalValidation}
            toogleModal={() => this.toogleModal()}
            title={"Are you sure you want to delete your review?"}
            button={"Yes"}
            goto={() => this.deleteReview()}
          />
          <ModalConfirm
            modal={this.state.modalResult}
            modalValidation={this.state.modalValidation}
            toogleModal={() => this.toogleModalResult()}
            toogleStatus="new"
            title={"Are you sure you want to delete your result?"}
            button={"Yes"}
            goto={() => this.deleteResult()}
          />
          {this.state.loading != true ? (
            <ModalStatus
              modalStatus={this.state.modalStatus}
              toogleModalStatus={() => this.toogleModalStatus()}
              status={this.state.gameDetail.name_status}
              loading={this.state.loading}
              updateStatus={(e) => this.updateStatus(e)}
            />
          ) : null}

          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Detail"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <div className="row mb-5">
                    <div className="order-md-1 order-12 col-md-5 col-xl-3 flex-fill ">
                      {this.state.loading ? (
                        <Loading status={"card"} />
                      ) : (
                        <div className="wrapper__list-game">
                          <img
                            src={this.state.gameImages[0].url}
                            className="preview"
                            alt=""
                          />
                          <h5 className="semi-bold font__size--14 text-capitalize text__14-1024 color__black d-inline-block text__1024 mb-0 mt-3 lh__5">
                            {this.state.gameDetail.game_name}
                          </h5>
                          <hr className="hr__game my-2" />
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <TagGame
                              name={this.state.gameDetail.name_category}
                            />
                            <div className="d-flex justify-content-start align-items-center ">
                              <img
                                src={
                                  process.env.REACT_APP_URL + "images/wwws.png"
                                }
                                alt=""
                              />
                              <span className="semi-bold font__size--12 text__12-1024 text__12-1024 color__softblue ml-2">
                                {dateFormat(
                                  this.state.gameDetail.created_at,
                                  "yyyy-mm-dd"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="order-1 order-md-12 mb-4 mb-md-0 col-md-7 col-xl-9 flex-fill ">
                      {this.state.loading ? (
                        <Loading status={"hero"} />
                      ) : (
                        <img
                          src={this.state.gameImages[1].url}
                          className="wrapper__banner"
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 mb-4 mb-lg-0">
                      <ul
                        class="nav nav-tabs wrapper__navtab"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link semi-bold font__size--12 text__12-1024 color__softblue active"
                            id="overview-tab"
                            data-toggle="tab"
                            href="#overview"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                          >
                            Overview
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link semi-bold font__size--12 text__12-1024 color__softblue"
                            id="qa-esult-tab"
                            data-toggle="tab"
                            href="#qa-esult"
                            role="tab"
                            aria-controls="qa-esult"
                            aria-selected="false"
                          >
                            QA Result
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link semi-bold font__size--12 text__12-1024 color__softblue"
                            id="warnings-tab"
                            data-toggle="tab"
                            href="#warnings"
                            role="tab"
                            aria-controls="warnings"
                            aria-selected="false"
                          >
                            Recognition Warnings
                          </a>
                        </li>
                      </ul>

                      <div className="wrapper__content-desc bg__white">
                        <div className="padding-desc">
                          <div class="tab-content mt-3" id="myTabContent">
                            <div
                              class="tab-pane fade show active"
                              id="overview"
                              role="tabpanel"
                              aria-labelledby="overview-tab"
                            >
                              <Overview
                                tooglePrivew={() => this.tooglePrivew()}
                                item={this.state.gameDetail}
                                images={this.state.gameImages}
                              />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="qa-esult"
                              role="tabpanel"
                              aria-labelledby="qa-esult-tab"
                            >
                              <QA
                                loading={this.state.loading}
                                slug={this.state.gameDetail.slug}
                                version_data={this.state.version_data}
                                review_data={this.state.review_data}
                                changelog_data={this.state.changelog_data}
                                result_data={this.state.result_data}
                                contentDetail_data={
                                  this.state.contentDetail_data
                                }
                                toogleModal={(e) => this.toogleModal(e)}
                                toogleModalResult={(e) =>
                                  this.toogleModalResult(e)
                                }
                                reviewStatus={() => this.reviewStatus()}
                              />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="warnings"
                              role="tabpanel"
                              aria-labelledby="warnings-tab"
                            >
                              <Recognition />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="wrapper__content-desc bg__white">
                        <div className="padding-desc">
                          <Information
                            detail={this.state.gameDetail}
                            apkResouces={this.state.apkResouces}
                            loading={this.state.loading}
                            compatible={this.state.compatible}
                            toogleModalStatus={() => this.toogleModalStatus()}
                          />
                        </div>
                      </div>
                      <h5 className="semi-bold font__size--16 text__16-1024 mt-3 mb-3">
                        APKs
                      </h5>

                      {this.state.loading
                        ? apkVersion
                        : this.state.apkVersion.map(function (object, i) {
                            return (
                              <DetailGame
                                status={i == 0 ? true : false}
                                item={object}
                                review_data={this.state.review_data}
                                changelog_data={this.state.changelog_data}
                                result_data={this.state.result_data}
                              />
                            );
                          }, this)}
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Detail);
