import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { cloneDeep } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "./../../../../component/dashboard/sidebar/index";
import Navbar from "./../../../../component/dashboard/navbar/index";
import Footer from "./../../../../component/dashboard/footer/index";
import Input from "./detail/input";
import Content from "./detail/content";
import LoadingUpload from "../../../../component/dashboard/modal/loadingUpload";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
      loading: true,
      version: [],
      detailGame: [],
      percentage: 0,
      validation: false,
      upload: false,
      error: [],
      status: ["Need Action", "Approved", "Rejected", "in QA"],
      number: 1,
      numberChangelog: 1,
      numberContent: 1,
      wrapperFrom: {
        version: "",
        status: "",
        changelog: [{ id: 0, text: "" }],
        result_device: "",
        result_android: "",
        result_status: "",
        content: [
          { id: 0, item: "", result: "", comment: "", content_media: [] },
        ],
      },
    };
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;
    this.getVerson(slug);
  }

  getVerson = (slug) => {
    const cookies = new Cookies();
    this.setState({
      loading: true,
    });
    axios
      .get(process.env.REACT_APP_API + `game/qa/review/` + slug, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(
        (res) => {
          var data = { ...this.state.wrapperFrom };

          if (res.data.data.gameDetail.status_id == 1) {
            data["status"] = "Need Action";
          } else if (res.data.data.gameDetail.status_id == 2) {
            data["status"] = "Approved";
          } else if (res.data.data.gameDetail.status_id == 4) {
            data["status"] = "Rejected";
          } else if (res.data.data.gameDetail.status_id == 5) {
            data["status"] = "in QA";
          }

          this.setState({
            version: res.data.data.version,
            detailGame: res.data.data.gameDetail,
            loading: false,
            wrapperFrom: data,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };
  getChangelog = (id) => {
    const cookies = new Cookies();
    axios
      .get(process.env.REACT_APP_API + `game/qa/review/changelog/` + id, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(
        (res) => {
          if (res.data.data.changelog.length > 0) {
            const dataFirst = this.state.wrapperFrom.changelog.filter(
              (item) => item.id === -1
            );
            const newStateFirst = cloneDeep(this.state);
            newStateFirst.wrapperFrom.changelog = dataFirst;
            newStateFirst.numberChangelog = 0;
            this.setState(newStateFirst);

            const newState = cloneDeep(this.state);

            res.data.data.changelog.map((obj) => {
              newState.wrapperFrom.changelog = [
                ...newState.wrapperFrom.changelog,
                { id: newState.numberChangelog, text: obj.text },
              ];
              newState.numberChangelog = newState.numberChangelog + 1;
            });
            this.setState(newState);
          } else {
            const dataFirst = this.state.wrapperFrom.changelog.filter(
              (item) => item.id === -1
            );
            const newStateFirst = cloneDeep(this.state);
            newStateFirst.wrapperFrom.changelog = dataFirst;
            newStateFirst.numberChangelog = 0;
            this.setState(newStateFirst);

            newStateFirst.wrapperFrom.changelog = [
              ...newStateFirst.wrapperFrom.changelog,
              { id: newStateFirst.numberChangelog, text: "" },
            ];
            newStateFirst.numberChangelog = newStateFirst.numberChangelog + 1;
            this.setState(newStateFirst);
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  onSubmit = () => {
    const cookies = new Cookies();
    let formData = new FormData();

    this.setState({
      upload: true,
      error: [],
    });

    formData.append("versi", this.state.wrapperFrom.version);
    formData.append("status", this.state.wrapperFrom.status);

    this.state.wrapperFrom.changelog.forEach((file) =>
      formData.append("changelog[]", file.text)
    );

    formData.append("result_android", this.state.wrapperFrom.result_device);
    formData.append("result_device", this.state.wrapperFrom.result_android);
    formData.append("result_status", this.state.wrapperFrom.result_status);

    let numberId = 0;

    this.state.wrapperFrom.content.forEach((file) => {
      formData.append("content_comment[]", file.comment);
      formData.append("content_item[]", file.item);
      formData.append("content_result[]", file.result);

      if (file.content_media != "") {
        formData.append("content_media" + numberId, file.content_media);
      }

      numberId = numberId + 1;
    });

    axios
      .post(
        process.env.REACT_APP_API +
          `game/qa/review/submit/` +
          this.props.match.params.slug,
        formData,
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.round((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            this.setState({
              percentage: percent,
            });

            if (percent == 100) {
              this.setState({
                validation: true,
              });
            }
          },
        }
      )
      .then(
        (res) => {
          if (res.data.success == "success") {
            window.location.replace(
              process.env.REACT_APP_URL +
                "dashboard/detail/" +
                this.props.match.params.slug
            );
          } else {
            toast.error("there is an error please check your input", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              // closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });

            this.setState({
              error: res.data.errors,
              upload: false,
              percentage: 0,
              validation: false,
            });
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  addInput = async () => {
    const newState = cloneDeep(this.state);
    newState.wrapperFrom.changelog = [
      ...newState.wrapperFrom.changelog,
      { id: newState.numberChangelog, text: "" },
    ];
    newState.numberChangelog = newState.numberChangelog + 1;
    this.setState(newState);
  };

  removeInput = (e) => {
    const data = this.state.wrapperFrom.changelog.filter(
      (item) => item.id !== e
    );
    const newState = cloneDeep(this.state);
    newState.wrapperFrom.changelog = data;

    this.setState(newState);
  };

  removeInputContent = (e) => {
    const data = this.state.wrapperFrom.content.filter((item) => item.id !== e);
    const newState = cloneDeep(this.state);
    newState.wrapperFrom.content = data;

    this.setState(newState);
  };

  onChangeInput = (t, id) => {
    var data = [...this.state.wrapperFrom.changelog];
    var index = data.findIndex((obj) => obj.id === id);
    data[index].text = t;

    this.setState({ data });
  };

  onChangeText = (e) => {
    var data = { ...this.state.wrapperFrom };
    data[e.target.name] = e.target.value;

    if (e.target.name == "version") {
      this.getChangelog(e.target.value);
    }

    this.setState({ wrapperFrom: data });
  };

  addContentInput = async () => {
    const newState = cloneDeep(this.state);
    newState.wrapperFrom.content = [
      ...newState.wrapperFrom.content,
      {
        id: newState.numberContent,
        item: "",
        result: "",
        comment: "",
        content_media: [],
      },
    ];

    newState.numberContent = newState.numberContent + 1;
    this.setState(newState);
  };

  onChangeInputContent = (t, n, id) => {
    var data = [...this.state.wrapperFrom.content];
    var index = data.findIndex((obj) => obj.id === id);
    data[index][n] = t;

    this.setState({ data });
  };

  componentDidUpdate() {}

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className="overflow-hidden dashboard">
          <LoadingUpload
            upload={this.state.upload}
            percentage={this.state.percentage}
            validation={this.state.validation}
          />
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Account"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="mt-5">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 col-xl-10">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="semi-bold font__size--20 mb-0">
                          Add your review
                        </h5>
                      </div>
                      <div className="wrapper__review-wrap bg__white mb-4">
                        <label
                          htmlFor=""
                          className="semi-bold font__size--14 opacity__7"
                        >
                          Version
                        </label>
                        <div className="d-block">
                          <select
                            className="wrapper__input-review select"
                            onChange={this.onChangeText}
                            name="version"
                            id=""
                          >
                            <option disabled selected hidden value>
                              eg. Version
                            </option>
                            {this.state.loading != true
                              ? this.state.version.map(function (object, i) {
                                  return (
                                    <option value={object.id}>
                                      {object.version}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                        <p className="color__red m-0 lh-1">
                          {this.state.error.versi
                            ? "The field is required."
                            : null}
                        </p>
                        <label
                          htmlFor=""
                          className="semi-bold font__size--14 mt-3 opacity__7"
                        >
                          Status
                        </label>
                        <div className="d-block">
                          <select
                            className="wrapper__input-review select"
                            onChange={this.onChangeText}
                            name="status"
                            id=""
                          >
                            <option disabled selected hidden value>
                              eg. Status
                            </option>
                            {this.state.loading != true
                              ? this.state.status.map(function (object, i) {
                                  return (
                                    <option
                                      selected={
                                        this.state.wrapperFrom.status === object
                                          ? true
                                          : false
                                      }
                                      value={object}
                                    >
                                      {object}
                                    </option>
                                  );
                                }, this)
                              : null}
                          </select>
                        </div>
                        <p className="color__red m-0 lh-1">
                          {this.state.error.status
                            ? "The field is required."
                            : null}
                        </p>
                        <div className="mt-3">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <label
                              htmlFor=""
                              className="semi-bold font__size--14 opacity__7 mb-0"
                            >
                              Changelog
                            </label>
                            <div
                              className="semi-bold font__size--14 color__blue pointer"
                              onClick={this.addInput}
                            >
                              + Add New
                            </div>
                          </div>
                          {this.state.wrapperFrom.changelog.map((obj, i) => {
                            return (
                              <Fragment>
                                <Input
                                  removeInput={(e) => this.removeInput(e)}
                                  id={obj.id}
                                  value={obj.text}
                                  onChangeInput={(t, id) =>
                                    this.onChangeInput(t, id)
                                  }
                                  error={this.state.error["changelog." + i]}
                                />
                              </Fragment>
                            );
                          }, this)}
                        </div>
                        <div className="mt-3">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <label
                              htmlFor=""
                              className="semi-bold font__size--14 opacity__7 mb-0"
                            >
                              Results
                            </label>
                          </div>
                          <div className="wrapper__wrap-result mb-4">
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 opacity__7 mb-2"
                                >
                                  Device
                                </label>
                                <input
                                  name="result_device"
                                  onChange={this.onChangeText}
                                  value={this.state.wrapperFrom.result_device}
                                  className="wrapper__input-review select"
                                  type="text"
                                />
                                <p className="color__red mb-4 lh-1 font__size--14">
                                  {this.state.error["result_device"]}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 opacity__7 mb-2"
                                >
                                  Android Version
                                </label>
                                <input
                                  className="wrapper__input-review select"
                                  name="result_android"
                                  onChange={this.onChangeText}
                                  value={this.state.wrapperFrom.result_android}
                                  type="text"
                                />
                                <p className="color__red mb-4 lh-1 font__size--14">
                                  {this.state.error["result_android"]}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 opacity__7 mb-2"
                                >
                                  Status
                                </label>
                                <select
                                  name=""
                                  name="result_status"
                                  onChange={this.onChangeText}
                                  className="wrapper__input-review select"
                                  id=""
                                >
                                  <option disabled selected hidden value>
                                    eg. Status
                                  </option>
                                  <option value="pass">Pass</option>
                                  <option value="fail">Fail</option>
                                </select>
                                <p className="color__red mb-4 lh-1 font__size--14">
                                  {this.state.error["result_status"]}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <label
                                htmlFor=""
                                className="semi-bold font__size--14 opacity__7 mb-0"
                              >
                                Content Details
                              </label>
                              <div
                                className="semi-bold font__size--14 color__blue pointer"
                                onClick={this.addContentInput}
                              >
                                + Add New
                              </div>
                            </div>
                            {this.state.wrapperFrom.content.map((obj, i) => {
                              return (
                                <Content
                                  id={obj.id}
                                  removeInputContent={(e) =>
                                    this.removeInputContent(e)
                                  }
                                  text={obj}
                                  onChangeInputContent={(t, n, id) =>
                                    this.onChangeInputContent(t, n, id)
                                  }
                                  errorComment={
                                    this.state.error["content_comment." + i]
                                  }
                                  errorItem={
                                    this.state.error["content_item." + i]
                                  }
                                  errorResult={
                                    this.state.error["content_result." + i]
                                  }
                                  errorMedia={
                                    this.state.error["content_media" + i]
                                  }
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="text-right">
                          <div
                            onClick={this.onSubmit}
                            className="pointer semi-bold font__size--18 text__18-1024 text__18-xs btn btn__blue color__white shadow btn__next"
                          >
                            Submit
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Index);
