import React, { Fragment } from "react";
const overview = (props) => {
  return (
    <Fragment>
      <h5 className="semi-bold font__size--16 text__16-1024 mb-3">
        Game Screenshots
      </h5>

      <div className="wrapper__show-preview wrap__flex-sm-50">
        {props.images.map(function (object, i) {
          return object.type == "screenshoot" ? (
            <div
              className="grid mb-3 mb-md-0 previw__image pointer"
              onClick={() => props.tooglePrivew()}
            >
              <img src={object.url} alt="" />
            </div>
          ) : (
            ""
          );
        })}
      </div>
      <hr className="hr__min-1" />
      <h5 className="semi-bold font__size--16 text__16-1024">
        Small Description
      </h5>
      <p className="m-0 font__size--14 text__14-1024 opacity__7">
        {props.item.game_note}
      </p>
      <hr className="hr__min-1" />
      <h5 className="semi-bold font__size--16 text__16-1024 mb-3">
        Full Description
      </h5>
      <div
        className="font__size--14 text__14-1024 opacity__7"
        dangerouslySetInnerHTML={{ __html: props.item.description }}
      ></div>
    </Fragment>
  );
};

export default overview;
