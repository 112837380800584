import React, { Component, Fragment, useRef } from "react";

const ApkUpload = (props) => {
  const selectAPK = useRef(null);
  const clickToChangeApk = () => {
    selectAPK.current.click();
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      props.UplaodImage(props.name, e.target.files);
    }
  };

  const removeInput = () => {
    selectAPK.current.value = "";
    props.RemoveImage(props.name);
  };
  const toSIze = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  return (
    <Fragment>
      <div className="wrapper__upload-form d-flex justify-content-center align-items-center position-relative">
        <div
          onClick={removeInput}
          className={
            "close__upload pointer " + (props.image.length > 0 ? "showing" : "")
          }
        >
          <img src={process.env.REACT_APP_URL + "images/sssdw.png"} alt="" />
        </div>
        <div>
          <input
            type="file"
            name={props.name}
            ref={selectAPK}
            onChange={imageChange}
            value={props.image.length == 0 ? null : null}
            className="form-control wrapper__hide input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
            placeholder="Insert your text here"
          />

          <div className="text-center pointer" onClick={clickToChangeApk}>
            <img
              src={process.env.REACT_APP_URL + "images/sadsadsad.png"}
              alt=""
            />
            <h5 className="semi-bold font__size--12 text__12-1024 mt-2 mb-1">
              {props.image.length > 0
                ? props.image[0].name
                : props.file != ""
                ? props.file
                : "Click to upload your game file here"}
            </h5>
            <p className="font__size--10 m-0 lh-1">
              {props.image.length > 0
                ? "Size File : " + toSIze(props.image[0].size)
                : props.file != ""
                ? "Size File : " + toSIze(props.size)
                : props.size
                ? "Max Size : " + props.size
                : "Max Size : 200 MB"}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApkUpload;
