import React, { Component, Fragment } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const LoadingUpload = (props) => {
  return (
    <Fragment>
      <div
        className={
          "modal wrapper__modal-app fade " + (props.upload ? "show" : "")
        }
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <div className="mx-auto" style={{ width: 164, height: 164 }}>
                <CircularProgressbarWithChildren
                  value={props.percentage}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `rgba(20, 54, 228, ${props.percentage / 100})`,
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                  }}
                >
                  <div
                    className="bold font__size--40 text__40-1024"
                    style={{ fontSize: 12, marginTop: -5 }}
                  >
                    {props.percentage}%
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="text-center mt-3 text__16">
                {props.validation
                  ? "Wait a moment, it's finishing the upload process"
                  : "the upload process is in progress do not close your browser window"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoadingUpload;
