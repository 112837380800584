import React, { Component } from "react";
import { Fragment } from "react";
import { Pie } from "react-chartjs-2";
// 1 aprove
// 2 submit
// 3 qa
// 4 need
// 5 riject
const donut = (props) => {
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          props.approved,
          props.submitted,
          props.inQa,
          props.needAction,
          props.rejected,
        ],
        backgroundColor: [
          "#34D399",
          "#0C1F56",
          "#FBBF24",
          "#B965FB",
          "#F87171",
        ],
        borderColor: ["#34D399", "#0C1F56", "#FBBF24", "#B965FB", "#F87171"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart",
      },
      tooltips: {
        enabled: false,
      },
    },
  };
  return (
    <Fragment>
      <div className="wrapper__chart-donut">
        <div className="wrap"></div>
        <div className="wrap-data text-center d-flex justify-content-center align-items-center">
          <div>
            <h5 className="bold font__size--28 text__28-1024 mb-0">
              {props.approved +
                props.submitted +
                props.needAction +
                props.rejected +
                props.inQa}
            </h5>
            <p className="font__size--12 m-0 lh-1">Total Games</p>
          </div>
        </div>
        <Pie data={data} options={options} />
      </div>
    </Fragment>
  );
};

export default donut;
