import React, { useEffect, Fragment } from "react";
import Anime from "animejs/lib/anime.es.js";

const SvgLine = (params) => {
  useEffect(() => {
    const path = Anime.path("path");
    Anime({
      targets: ".move__lineH",
      strokeDashoffset: [Anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      direction: "alternate",
      delay: function (el, i) {
        return i * 100;
      },
      loop: true,
    });
  }, []);
  return (
    <Fragment>
      <svg
        className="path__49"
        width="523"
        height="1057"
        viewBox="0 0 523 1057"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M186.046 249.431V0H296.383V376.787L385.004 465.409V752.401L296.383 841.022V1057H186.046V619.762L93.9034 527.62V341.574L186.046 249.431Z"
          fill="#0BAB82"
        />
        <path
          d="M403.783 545.227L262.928 404.372V1057H403.783V545.227Z"
          fill="#0BAB82"
        />
        <path
          d="M403.783 545.227L262.928 404.372V1057H403.783V545.227Z"
          fill="#0BAB82"
        />
        <path
          d="M196.022 363.876V0H427.846V363.876L282.883 508.839V1056.41H51.0586V508.839L196.022 363.876Z"
          fill="#16C79A"
        />
        <path
          d="M262.928 436.651L332.898 506.209V525.334L262.928 456.358V436.651Z"
          fill="white"
        />
        <path
          d="M262.928 482.212L332.898 551.771V570.895L262.928 501.92V482.212Z"
          fill="white"
        />
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M262.928 527.774L332.898 597.333V616.457L262.928 547.482V527.774Z"
          stroke="white"
          stroke-width="3"
        />
        <path
          d="M262.928 573.336L332.898 642.895V662.019L262.928 593.044V573.336Z"
          fill="white"
        />
        <g filter="url(#filter0_i)">
          <path
            d="M100.945 650.281L-0.000831604 751.293V910.863L100.945 809.851V650.281Z"
            fill="white"
          />
        </g>
        <path
          d="M100.945 833.393L-0.000831604 933.745V961.336L100.945 861.825V833.393Z"
          fill="white"
        />
        <path
          d="M100.945 880.345L-0.000831604 980.697V1008.29L100.945 908.777V880.345Z"
          fill="#319CC9"
        />
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M350.963 1056.41V947.993L223.02 820.155V734.794"
          stroke="white"
        />
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M223.02 0V108.42L350.963 236.259V321.619"
          stroke="white"
        />
        <rect
          x="222.939"
          y="732"
          width="4.15701"
          height="4.15701"
          transform="rotate(45 222.939 732)"
          fill="white"
        />
        <g filter="url(#filter1_i)">
          <path
            d="M134.984 336.747V0H171.372V369.745L134.984 336.747Z"
            fill="#319CC9"
          />
        </g>
        <g filter="url(#filter2_i)">
          <path
            d="M521.498 866.259L462.473 807.179V713.85L521.498 772.93V866.259Z"
            fill="#16C79A"
          />
        </g>
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M521.498 737.636L462.473 678.556V585.227L521.498 644.307V737.636Z"
          stroke="#319CC9"
          stroke-width="3"
        />
        <path
          d="M521.498 609.014L462.473 549.934V456.605L521.498 515.685V609.014Z"
          fill="white"
        />
        <rect
          x="350.939"
          y="319"
          width="4.15701"
          height="4.15701"
          transform="rotate(45 350.939 319)"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_i"
            x="-16"
            y="640.281"
            width="116.946"
            height="270.582"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-16" dy="-10" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter1_i"
            x="124.984"
            y="-10"
            width="46.3876"
            height="379.745"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-12" dy="-35" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter2_i"
            x="446.473"
            y="703.85"
            width="75.0253"
            height="162.409"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-16" dy="-10" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
        </defs>
      </svg>
    </Fragment>
  );
};

export default SvgLine;
