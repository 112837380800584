import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";

import Status from "./../button/Status";
import TagGame from "./../button/tagGame";

const Game = (props) => {
  return (
    <Fragment>
      <div className="wrapper__list-game">
        <img src={props.item.url} className="preview" alt="" />
        <NavLink
          to={`/dashboard/detail/${props.item.slug}`}
          className="semi-bold font__size--14 color__black d-inline-block text__1024 mb-0 mt-3 text-capitalize lh__5"
        >
          {props.item.game_name}
        </NavLink>
        <hr className="hr__game my-2" />
        <div className="d-flex justify-content-between align-items-center mb-2">
          <TagGame name={props.item.name_category} />
          <div className="d-flex justify-content-start align-items-center mt-2">
            <img src={process.env.REACT_APP_URL + "images/wwws.png"} alt="" />
            <span className="semi-bold font__size--12 text__12-1024 color__softblue ml-2">
              {dateFormat(props.item.created_at, "yyyy-mm-dd")}
            </span>
          </div>
        </div>
        <Status
          typeStatus={props.typeStatus}
          slug={props.item.slug}
          toogleModalStatus={(e, a) => props.toogleModalStatus(e, a)}
          status={props.item.name_status}
        />
      </div>
    </Fragment>
  );
};

export default Game;
