import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { $ } from "react-jquery-plugin";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Redirect, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SvgLine2 from "./svgLine2";

export default class signUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: false,
      image: "./../images/dsadasdasd.png",
      redirect: false,
      login: {
        name: "",
        username: "",
        email: "",
        password: "",
      },
    };
  }
  componentDidMount() {
    AOS.init({
      once: true,
    });
  }

  showPassword = () => {
    this.setState({
      password: !this.state.password,
    });

    if (this.state.password) {
      this.setState({
        image: "./../images/dsadasdasd.png",
      });
    } else {
      this.setState({
        image: "./../images/sadsad.png",
      });
    }
  };

  onChangeInput = (e) => {
    let loginInput = { ...this.state.login };
    loginInput[e.target.name] = e.target.value;

    this.setState({
      login: loginInput,
    });
  };

  btnSignUp = () => {
    axios.post(process.env.REACT_APP_API + `register`, this.state.login).then(
      (res) => {
        if (res.data.success == "success") {
          toast.success("Kamu berhasil terdaftar, silahkan login", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });

          this.setState({
            redirect: true,
          });
        } else {
          $.each(res.data.errors, function (index, item) {
            toast.error(item[0], {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          });
        }
      },
      (err) => {
        console.log("error ", err);
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <Fragment>
        <Helmet>
          <title>WowSee Vault - Sign Up</title>
        </Helmet>
        <ToastContainer />
        <section className="height__100 min-height-100 p-0 w-100">
          <div className="row justify-content-center justify-content-lg-start h-100 w-100 m-0">
            <div className="col-lg-6 px-0 position-relative">
              <NavLink to="/" className="path__1 d-none d-xl-block">
                <img
                  src="./../images/Full Logo - Transparent - Black.png"
                  alt=""
                />
              </NavLink>
              <div className="h-100 my-lg-4 py-5 d-flex justify-content-center align-items-center">
                <div className="row w-100 justify-content-center">
                  <div className="col-md-11">
                    <div className="container px-0 position-relative">
                      <div className="d-block mb-5 d-xl-none">
                        <NavLink to="/">
                          <img
                            src="./../images/Full Logo - Transparent - Black.png"
                            alt=""
                          />
                        </NavLink>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3 className=" semi-bold font__size--28 text__28-1024 text__28-sm text__28-xxs color__darkblue mb-2">
                            Sign Up to WowSee
                          </h3>

                          <div className="mb-0">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                                >
                                  Full Name
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <input
                                    type="text"
                                    name="name"
                                    onChange={this.onChangeInput}
                                    value={this.state.login.name}
                                    className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="Input text here"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 form-group">
                                <label
                                  htmlFor=""
                                  className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                                >
                                  Username
                                </label>
                                <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                  <input
                                    type="text"
                                    onChange={this.onChangeInput}
                                    value={this.state.login.username}
                                    name="username"
                                    className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                                    placeholder="Input text here"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-0">
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                              >
                                E-mail Address
                              </label>
                              <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                <input
                                  type="email"
                                  name="email"
                                  onChange={this.onChangeInput}
                                  value={this.state.login.email}
                                  className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 normal"
                                  placeholder="Insert your email here"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="normal font__size--14 text__14-1024 color__darkblue opacity__6"
                              >
                                Password
                              </label>
                              <div className="wrapper__input-log d-flex justify-content-start align-items-center">
                                <input
                                  onChange={this.onChangeInput}
                                  type={
                                    this.state.password ? "text" : "password"
                                  }
                                  autoComplete="off"
                                  name="password"
                                  className="form-control input font__size--14 text__14-1024 color__darkblue opacity__6 "
                                  placeholder="Insert your password here"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="wrapper__remember d-flex justify-content-start align-items-start mt-3 mb-4">
                            <input
                              type="checkbox"
                              className="mt-2"
                              id="remember"
                              name="remember"
                              value="remember"
                            />
                            <label
                              htmlFor="remember"
                              className="ml-2 font__size--14"
                            >
                              Creating an account means you’re okay with out{" "}
                              <br className="d-none d-md-block" />
                              <a href="#!">Terms of Service</a>,
                              <a href="#!">Privacy Policy</a>, and our default{" "}
                              <br className="d-none d-md-block" />
                              <a href="#!">Notification Settings</a>.
                            </label>
                          </div>
                          <button
                            onClick={() => this.btnSignUp()}
                            className=" font__size--18 text__18-1024 semi-bold color__white btn btn__blue-2 shadow btn__login"
                          >
                            Create Account
                          </button>
                          <div className="font__size--14 mt-4">
                            Already have an account?{" "}
                            <NavLink to="/login">Sign In</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-lg-block position-relative px-0 bg__darkblue min-height-100">
              <SvgLine2 />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
