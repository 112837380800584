import React, { Fragment } from "react";

const Status = (props) => {
  return (
    <Fragment>
      <div
        onClick={
          JSON.parse(localStorage.getItem("userData")).role == "admin"
            ? props.typeStatus == "select"
              ? (e, a) => props.toogleModalStatus(props.status, props.slug)
              : null
            : null
        }
        className={
          "btn__status d-inline-block semi-bold  color__white " +
          props.status.toLowerCase() +
          " " +
          (props.type == "small"
            ? " font__size--11 text__11-1024 small "
            : " font__size--12 text__12-1024 ") +
          " " +
          (props.className ? props.className : "") +
          " " +
          (JSON.parse(localStorage.getItem("userData")).role == "admin"
            ? props.typeStatus == "select"
              ? "pointer"
              : null
            : null)
        }
      >
        {props.status}
      </div>
    </Fragment>
  );
};

export default Status;
