import React, { useEffect, Fragment } from "react";
import Anime from "animejs/lib/anime.es.js";

const SvgLine3 = (params) => {
  useEffect(() => {
    const path = Anime.path("path");
    Anime({
      targets: ".move__line",
      strokeDashoffset: [Anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      direction: "alternate",
      delay: function (el, i) {
        return i * 100;
      },
      loop: true,
    });
  }, []);
  return (
    <Fragment>
      <svg
        className="path__49"
        width="462"
        height="1060"
        viewBox="0 0 462 1060"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="266.242"
          y="102"
          width="6"
          height="6"
          transform="rotate(45 266.242 102)"
          fill="#16C79A"
          fill-opacity="0.5"
        />
        <rect
          x="184.297"
          y="0.586914"
          width="159.646"
          height="1056.48"
          fill="white"
          fill-opacity="0.08"
        />
        <g opacity="0.5">
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M237.707 385.028V417.309L275.858 455.46V527.065"
            stroke="#319CC9"
            stroke-width="1.5"
          />
          <rect
            x="276.242"
            y="523"
            width="6"
            height="6"
            transform="rotate(45 276.242 523)"
            fill="#319CC9"
          />
          <rect
            x="238.242"
            y="381"
            width="6"
            height="6"
            transform="rotate(45 238.242 381)"
            fill="#319CC9"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M208.361 1037.11L224.209 1021.26V944.961"
            stroke="#319CC9"
            stroke-width="1.5"
          />
          <rect
            x="224.242"
            y="941"
            width="6"
            height="6"
            transform="rotate(45 224.242 941)"
            fill="#319CC9"
          />
          <rect
            x="211"
            y="1034"
            width="6"
            height="6"
            transform="rotate(90 211 1034)"
            fill="#319CC9"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M81.5839 0.586914V116.8L22.8906 175.493V298.749L60.4543 336.312V623.322"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M402.635 1.76074V154.95L363.311 194.275V355.681L379.745 372.115V434.917L358.615 456.046V613.931"
            stroke="#16C79A"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M230.078 55.1719L216.578 68.6713V144.973"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M113.278 506.522V656.777L92.735 677.32V739.535L113.278 760.077V915.027L71.6055 956.7V1056.48"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M87.4531 292.292V254.142L105.061 236.534V146.733"
            stroke="#319CC9"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M266.5 106.5V194.54L281.141 217.165V303.444"
            stroke="#16C79A"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M386.202 848.117V882.746L366.246 902.702V994.85"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M447.244 258.837L399.115 306.966V488.328L416.136 505.349V642.691"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M147.908 1057.65V1037.11L187.233 997.785V866.312L162.582 841.661V722.514L181.95 703.145V482.458L206.601 457.807V302.27L171.385 267.054V1.76074"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M227.143 1057.65L251.794 1033V767.12L217.752 733.078V686.711L238.295 666.168V568.737"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M351.572 1060V1047.67L319.291 1015.39V793.533L299.922 774.164V739.535"
            stroke="#16C79A"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M139.689 284.662V328.682L162.58 351.572V441.373"
            stroke="#319CC9"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M83.3437 818.771V778.273L52.2363 747.165V696.102"
            stroke="#319CC9"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M143.211 923.245V971.96L108.582 1006.59"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M210.123 893.311V848.704L183.711 822.292V766.534"
            stroke="#16C79A"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M427.287 115.625V208.361L384.441 251.207"
            stroke="#319CC9"
            stroke-width="1.5"
          />
          <path
            className="move__line"
            style={{ strokeDashoffset: "0px" }}
            d="M343.943 152.015L376.812 119.147V0"
            stroke="white"
            stroke-width="1.5"
          />
          <rect
            x="366.242"
            y="993"
            width="6"
            height="6"
            transform="rotate(45 366.242 993)"
            fill="white"
          />
        </g>
        <g filter="url(#filter0_d)">
          <path
            d="M239.468 541.739L275.049 562.281V603.367L239.468 623.909L203.887 603.367V562.281L239.468 541.739Z"
            fill="#319CC9"
          />
        </g>
        <path
          d="M415.548 610.997L451.129 631.539V672.624L415.548 693.167L379.967 672.624V631.539L415.548 610.997Z"
          fill="#F8F1F1"
        />
        <path
          d="M280.554 271.75L324.268 296.988V347.464L280.554 372.702L236.841 347.464V296.988L280.554 271.75Z"
          fill="#319CC9"
        />
        <path
          d="M113.865 465.438L149.445 485.98V527.065L113.865 547.608L78.2837 527.065V485.98L113.865 465.438Z"
          fill="#16C79A"
        />
        <path
          d="M251.991 591.779C252.922 591.236 253.556 590.238 253.556 589.084V571.868C253.556 570.141 252.152 568.737 250.426 568.737H228.514C226.787 568.737 225.384 570.141 225.384 571.868V589.084C225.384 590.236 226.017 591.236 226.949 591.779V592.215H223.818V595.345H255.121V592.215H251.991V591.779ZM228.514 589.084V571.868H250.426L250.429 589.084H228.514Z"
          fill="white"
        />
        <g filter="url(#filter1_i)">
          <path
            d="M0 121.495L46.6698 168.195V241.968L0 195.268V121.495Z"
            fill="white"
          />
        </g>
        <path
          d="M0 206.152L46.6698 252.547V265.303L0 219.297V206.152Z"
          fill="#16C79A"
        />
        <path
          d="M0 227.859L46.6698 274.254V287.01L0 241.004V227.859Z"
          fill="#16C79A"
        />
        <path
          d="M387.752 877.464L347.464 917.515V928.527L387.752 888.811V877.464Z"
          fill="#F8F1F1"
        />
        <path
          d="M387.752 903.888L347.464 943.939V954.95L387.752 915.235V903.888Z"
          fill="#F8F1F1"
        />
        <path
          d="M387.752 930.312L347.464 970.363V981.375L387.752 941.659V930.312Z"
          fill="#F8F1F1"
        />
        <g filter="url(#filter2_d)">
          <path
            d="M386.202 783.555L421.783 804.097V845.183L386.202 865.725L350.622 845.183V804.097L386.202 783.555Z"
            fill="#16C79A"
          />
        </g>
        <path
          d="M385.555 816.813H379.475C378.832 816.812 378.205 817.01 377.679 817.378C377.152 817.747 376.752 818.269 376.532 818.872L370.647 835.059C370.439 835.63 370.581 836.271 371.01 836.701L371.469 837.16L381.52 827.108C381.519 827.068 381.508 827.027 381.508 826.986C381.508 826.522 381.645 826.068 381.903 825.682C382.161 825.296 382.528 824.995 382.957 824.817C383.386 824.639 383.858 824.593 384.314 824.684C384.769 824.774 385.187 824.998 385.516 825.326C385.844 825.654 386.068 826.073 386.158 826.528C386.249 826.984 386.202 827.456 386.025 827.885C385.847 828.314 385.546 828.68 385.16 828.938C384.774 829.196 384.32 829.334 383.856 829.334C383.815 829.334 383.774 829.323 383.733 829.321L373.682 839.373L374.141 839.831C374.351 840.042 374.617 840.189 374.908 840.253C375.199 840.317 375.503 840.297 375.782 840.194L391.969 834.308C392.573 834.088 393.095 833.688 393.463 833.161C393.832 832.635 394.029 832.008 394.029 831.365V825.285L397.158 822.158L388.684 813.684L385.555 816.813ZM398.629 820.04L390.803 812.214L394.121 808.895L401.947 816.72L398.629 820.04Z"
          fill="white"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="236"
          y="271"
          width="89"
          height="102"
        >
          <path
            d="M280.554 271.75L324.268 296.988V347.464L280.554 372.702L236.841 347.464V296.988L280.554 271.75Z"
            fill="#319CC9"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M269.256 289.646C269.256 289.646 275.807 280.782 287.414 286.697C300.14 293.182 293.263 307.16 293.263 307.16L265.916 301.979L269.256 289.646Z"
            fill="#A74B22"
          />
          <path
            d="M263.785 307.229C263.785 307.229 256.63 293.182 269.255 289.646L271.524 297.181L263.785 307.229Z"
            fill="#A74B22"
          />
          <path
            d="M269.852 313.711C269.931 316.517 267.721 318.855 264.915 318.934C262.11 319.014 259.771 316.804 259.691 313.998C259.613 311.193 261.823 308.854 264.629 308.775C267.434 308.696 269.773 310.906 269.852 313.711Z"
            fill="#E8B499"
          />
          <path
            d="M262.012 313.396L262.013 313.396C262.043 313.382 262.252 313.297 262.572 313.298C262.899 313.299 263.344 313.382 263.865 313.733C264.385 314.084 264.984 314.709 265.586 315.804C265.661 315.942 265.833 315.992 265.971 315.916C266.109 315.841 266.159 315.668 266.083 315.531C265.45 314.377 264.795 313.675 264.183 313.261C263.57 312.848 263 312.729 262.572 312.73C262.091 312.73 261.79 312.873 261.763 312.886C261.622 312.954 261.564 313.124 261.632 313.265C261.701 313.406 261.871 313.465 262.012 313.396Z"
            fill="#C47268"
          />
          <path
            d="M288.406 313.855C288.406 316.662 290.681 318.937 293.488 318.937C296.294 318.937 298.569 316.662 298.569 313.855C298.569 311.049 296.294 308.773 293.488 308.773C290.681 308.773 288.406 311.049 288.406 313.855Z"
            fill="#E8B499"
          />
          <path
            d="M296.469 312.8C296.442 312.789 296.165 312.667 295.723 312.667C295.299 312.666 294.723 312.784 294.109 313.211C293.493 313.637 292.841 314.364 292.225 315.567C292.153 315.706 292.208 315.878 292.348 315.949C292.488 316.02 292.659 315.965 292.73 315.826C293.315 314.684 293.912 314.037 294.432 313.678C294.953 313.318 295.4 313.236 295.723 313.234C295.89 313.234 296.025 313.258 296.114 313.28L296.212 313.309L296.234 313.317L296.234 313.318C296.377 313.382 296.545 313.319 296.61 313.176C296.675 313.033 296.611 312.864 296.469 312.8Z"
            fill="#C47268"
          />
          <path
            d="M285.648 325.565C285.648 325.565 282.783 327.587 280.172 328.086C277.562 328.585 275.023 327.07 274.991 327.051L271.942 325.298L270.693 342.024L271.031 342.303C275.458 345.955 281.84 345.998 286.317 342.407L286.971 341.881L285.648 325.565Z"
            fill="#E8B499"
          />
          <path
            d="M270.693 323.538C270.693 323.538 273.541 332.859 280.094 332.755C285.479 332.67 286.349 323.538 286.349 323.538H270.693Z"
            fill="#E0A587"
          />
          <path
            d="M292.851 305.009C292.881 310.753 292.086 315.714 291.284 319.236C290.5 322.685 287.466 325.103 284.287 327.056L283.708 327.413C280.343 329.48 277.562 329.55 274.127 327.592C270.661 325.616 267.44 323.002 266.677 319.31C265.288 312.594 265.103 305.149 265.103 305.149C265.103 305.149 263.152 289.611 278.118 289.817C293.085 290.023 292.851 305.009 292.851 305.009Z"
            fill="#E8B499"
          />
          <path
            d="M273.731 309.566C273.731 310.235 273.189 310.777 272.52 310.777C271.851 310.777 271.309 310.235 271.309 309.566C271.309 308.897 271.851 308.355 272.52 308.355C273.189 308.355 273.731 308.897 273.731 309.566Z"
            fill="#1E0B02"
          />
          <path
            d="M285.615 309.566C285.615 310.235 285.072 310.777 284.403 310.777C283.734 310.777 283.191 310.235 283.191 309.566C283.191 308.897 283.734 308.355 284.403 308.355C285.072 308.355 285.615 308.897 285.615 309.566Z"
            fill="#1E0B02"
          />
          <path
            d="M277.08 312.734V314.675C277.08 314.75 277.11 314.823 277.163 314.876C277.216 314.929 277.289 314.959 277.364 314.959H278.935C279.092 314.959 279.22 314.832 279.22 314.675C279.22 314.518 279.092 314.391 278.935 314.391H277.648V312.734C277.648 312.577 277.521 312.45 277.364 312.45C277.207 312.45 277.08 312.577 277.08 312.734Z"
            fill="#C47268"
          />
          <path
            d="M268.01 304.447L275.923 303.734C275.923 303.734 275.215 301.683 272.015 302.016C268.741 302.356 268.01 304.447 268.01 304.447Z"
            fill="black"
          />
          <path
            d="M288.408 304.447L280.494 303.734C280.494 303.734 281.203 301.683 284.401 302.016C287.675 302.356 288.408 304.447 288.408 304.447Z"
            fill="black"
          />
          <path
            d="M274.656 317.441H282.463C282.463 317.441 282.095 319.833 278.444 319.833C274.792 319.833 274.656 317.441 274.656 317.441Z"
            fill="white"
          />
          <path
            d="M265.612 312.501C265.612 312.501 266.199 293.469 265.955 292.927C265.955 292.927 259.207 299.163 265.612 312.501Z"
            fill="#A74B22"
          />
          <path
            d="M292.287 312.367L291.273 291.701C291.273 291.701 297.764 298.148 292.287 312.367Z"
            fill="#A74B22"
          />
          <path
            d="M271.876 291.241C271.876 291.241 267.233 292.498 267.39 297.057C267.549 301.616 264.465 306.097 264.465 306.097L263.057 301.541C263.057 301.541 261.97 293.051 271.876 291.241Z"
            fill="#A74B22"
          />
          <path
            d="M270.704 291.618C270.704 291.618 274.18 289.646 277.631 293.986C280.579 297.691 288.349 301.508 292.952 297.876C292.952 297.876 290.02 287.387 278.586 287.6C267.15 287.813 270.704 291.618 270.704 291.618Z"
            fill="#A74B22"
          />
          <path
            d="M254.507 348.374C231.457 359.916 212.469 381.4 212.469 381.4L224.84 396.304L259.69 374.165L254.507 348.374Z"
            fill="#EEC86A"
          />
          <path
            d="M301.213 347.685C301.213 347.685 309.33 349.728 313.652 361.917C322.313 386.336 331.294 451.764 331.294 451.764L315.79 454.686L294.053 370.866L301.213 347.685Z"
            fill="#EEC86A"
          />
          <path
            d="M296.149 345.432L303.101 348.525C305.917 349.777 307.735 352.566 307.745 355.649L307.992 431.081H249.709V355.589C249.709 352.437 251.6 349.593 254.507 348.374L261.603 345.398L296.149 345.432Z"
            fill="#EEC86A"
          />
          <path
            d="M279.29 338.125L296.149 345.433C296.149 345.433 278.828 361.087 261.604 345.398L279.29 338.125Z"
            fill="#E8B499"
          />
          <path
            d="M260.992 345.913C261.033 345.96 262.589 347.794 265.591 349.63C268.59 351.465 273.048 353.298 278.833 353.297C284.617 353.298 289.094 351.474 292.115 349.65C295.137 347.825 296.715 346 296.755 345.955L295.544 344.911L295.534 344.923C295.39 345.084 293.842 346.789 291.019 348.442C288.194 350.095 284.107 351.698 278.833 351.699C273.39 351.698 269.233 349.982 266.425 348.266C265.021 347.408 263.958 346.551 263.251 345.913C262.898 345.595 262.634 345.331 262.46 345.15C262.373 345.059 262.309 344.99 262.268 344.943L262.224 344.893L262.215 344.882L262.214 344.882L260.992 345.913Z"
            fill="white"
          />
        </g>
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="78"
          y="465"
          width="72"
          height="83"
        >
          <path
            d="M113.865 465.438L149.445 485.98V527.065L113.865 547.608L78.2837 527.065V485.98L113.865 465.438Z"
            fill="#16C79A"
          />
        </mask>
        <g mask="url(#mask1)">
          <path
            d="M118.064 482.039C118.064 482.039 124.099 479.35 126.238 484.435C128.298 489.33 120.118 493.996 120.118 493.996L118.064 482.039Z"
            fill="#1E0B02"
          />
          <path
            d="M106.777 502.336C106.841 504.597 105.06 506.481 102.799 506.545C100.538 506.609 98.6534 504.828 98.5896 502.567C98.5257 500.306 100.307 498.422 102.568 498.358C104.829 498.294 106.713 500.075 106.777 502.336Z"
            fill="#C67D69"
          />
          <path
            d="M100.467 502.099C100.489 502.089 100.655 502.021 100.909 502.021C101.17 502.023 101.523 502.089 101.94 502.368C102.356 502.649 102.838 503.15 103.321 504.031C103.387 504.151 103.537 504.196 103.658 504.129C103.779 504.063 103.823 503.913 103.756 503.792C103.245 502.86 102.716 502.292 102.218 501.956C101.721 501.621 101.258 501.524 100.909 501.524C100.518 501.525 100.272 501.641 100.249 501.652C100.125 501.712 100.074 501.861 100.135 501.984C100.195 502.108 100.344 502.159 100.467 502.099Z"
            fill="#A8523F"
          />
          <path
            d="M121.729 502.452C121.729 504.713 123.562 506.546 125.824 506.546C128.087 506.546 129.919 504.713 129.919 502.452C129.919 500.19 128.087 498.356 125.824 498.356C123.562 498.356 121.729 500.19 121.729 502.452Z"
            fill="#C67D69"
          />
          <path
            d="M119.508 511.888C119.508 511.888 117.2 513.517 115.096 513.919C112.991 514.321 110.946 513.1 110.92 513.086L108.463 511.672L107.457 525.152L107.729 525.376C111.296 528.32 116.439 528.354 120.046 525.46L120.574 525.037L119.508 511.888Z"
            fill="#C67D69"
          />
          <path
            d="M107.457 510.255C107.457 510.255 109.752 517.766 115.032 517.683C119.373 517.614 120.073 510.255 120.073 510.255H107.457Z"
            fill="#BE6D57"
          />
          <path
            d="M125.312 495.322C125.336 499.951 124.694 503.949 124.049 506.787C123.416 509.567 121.608 511.516 119.046 513.09L118.579 513.377C115.868 515.043 112.355 515.099 109.587 513.521C106.793 511.929 104.833 509.822 104.218 506.847C103.1 501.435 102.95 495.435 102.95 495.435C102.95 495.435 101.378 482.914 113.439 483.079C125.5 483.245 125.312 495.322 125.312 495.322Z"
            fill="#C67D69"
          />
          <path
            d="M109.903 498.994C109.903 499.533 109.466 499.971 108.927 499.971C108.388 499.971 107.951 499.533 107.951 498.994C107.951 498.455 108.388 498.018 108.927 498.018C109.466 498.018 109.903 498.455 109.903 498.994Z"
            fill="#1E0B02"
          />
          <path
            d="M119.482 498.994C119.482 499.533 119.044 499.971 118.506 499.971C117.967 499.971 117.529 499.533 117.529 498.994C117.529 498.455 117.967 498.018 118.506 498.018C119.044 498.018 119.482 498.455 119.482 498.994Z"
            fill="#1E0B02"
          />
          <path
            d="M112.584 500.42V503.112C112.584 503.178 112.611 503.241 112.656 503.288C112.703 503.334 112.767 503.36 112.833 503.36H114.571C114.709 503.36 114.82 503.249 114.82 503.112C114.82 502.975 114.709 502.863 114.571 502.863H113.081V500.42C113.081 500.283 112.97 500.171 112.833 500.171C112.695 500.171 112.584 500.283 112.584 500.42Z"
            fill="#A8523F"
          />
          <path
            d="M105.293 494.87L111.67 494.295C111.67 494.295 111.099 492.643 108.521 492.911C105.883 493.185 105.293 494.87 105.293 494.87Z"
            fill="black"
          />
          <path
            d="M121.73 494.87L115.354 494.295C115.354 494.295 115.925 492.643 118.503 492.911C121.141 493.185 121.73 494.87 121.73 494.87Z"
            fill="black"
          />
          <path
            d="M110.648 505.342H116.939C116.939 505.342 116.643 507.269 113.7 507.269C110.757 507.269 110.648 505.342 110.648 505.342Z"
            fill="white"
          />
          <path
            d="M103.361 501.36C103.361 501.36 105.146 487.817 104.273 485.586C104.273 485.586 98.199 490.611 103.361 501.36Z"
            fill="#1E0B02"
          />
          <path
            d="M124.858 501.252C124.858 501.252 122.849 488.319 123.406 484.598C123.406 484.598 129.271 489.794 124.858 501.252Z"
            fill="#1E0B02"
          />
          <path
            d="M122.985 483.676L117.424 482.835L116.934 481.873L113.999 482.317L111.064 481.873L110.574 482.835L105.013 483.676L104.271 486.651L104.455 490.081C104.455 490.081 105.335 486.191 108.399 486.965C109.121 487.148 109.987 487.45 110.998 487.654C111.943 487.86 112.843 487.953 113.692 487.968C113.793 487.971 113.897 487.968 113.999 487.969C114.101 487.968 114.205 487.971 114.305 487.968C115.155 487.953 116.055 487.86 116.999 487.654C118.011 487.45 118.895 487.21 119.598 486.965C122.439 485.977 123.486 489.904 123.486 489.904L123.726 486.651L122.985 483.676Z"
            fill="#1E0B02"
          />
          <path
            d="M122.873 487.061C122.873 487.061 119.256 490.288 114.623 487.808C114.623 487.808 102.834 495.07 99.5074 487.165C96.7336 480.575 108.248 471.63 120.128 481.709L122.873 487.061Z"
            fill="#1E0B02"
          />
          <path
            d="M134.401 530.49L120.574 525.037L107.457 525.152L90.1615 533.244C88.8061 534.055 89.0614 535.698 89.5533 537.199L93.4645 583.424L136.84 582.827L139.667 535.861C140.036 534.316 135.83 531.183 134.401 530.49Z"
            fill="#E180A0"
          />
          <path
            d="M107.456 525.152L103.592 526.928C103.592 526.928 103.55 533.406 114.05 533.07C124.817 532.726 124.793 526.624 124.793 526.624L120.573 525.037L107.456 525.152Z"
            fill="#C67D69"
          />
          <path
            d="M90.1612 533.244C90.1612 533.244 79.2565 536.886 77.0098 556.248L98.5884 562.673L104.592 543.952L90.1612 533.244Z"
            fill="#E180A0"
          />
          <path
            d="M128.189 502.099C128.167 502.089 128.002 502.021 127.747 502.021C127.487 502.023 127.133 502.089 126.716 502.368C126.3 502.649 125.819 503.15 125.335 504.031C125.27 504.151 125.119 504.196 124.998 504.129C124.877 504.063 124.834 503.913 124.9 503.792C125.411 502.86 125.94 502.292 126.438 501.956C126.935 501.621 127.398 501.524 127.747 501.524C128.139 501.525 128.384 501.641 128.407 501.652C128.531 501.712 128.582 501.861 128.521 501.984C128.462 502.108 128.313 502.159 128.189 502.099Z"
            fill="#A8523F"
          />
        </g>
        <mask
          id="mask2"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="379"
          y="610"
          width="73"
          height="84"
        >
          <path
            d="M415.548 610.997L451.129 631.539V672.624L415.548 693.167L379.967 672.624V631.539L415.548 610.997Z"
            fill="#F8F1F1"
          />
        </mask>
        <g mask="url(#mask2)">
          <path
            d="M402.756 638.981C402.756 638.981 401.795 648.606 390.874 656.351C379.229 664.608 387.906 687.466 409.288 686.4C432.516 685.242 442.154 655.403 427.001 632.738L402.756 638.981Z"
            fill="#A74B22"
          />
          <path
            d="M419.047 667.373L404.969 671.33C400.906 672.471 397.65 676.782 398.878 680.82L411.554 709.506L433.006 708.533L440.548 677.454C441.194 673.231 436.815 669.397 432.57 668.914L419.047 667.373Z"
            fill="#7B97FB"
          />
          <path
            d="M404.969 671.33L419.047 667.373L432.57 668.914L432.29 669.149C424.576 675.616 413.612 676.491 404.969 671.33Z"
            fill="#E0A78F"
          />
          <path
            d="M445.717 677.69L459.054 700.499C459.705 702.131 458.847 703.976 457.179 704.528L450.75 706.657C449.77 706.982 448.691 706.716 447.983 705.964C445.617 703.448 435.223 695.521 435.552 685.833L431.857 679.218L432.569 668.914C439.46 669.745 444.436 674.549 445.717 677.69Z"
            fill="#7B97FB"
          />
          <path
            d="M437.274 692.746L440.255 682.943C440.3 682.794 440.216 682.635 440.067 682.59C439.917 682.544 439.759 682.629 439.714 682.779L436.733 692.581C436.687 692.73 436.772 692.888 436.921 692.934C437.071 692.98 437.229 692.895 437.274 692.746Z"
            fill="black"
          />
          <path
            d="M394.084 680.657L383.447 704.843C382.987 706.539 384.05 708.273 385.771 708.631L390.786 709.761C391.797 709.971 392.423 712.374 393.04 711.546C395.102 708.775 406.551 697.168 405.115 687.582L408.027 680.587L404.969 671.329C398.219 672.944 394.997 677.39 394.084 680.657Z"
            fill="#7B97FB"
          />
          <path
            d="M404.716 694.42L400.632 685.024C400.57 684.88 400.403 684.815 400.26 684.877C400.117 684.939 400.051 685.106 400.113 685.249L404.197 694.646C404.259 694.789 404.426 694.855 404.569 694.792C404.713 694.73 404.778 694.564 404.716 694.42Z"
            fill="black"
          />
          <path
            d="M369.388 678.888L364.393 680.935L384.522 709.729C386.116 712.932 390.276 713.82 393.04 711.546C395.336 709.655 395.74 706.293 393.956 703.912L369.388 678.888Z"
            fill="#E0A78F"
          />
          <path
            d="M409.866 649.708C410.106 651.831 408.58 653.747 406.457 653.987C404.334 654.226 402.419 652.7 402.179 650.577C401.94 648.454 403.466 646.539 405.589 646.299C407.711 646.059 409.626 647.586 409.866 649.708Z"
            fill="#E0A78F"
          />
          <path
            d="M403.897 649.971L403.859 649.906L403.896 649.972L403.897 649.971L403.859 649.906L403.896 649.972C403.902 649.968 404.106 649.855 404.436 649.856C404.942 649.859 405.785 650.105 406.762 651.568C406.828 651.668 406.962 651.695 407.061 651.629C407.16 651.563 407.188 651.429 407.122 651.329C406.104 649.787 405.103 649.419 404.436 649.423C403.978 649.423 403.698 649.587 403.676 649.599C403.574 649.661 403.54 649.793 403.601 649.896C403.662 649.998 403.795 650.032 403.897 649.971Z"
            fill="#B9635E"
          />
          <path
            d="M423.946 648.629C424.125 650.758 425.997 652.338 428.126 652.158C430.254 651.978 431.834 650.107 431.654 647.978C431.475 645.849 429.604 644.269 427.475 644.449C425.346 644.629 423.766 646.5 423.946 648.629Z"
            fill="#E0A78F"
          />
          <path
            d="M429.995 647.312C429.978 647.306 429.82 647.252 429.57 647.252C429.262 647.251 428.811 647.338 428.339 647.691C427.866 648.043 427.375 648.653 426.954 649.682C426.909 649.793 426.961 649.919 427.071 649.964C427.182 650.01 427.308 649.957 427.354 649.846C427.755 648.869 428.203 648.331 428.597 648.037C428.992 647.744 429.338 647.686 429.57 647.684C429.664 647.684 429.738 647.695 429.787 647.705L429.84 647.717L429.851 647.72L429.857 647.701L429.851 647.72L429.851 647.72L429.857 647.701L429.851 647.72C429.963 647.76 430.087 647.701 430.127 647.588C430.167 647.476 430.108 647.352 429.995 647.312Z"
            fill="#B9635E"
          />
          <path
            d="M429.268 638.738C429.846 645.572 424.377 651.614 417.055 652.232C409.733 652.85 403.33 647.811 402.753 640.977C402.175 634.142 407.644 628.101 414.965 627.483C422.288 626.864 428.691 631.903 429.268 638.738Z"
            fill="#A74B22"
          />
          <path
            d="M421.278 656.602C421.278 656.602 419.74 658.274 418.251 658.779C416.762 659.283 415.186 658.256 415.165 658.244L413.275 657.062L413.634 669.807L413.849 670.002C416.666 672.558 420.386 672.282 422.76 669.343L423.108 668.913L421.278 656.602Z"
            fill="#E0A78F"
          />
          <path
            d="M411.934 655.487C411.934 655.487 414.698 662.719 418.508 662.324C421.641 661.999 421.928 655.014 421.928 655.014L411.934 655.487Z"
            fill="#D99A7F"
          />
          <path
            d="M426.707 641.137C427.079 645.305 426.78 648.957 426.387 651.566C426.003 654.12 424.448 656.02 422.156 657.641L421.739 657.938C419.314 659.654 416.012 659.984 413.287 658.784C410.537 657.573 408.533 655.831 407.729 653.199C406.265 648.413 405.669 643.021 405.669 643.021C405.669 643.021 403.838 631.816 415.201 631.004C426.565 630.193 426.707 641.137 426.707 641.137Z"
            fill="#E0A78F"
          />
          <path
            d="M415.542 647.886L415.68 649.516L415.756 649.663L415.913 649.713L417.027 649.619C417.146 649.609 417.234 649.504 417.224 649.385C417.214 649.267 417.109 649.178 416.991 649.188L416.092 649.264L415.973 647.85C415.963 647.731 415.858 647.642 415.739 647.653C415.62 647.663 415.532 647.767 415.542 647.886Z"
            fill="#B9635E"
          />
          <path
            d="M407.553 642.661L413.509 641.613C413.509 641.613 412.84 640.104 410.435 640.561C407.974 641.028 407.553 642.661 407.553 642.661Z"
            fill="black"
          />
          <path
            d="M424.052 641.267L418.004 641.233C418.004 641.233 418.411 639.632 420.858 639.68C423.363 639.728 424.052 641.267 424.052 641.267Z"
            fill="black"
          />
          <path
            d="M412.524 645.918C412.567 646.425 412.19 646.871 411.683 646.914C411.175 646.957 410.729 646.58 410.687 646.073C410.644 645.566 411.02 645.12 411.528 645.077C412.035 645.034 412.481 645.41 412.524 645.918Z"
            fill="#1E0B02"
          />
          <path
            d="M421.536 645.157C421.579 645.664 421.202 646.11 420.695 646.153C420.187 646.196 419.741 645.819 419.699 645.312C419.656 644.804 420.032 644.358 420.54 644.316C421.047 644.273 421.493 644.649 421.536 645.157Z"
            fill="#1E0B02"
          />
          <path
            d="M413.194 652.245C413.214 652.254 414.125 652.652 415.672 652.652C416.815 652.652 418.305 652.434 420.031 651.683C420.14 651.636 420.191 651.508 420.143 651.399C420.096 651.289 419.968 651.239 419.858 651.286C418.187 652.013 416.76 652.22 415.672 652.22C414.936 652.22 414.355 652.125 413.96 652.031C413.763 651.984 413.612 651.938 413.512 651.904L413.401 651.863L413.368 651.849C413.259 651.802 413.131 651.851 413.083 651.961C413.035 652.07 413.084 652.197 413.194 652.245Z"
            fill="black"
          />
          <path
            d="M426.491 638.574C426.491 638.574 409.837 640.611 409.715 630.754C409.715 630.754 423.925 625.399 426.491 638.574Z"
            fill="#A74B22"
          />
          <path
            d="M405.198 640.38C405.198 640.38 410.86 637.429 410.351 631.413C410.351 631.413 404.394 632.615 405.198 640.38Z"
            fill="#A74B22"
          />
        </g>
        <rect
          x="210.242"
          y="889"
          width="6"
          height="6"
          transform="rotate(45 210.242 889)"
          fill="#208D88"
        />
        <path
          d="M60.2431 619.001L64.4857 623.243L60.2431 627.486L56.0005 623.243L60.2431 619.001Z"
          fill="#8CA2B5"
        />
        <path
          d="M449.999 255.5L449.999 261.5L443.999 261.5L443.999 255.5L449.999 255.5Z"
          fill="#8CA2B5"
        />
        <rect
          x="233"
          y="52"
          width="6"
          height="6"
          transform="rotate(90 233 52)"
          fill="#95A9BB"
        />
        <rect
          x="220.484"
          y="145.243"
          width="6"
          height="6"
          transform="rotate(135 220.484 145.243)"
          fill="#95A9BB"
        />
        <rect
          x="347"
          y="149"
          width="6"
          height="6"
          transform="rotate(90 347 149)"
          fill="#95A9BB"
        />
        <rect
          x="105.242"
          y="143"
          width="6"
          height="6"
          transform="rotate(45 105.242 143)"
          fill="#25709A"
        />
        <rect
          x="87.2422"
          y="288"
          width="6"
          height="6"
          transform="rotate(45 87.2422 288)"
          fill="#25709A"
        />
        <rect
          x="140.242"
          y="280"
          width="6"
          height="6"
          transform="rotate(45 140.242 280)"
          fill="#25709A"
        />
        <rect
          x="427.242"
          y="111"
          width="6"
          height="6"
          transform="rotate(45 427.242 111)"
          fill="#25709A"
        />
        <rect
          x="143.242"
          y="919"
          width="6"
          height="6"
          transform="rotate(45 143.242 919)"
          fill="#8CA2B5"
        />
        <rect
          x="112"
          y="1003"
          width="6"
          height="6"
          transform="rotate(90 112 1003)"
          fill="#8CA2B5"
        />
        <rect
          x="387"
          y="248"
          width="6"
          height="6"
          transform="rotate(90 387 248)"
          fill="#25709A"
        />
        <rect
          x="162.242"
          y="437"
          width="6"
          height="6"
          transform="rotate(45 162.242 437)"
          fill="#25709A"
        />
        <rect
          x="52.2422"
          y="692"
          width="6"
          height="6"
          transform="rotate(45 52.2422 692)"
          fill="#25709A"
        />
        <rect
          x="83.2422"
          y="815"
          width="6"
          height="6"
          transform="rotate(45 83.2422 815)"
          fill="#25709A"
        />
        <rect
          x="358.242"
          y="610"
          width="6"
          height="6"
          transform="rotate(45 358.242 610)"
          fill="#178682"
        />
        <rect
          x="300.242"
          y="735"
          width="6"
          height="6"
          transform="rotate(45 300.242 735)"
          fill="#208D88"
        />
        <rect
          x="184.242"
          y="762"
          width="6"
          height="6"
          transform="rotate(45 184.242 762)"
          fill="#208D88"
        />
        <defs>
          <filter
            id="filter0_d"
            x="163.887"
            y="524.739"
            width="151.162"
            height="162.171"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="23" />
            <feGaussianBlur stdDeviation="20" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.192157 0 0 0 0 0.611765 0 0 0 0 0.788235 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_i"
            x="-16"
            y="111.495"
            width="62.6698"
            height="130.473"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-16" dy="-10" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter2_d"
            x="310.621"
            y="766.555"
            width="151.162"
            height="162.171"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="23" />
            <feGaussianBlur stdDeviation="20" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0862745 0 0 0 0 0.780392 0 0 0 0 0.603922 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Fragment>
  );
};

export default SvgLine3;
