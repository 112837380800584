import React, { Fragment, useRef } from "react";

const Content = (props) => {
  const select = useRef(null);
  const clickToChange = () => {
    select.current.click();
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      props.onChangeInputContent(e.target.files[0], e.target.name, props.id);
    } else {
      select.current.value = "";
    }
  };
  const onChangeContent = async (e) => {
    await props.onChangeInputContent(e.target.value, e.target.name, props.id);
  };
  return (
    <Fragment>
      <div className="wrapper__content-detail position-relative mb-4">
        <img
          onClick={(e) => props.removeInputContent(props.id)}
          src={process.env.REACT_APP_URL + "images/vfaaw.png"}
          className="close_icon pointer"
          alt=""
        />
        <div className="row">
          <div className="col-md-8 mb-4">
            <label htmlFor="" className="normal font__size--14 opacity__7 mb-2">
              Item
            </label>
            <input
              className="wrapper__input-review select"
              name="item"
              onChange={onChangeContent}
              value={props.text.item}
              type="text"
            />
            <p className="color__red mb-4 lh-1 font__size--14">
              {props.errorItem ? "The field is required." : null}
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <label htmlFor="" className="normal font__size--14 opacity__7 mb-2">
              Result
            </label>
            <select
              name="result"
              onChange={onChangeContent}
              className="wrapper__input-review select"
              id=""
            >
              <option disabled selected hidden value>
                eg. Result
              </option>
              <option value="pass">Pass</option>
              <option value="fail">Fail</option>
            </select>
            <p className="color__red mb-4 lh-1 font__size--14">
              {props.errorResult ? "The field is required." : null}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="" className="normal font__size--14 opacity__7 mb-2">
              Media (Optional)
            </label>
            <div className="position-relative wrapper__on-input-close">
              <input
                type="file"
                name="content_media"
                className="media"
                ref={select}
                onChange={imageChange}
                id=""
              />
              <div
                className="wrapper__input-review d-flex align-items-center bg__white overflow-hidden"
                onClick={clickToChange}
              >
                {props.text.content_media != ""
                  ? props.text.content_media.name
                  : "Upload File"}
              </div>
              <img
                onClick={clickToChange}
                src={process.env.REACT_APP_URL + "images/csdfg.png"}
                className="close pointer"
                alt=""
              />
            </div>
            <p className="color__red mb-4 lh-1 font__size--14">
              {props.errorMedia ? "The field is required and file must be a file of type: csv, xls, xlsx, jpeg, png, jpg." : null}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="" className="normal font__size--14 opacity__7 mb-2">
              Comment (Optional)
            </label>
            <input
              className="wrapper__input-review select"
              name="comment"
              onChange={onChangeContent}
              value={props.text.comment}
              type="text"
            />
            <p className="color__red mb-4 lh-1 font__size--14">
              {props.errorComment ? "The field is required." : null}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Content;
