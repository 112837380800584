import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { $ } from "react-jquery-plugin";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Redirect, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

import SvgLine3 from "./svgLine3";

export default class signUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: false,
      image: "./../images/dsadasdasd.png",
      redirect: false,
      login: {
        email: "",
        password: "",
      },
    };
  }
  componentDidMount() {
    AOS.init({
      once: true,
    });
  }

  showPassword = () => {
    this.setState({
      password: !this.state.password,
    });

    if (this.state.password) {
      this.setState({
        image: "./../images/dsadasdasd.png",
      });
    } else {
      this.setState({
        image: "./../images/sadsad.png",
      });
    }
  };

  Choicetype(e) {
    const cookies = new Cookies();
    axios
      .post(
        process.env.REACT_APP_API + `register/choice-type`,
        {
          type: e,
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      )
      .then(
        (res) => {
          localStorage.setItem("userData", JSON.stringify(res.data.data));

          this.setState({
            redirect: true,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Fragment>
        <Helmet>
          <title>WowSee Vault - Type User</title>
        </Helmet>
        <ToastContainer />
        <section className="height__100 min-height-100 p-0 w-100">
          <div className="row justify-content-center justify-content-lg-start h-100 w-100 m-0">
            <div className="col-lg-6 px-0 position-relative">
              <NavLink to="/" className="path__1 d-none d-xl-block">
                <img
                  src="./../images/Full Logo - Transparent - Black.png"
                  alt=""
                />
              </NavLink>
              <div className="h-100 py-5 d-flex justify-content-center align-items-center">
                <div className="row w-100 justify-content-center">
                  <div className="col-md-11">
                    <div className="container px-0 position-relative">
                      <div className="d-block mb-5 d-xl-none">
                        <NavLink to="/">
                          <img
                            src="./../images/Full Logo - Transparent - Black.png"
                            alt=""
                          />
                        </NavLink>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3 className=" semi-bold font__size--28 text__28-1024 text__28-sm text__28-xxs color__darkblue mb-2">
                            What type of user are you ?
                          </h3>
                          <h5 className="medium lh-2 font__size--16 text__16-1024 text__16-sm color__darkblue opacity__4 mb-4">
                            Let us know what tour role is
                          </h5>

                          <div
                            onClick={() => this.Choicetype("creator")}
                            className="wrapper__chooise-actor pointer mb-4"
                          >
                            <div className="d-flex justify-content-xl-between align-content-center">
                              <span className="font__size--16">
                                Creator (Admin)
                              </span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 12H19"
                                  stroke="#475569"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 5L19 12L12 19"
                                  stroke="#475569"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <div
                            onClick={() => this.Choicetype("viwer")}
                            className="wrapper__chooise-actor pointer"
                          >
                            <div className="d-flex justify-content-xl-between align-content-center">
                              <span className="font__size--16">
                                Viewer (User)
                              </span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 12H19"
                                  stroke="#475569"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 5L19 12L12 19"
                                  stroke="#475569"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-lg-block position-relative px-0 bg__darkblue min-height-100">
              <SvgLine3 />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
