import React, { useState, Fragment } from "react";
import dateFormat from "dateformat";
import axios from "axios";
import Cookies from "universal-cookie";

const DetailGame = (props) => {
  const [showing, setShowing] = useState(props.status ? props.status : false);
  const toSIze = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const downloadApk = (id) => {
    const cookies = new Cookies();
    axios
      .get(process.env.REACT_APP_API + `game/download/${id}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          // console.log(res);
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };
  return (
    <Fragment>
      <div className="wrapper__content-desc bg__white mb-4">
        <div className="padding-desc">
          <div
            className={
              "wrapper__content-download bg__white " +
              (showing ? "showing" : "")
            }
          >
            <div className="d-flex justify-content-between align-items-center pointer">
              <div className="wrap__item d-flex justify-content-start align-items-center">
                <div className="item">
                  <a
                    className="version color__black d-flex justify-content-start align-items-center"
                    href={props.item.game_url}
                  >
                    <img
                      src={process.env.REACT_APP_URL + "images/dsds.png"}
                      alt=""
                    />
                    <span className="semi-bold font__size--12 text__12-1024 ml-2">
                      V{props.item.version}
                    </span>
                  </a>
                </div>
                <div
                  className="item line d-flex justify-content-start align-items-center pointer"
                  onClick={() => setShowing(!showing)}
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/ssfwfw.png"}
                    alt=""
                  />
                  <span className="font__size--12 text__12-1024 semi-bold opacity__5 ml-2">
                    {dateFormat(props.item.created_at, "yyyy-mm-dd")}
                  </span>
                </div>
                <div
                  className="item d-sm-flex d-none justify-content-start align-items-center pointer"
                  onClick={() => setShowing(!showing)}
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/sdefde.png"}
                    alt=""
                  />
                  <span className="font__size--12 text__12-1024 semi-bold opacity__5 ml-2">
                    {toSIze(props.item.size)}
                  </span>
                </div>
              </div>
              <img
                src={process.env.REACT_APP_URL + "images/asa.png"}
                className="d-inline-block arrow"
                alt=""
              />
            </div>
            <div className="desc__item">
              <hr className="opacity__5 m-0 py-2" />
              <div className="row">
                <div className="col-md-5">
                  <h5 className="semi-bold font__size--14 text__14-1024">
                    Active:
                  </h5>
                  {props.review_data.map((rev) => {
                    if (rev.apk_id == props.item.id) {
                      return props.result_data.map((itemWrap, i) => {
                        return itemWrap.map((item, k) => {
                          if (item.apk_review_id == rev.id) {
                            if (k == 0) {
                              return (
                                <p className="m-0 opacity__7">
                                  {item.status == "pass" ? "Yes" : "No"}
                                </p>
                              );
                            }
                          }
                        });
                      });
                    }
                  })}
                </div>
                <div className="col-md">
                  <h5 className="semi-bold font__size--14 text__14-1024">
                    Changelog :
                  </h5>
                  <ul className="font__size--14 text__14-1024 opacity__7 m-0 pl-3">
                    {props.review_data.map((rev) => {
                      if (rev.apk_id == props.item.id) {
                        return props.changelog_data.map((itemWrap) => {
                          return itemWrap.map((item, i) => {
                            if (item.apk_review_id == rev.id) {
                              return <li>{item.text}</li>;
                            }
                          });
                        });
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailGame;
