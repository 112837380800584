import React, { Fragment, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Loadable, { lazy } from "react-loadable";
import axios from "axios";
import Cookies from "universal-cookie";

import Login from "./auth/login";
import SignUp from "./auth/signUp";
import Type from "./auth/type";
import NewPassword from "./auth/newPassword";
import ForgotPassword from "./auth/forgotPassword";

import Account from "./dashboard/setting/account";
import Password from "./dashboard/setting/password";

// dashboard
import Dashboard from "./dashboard/index";
import Library from "./dashboard/library";
import Detail from "./dashboard/detail/detail";
import UploadGame from "./dashboard/upload/create/index";
import MyGame from "./dashboard/my/index";
import EditGame from "./dashboard/upload/edit/index";
import Review from "./dashboard/qa/review/index";
import ReviewEdit from "./dashboard/qa/review/edit/index";
import User from "./dashboard/user";

// others
import Error from "./dashboard/errors/index";
import Privacy from "./dashboard/privacy/index";
import Terms from "./dashboard/terms/index";

// context
import UserContext from "../context/UserContext";

const Index = (props) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  const login = localStorage.getItem("isLoggedIn");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const cookies = new Cookies();

  const chceckLogin = async (token) => {
    const api = await cookies.get("jwt");

    if (cookies.get("jwt") == undefined) {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_URL + "/");
    }
  };

  useEffect(async () => {}, []);

  const checkType = () => {
    chceckLogin();
    if (JSON.parse(localStorage.getItem("userData")).type == null) {
      if (JSON.parse(localStorage.getItem("userData")).role == "admin") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const checkRole = () => {
    chceckLogin();
    if (JSON.parse(localStorage.getItem("userData")).role == "admin") {
      return true;
    } else {
      return false;
    }
  };

  const justControl = () => {
    chceckLogin();
    if (
      JSON.parse(localStorage.getItem("userData")).role == "admin" ||
      JSON.parse(localStorage.getItem("userData")).type == "creator"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Router forceRefresh>
        <UserContext.Provider value={{ user }}>
          <Switch>
            <Route exact path="/">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/dashboard/library" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Login />
              )}
            </Route>
            <Route exact path="/login">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/dashboard/library" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Login />
              )}
            </Route>
            <Route exact path="/forgot-password">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/dashboard/library" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <ForgotPassword />
              )}
            </Route>
            <Route exact path="/sign-up">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/dashboard/library" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <SignUp />
              )}
            </Route>
            <Route exact path="/type-user">
              {login ? <Type /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/new-password/:token">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/dashboard/library" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <NewPassword />
              )}
            </Route>
            <Route exact path="/dashboard">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <Dashboard />
                  ) : (
                    <Redirect to="/dashboard/library" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/library">
              {login ? (
                checkType() == true ? (
                  <Library />
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/detail/:slug">
              {login ? (
                checkType() == true ? (
                  <Detail />
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/upload">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <UploadGame />
                  ) : (
                    <Redirect to="/" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/my-game">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <MyGame />
                  ) : (
                    <Redirect to="/" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/my-game/edit/:slug">
              {login ? (
                checkType() == true ? (
                  justControl() == true ? (
                    <EditGame />
                  ) : (
                    <Redirect to="/" />
                  )
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/setting/account">
              {login ? (
                checkType() == true ? (
                  <Account />
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/setting/password">
              {login ? (
                checkType() == true ? (
                  <Password />
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/qa/add-review/:slug">
              {login ? (
                checkType() == true ? (
                  <Review />
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/qa/edit-review/:slug/:id/:idResult">
              {login ? (
                checkType() == true ? (
                  <ReviewEdit />
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/dashboard/user/apply">
              {login ? (
                checkRole() == true ? (
                  <User />
                ) : (
                  <Redirect to="/type-user" />
                )
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/dashboard/privacy">
              {login ? <Privacy /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/dashboard/terms">
              {login ? <Terms /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="*">
              {login ? <Error /> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </UserContext.Provider>
      </Router>
    </Fragment>
  );
};

export default Index;
