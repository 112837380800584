import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const Index = (props) => {
  return (
    <Fragment>
      <div className="wrapper__footer bg__white mt-3">
        <div className="container">
          <div className="footer">
            <div className="d-flex justify-content-md-between justify-content-center align-items-center flex-wrap">
              <ul className="menu mb-4 mb-md-0 text-center text-md-left">
                <li>
                  <a
                    href="#!"
                    className="color__softblue font__size--14 text__14-1024"
                  >
                    About us
                  </a>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/terms"
                    className="color__softblue font__size--14 text__14-1024"
                  >
                    Terms & Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/privacy"
                    className="color__softblue font__size--14 text__14-1024"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
              <ul className="menu">
                <li>
                  <a
                    href="#!"
                    className="color__softblue font__size--14 text__14-1024"
                  >
                    © 2021 WOWSEE. All rights reserved
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Index;
