import React, { useEffect, Fragment } from "react";
import Anime from "animejs/lib/anime.es.js";

const SvgLine2 = (params) => {
  useEffect(() => {
    const path = Anime.path("path");
    Anime({
      targets: ".move__lineH",
      strokeDashoffset: [Anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      direction: "alternate",
      delay: function (el, i) {
        return i * 100;
      },
      loop: true,
    });
  }, []);
  return (
    <Fragment>
      <svg
        className="path__49"
        width="538"
        height="1060"
        viewBox="0 0 538 1060"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M536.519 309.437L381.367 154.285V3.87109"
          stroke="#319CC9"
          stroke-width="2"
        />
        <rect
          x="319.783"
          y="-5.60352"
          width="33.1622"
          height="1065.93"
          fill="#319CC9"
        />
        <path
          d="M100.673 610.264L182.395 691.985V631.583L131.467 580.655V79.6696H100.673V610.264Z"
          fill="white"
        />
        <path
          d="M210.819 657.639V1059.14H292.54V530.913L210.819 449.191V-7.97266H2.37109V449.191L210.819 657.639Z"
          fill="#16C79A"
          stroke="#16C79A"
          stroke-width="4"
        />
        <path
          d="M294.909 529.728L213.188 448.007V508.41L264.115 559.337V1060.32H294.909V529.728Z"
          fill="#0BAB82"
        />
        <path
          d="M-0.000350952 509.594L81.7207 591.315V530.913L30.7931 479.985V-21.0004H-0.000350952V509.594Z"
          fill="#0BAB82"
        />
        <g filter="url(#filter0_i)">
          <path
            d="M246.352 378.401V-5.60352H283.067V416.03L246.352 378.401Z"
            fill="#319CC9"
          />
        </g>
        <g filter="url(#filter1_i)">
          <path
            d="M100.672 655.271L180.024 734.697V860.166L100.672 780.741V655.271Z"
            fill="white"
          />
        </g>
        <path
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          d="M100.672 828.188L180.024 907.614V1033.08L100.672 953.657V828.188Z"
          stroke="#16C79A"
        />
        <path
          d="M100.672 1001.11L180.024 1080.53V1206L100.672 1126.57V1001.11Z"
          fill="white"
        />
        <g filter="url(#filter2_i)">
          <path
            d="M396.762 231.27L498.617 333.191V494.198L396.762 392.276V231.27Z"
            fill="white"
          />
        </g>
        <path
          d="M396.762 416.03L498.617 517.286V545.125L396.762 444.718V416.03Z"
          fill="#16C79A"
        />
        <path
          d="M396.762 463.405L498.617 564.661V592.5L396.762 492.093V463.405Z"
          fill="#16C79A"
        />
        <path
          d="M394.395 573.55L495.658 674.813V912.87"
          className="move__lineH"
          style={{ strokeDashoffset: "0px" }}
          stroke="white"
          stroke-opacity="0.39"
          stroke-width="4"
        />
        <defs>
          <filter
            id="filter0_i"
            x="236.352"
            y="-15.6035"
            width="46.7153"
            height="431.633"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-12" dy="-35" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter1_i"
            x="84.6719"
            y="645.271"
            width="95.3523"
            height="214.895"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-16" dy="-10" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter2_i"
            x="380.762"
            y="221.27"
            width="117.855"
            height="272.929"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-16" dy="-10" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
        </defs>
      </svg>
    </Fragment>
  );
};

export default SvgLine2;
