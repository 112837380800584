import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";

import Status from "./../button/Status";
import TagGame from "./../button/tagGame";

const needAction = (props) => {
  return (
    <Fragment>
      <NavLink
        to={`/dashboard/detail/${props.item.slug}`}
        className="wrapper__needAction d-inline-block w-100 mb-3"
      >
        <div className="d-flex justify-content-start align-items-center">
          <img src={props.item.url} className="preview" alt="" />
          <div className="ml-2">
            <h5 className="semi-bold font__size--14 color__darkblue mb-0 lh-1">
              {props.item.game_name}
            </h5>
            <div className="d-flex justify-content-start align-items-center">
              <img src={process.env.REACT_APP_URL + "images/wwws.png"} alt="" />{" "}
              <span className="font__size--12 text__12-1024 ml-2 semi-bold color__softblue">
                {dateFormat(props.item.created_at, "yyyy-mm-dd")}
              </span>
            </div>
          </div>
        </div>
        <hr className="opacity__5" />
        <div className="d-flex justify-content-between align-items-center">
          <TagGame name={props.item.name_category} />
          <Status status={props.item.name_status} className="px__8" />
        </div>
      </NavLink>
    </Fragment>
  );
};

export default needAction;
