import React, { Component, Fragment, useEffect } from "react";
import { $ } from "react-jquery-plugin";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

import Sidebar from "./../../../component/dashboard/sidebar/index";
import Navbar from "./../../../component/dashboard/navbar/index";
import Category from "./../../../component/dashboard/category/index";
import Game from "./../../../component/dashboard/card/game";
import GameList from "./../../../component/dashboard/card/gameList";
import Pagination from "./../../../component/dashboard/paginaton/index";
import Donut from "./../../../component/dashboard/statistic/donut";
import NeedAction from "../../../component/dashboard/card/needAction";
import Footer from "./../../../component/dashboard/footer/index";
import UserContext from "../../../context/UserContext";
import Loading from "../../../component/dashboard/card/loading";
import ModalConfirm from "../../../component/dashboard/modal/modalConfirm";

export default class index extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
      loading: true,
      approved: 0,
      submitted: 0,
      needAction: 0,
      rejected: 0,
      inQa: 0,
      vendor: [],
      totalGame: 0,
      modal: false,
      modalData: "",
      modalValidation: false,
      listGame: null,
      postListGame: null,
      gameNeed: [],
      offset: 0,
      data: [],
      perPage: 9,
      currentPage: 0,
      pageCount: 0,
      filter: {
        search: "",
        category: [],
        date: "all",
        status: [],
        vendor: [],
        onStay: null,
      },
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  componentDidMount() {
    this.getAllGame();
  }

  goto = () => {
    console.log("goto");
  };

  componentDidUpdate() {}

  getAllGame = async () => {
    const cookies = new Cookies();
    await axios
      .get(process.env.REACT_APP_API + `game/data/user`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          const data = res.data.data.gameDetail;
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          const listGame = slice.map((object) => (
            <Fragment>
              <GameList
                item={object}
                menu={true}
                toogleModal={(e) => this.toogleModal(e)}
              />
            </Fragment>
          ));

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listGame: listGame,
            loading: false,
            totalGame: data.length,
            vendor: res.data.data.vendor,
            totalGame: res.data.data.count,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  getFilterData = async () => {
    const cookies = new Cookies();
    this.setState({
      loading: true,
    });
    await axios
      .post(
        process.env.REACT_APP_API + `game/filter/my-game`,
        this.state.filter,
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(
        (res) => {
          const data = res.data.data;
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          const listGame = slice.map((object) => (
            <Fragment>
              <GameList
                item={object}
                menu={true}
                toogleModal={(e) => this.toogleModal(e)}
              />
            </Fragment>
          ));

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listGame: listGame,
            loading: false,
            totalGame: data.length,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  ClearFilter = () => {
    var x = document.getElementsByClassName("checkbox");
    for (var i = 0; i <= x.length; i++) {
      if (x[i] != undefined) {
        x[i].checked = false;
      }
    }
    const cookies = new Cookies();
    this.setState({
      loading: true,
    });
    axios
      .get(process.env.REACT_APP_API + `game/data/user`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          let gameInput = { ...this.state.filter };
          gameInput["date"] = "all";
          gameInput["category"] = [];
          gameInput["status"] = [];
          gameInput["vendor"] = [];

          const data = res.data.data.gameDetail;
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          const listGame = slice.map((object) => (
            <Fragment>
              <GameList
                item={object}
                menu={true}
                toogleModal={(e) => this.toogleModal(e)}
              />
            </Fragment>
          ));

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listGame: listGame,
            loading: false,
            totalGame: data.length,
            filter: gameInput,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  onChangeComplete = async (e) => {
    let gameInput = { ...this.state.filter };

    function checkCompability(compability) {
      return compability === e.target.value;
    }

    if (e.target.value != undefined) {
      if (gameInput[e.target.name].find(checkCompability) == e.target.value) {
        gameInput[e.target.name].splice(
          gameInput[e.target.name].indexOf(e.target.value),
          1
        );
      } else {
        gameInput[e.target.name].push(e.target.value);
      }
      this.getFilterData();
    }
  };

  onChangeDate = async (e) => {
    let gameInput = { ...this.state.filter };
    gameInput["date"] = e;
    await this.setState({
      filter: gameInput,
    });

    this.getFilterData();
  };

  onChangeSearch = async (e) => {
    let gameInput = { ...this.state.filter };
    gameInput["search"] = e;
    await this.setState({
      filter: gameInput,
    });
    this.getFilterData();
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAllGame();
      }
    );
  };

  removeGame() {
    const cookies = new Cookies();
    this.setState({
      modalValidation: true,
    });
    axios
      .get(process.env.REACT_APP_API + `delete/game/` + this.state.modalData, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          if (res.data.success == "success") {
            toast.success("Game deleted successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });

            this.setState({
              loading: true,
              modalData: "",
              modal: false,
              modalValidation: false,
            });

            this.getAllGame();
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
  }

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  toogleModal = (e) => {
    this.setState({
      modal: !this.state.modal,
      modalData: e,
    });
  };

  render() {
    const gameListLoading = [];

    for (var i = 0; i < 9; i++) {
      gameListLoading.push(<Loading status={"lineProfile"} />);
    }

    const category = (
      <Category
        vendor={this.state.vendor}
        loading={this.state.loading}
        toogleShowing={(a) => this.toogleShowing(a)}
        showing={this.state.showing}
        onChangeComplete={(e) => this.onChangeComplete(e)}
        onChangeDate={(e) => this.onChangeDate(e)}
        status={this.state.filter.status.length}
        filter={this.state.filter}
        ClearFilter={this.ClearFilter}
        grid={false}
        page={"mygame"}
      />
    );

    return (
      <Fragment>
        <div className="overflow-hidden dashboard">
          <ModalConfirm
            modal={this.state.modal}
            modalValidation={this.state.modalValidation}
            toogleModal={() => this.toogleModal()}
            title={"Are you sure you want to delete your game?"}
            goto={() => this.removeGame()}
            button={"Delete"}
          />
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Games"}
                onSearch={(e) => this.onChangeSearch(e)}
                valSearch={this.state.filter.search}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <div className="row">
                    <div className="col-12">
                      <div>{category}</div>

                      <div>
                        <div className="row">
                          <div className="col-10 col-md-4">
                            <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                              In Progress ({this.state.totalGame})
                            </h5>
                          </div>
                          <div className="col-2  d-none d-md-block">
                            <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                              Vendor
                            </h5>
                          </div>
                          <div className="col  d-none d-md-block">
                            <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                              Category
                            </h5>
                          </div>
                          <div className="col d-none d-md-block">
                            <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                              Date Added
                            </h5>
                          </div>
                          <div className="col  d-none d-md-block">
                            <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                              Status
                            </h5>
                          </div>
                          <div className="col col-md-1 pl-0 d-none d-md-block">
                            <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                              Action
                            </h5>
                          </div>
                        </div>
                        {this.state.loading
                          ? gameListLoading
                          : this.state.listGame}
                      </div>

                      <div className="text-center mt-3">
                        {this.state.loading ? (
                          gameListLoading
                        ) : (
                          <nav
                            aria-label="Page navigation example"
                            className="d-inline-block wrapper__paginate-rect"
                          >
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={
                                "page-link semi-bold fonr__size--14 color__black"
                              }
                              activeClassName={"active"}
                              previousClassName={"page-item"}
                              nextClassName={"page-item"}
                              previousLinkClassName={
                                "page-link semi-bold fonr__size--14 color__black"
                              }
                              nextLinkClassName={
                                "page-link semi-bold fonr__size--14 color__black"
                              }
                            />
                          </nav>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
