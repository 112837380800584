import React, { useEffect, Fragment } from "react";
import Anime from "animejs/lib/anime.es.js";

const SvgLine4 = (params) => {
  useEffect(() => {
    const path = Anime.path("path");
    Anime({
      targets: ".move__line",
      strokeDashoffset: [Anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      direction: "alternate",
      delay: function (el, i) {
        return i * 100;
      },
      loop: true,
    });
  }, []);
  return (
    <Fragment>
      <svg
        className="path__49"
        width="524"
        height="1060"
        viewBox="0 0 524 1060"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M144.692 250.139V0H255.342V377.857L344.215 466.73V754.536L255.342 843.409V1060H144.692V621.521L52.2881 529.117V342.543L144.692 250.139Z"
          fill="#0BAB82"
        />
        <path
          d="M363.048 546.774L221.793 405.519V1060H363.048V546.774Z"
          fill="#0BAB82"
        />
        <path
          d="M363.048 546.774L221.793 405.519V1060H363.048V546.774Z"
          fill="#0BAB82"
        />
        <path
          d="M154.697 364.908V0H387.179V364.908L241.804 510.283V1059.41H9.32227V510.283L154.697 364.908Z"
          fill="#16C79A"
        />
        <path
          d="M221.793 437.89L291.961 507.646V526.825L221.793 457.653V437.89Z"
          fill="white"
        />
        <path
          d="M221.793 483.582L291.961 553.337V572.516L221.793 503.345V483.582Z"
          fill="white"
        />
        <path
          d="M221.793 529.272L291.961 599.028V618.207L221.793 549.036V529.272Z"
          stroke="white"
          stroke-width="3"
        />
        <path
          d="M221.793 574.963L291.961 644.719V663.898L221.793 594.727V574.963Z"
          fill="white"
        />
        <g filter="url(#filter0_i)">
          <path
            d="M59.3516 652.127L-41.8811 753.426V913.448L59.3516 812.15V652.127Z"
            fill="white"
          />
        </g>
        <path
          d="M59.3516 835.758L-41.8811 936.395V964.064L59.3516 864.27V835.758Z"
          fill="white"
        />
        <path
          d="M59.3516 882.843L-41.8811 983.48V1011.15L59.3516 911.355V882.843Z"
          fill="#319CC9"
        />
        <path
          d="M310.08 950.684H310.58V950.477L310.433 950.33L310.08 950.684ZM181.774 822.483H181.274V822.69L181.42 822.836L181.774 822.483ZM181.774 733.993L178.887 736.88L181.774 739.766L184.66 736.88L181.774 733.993ZM310.58 1059.41V950.684H309.58V1059.41H310.58ZM310.433 950.33L182.127 822.129L181.42 822.836L309.727 951.038L310.433 950.33ZM182.274 822.483V736.88H181.274V822.483H182.274Z"
          fill="white"
        />
        <path
          d="M181.773 108.728H181.273V108.935L181.42 109.081L181.773 108.728ZM310.08 236.929H310.58V236.722L310.433 236.575L310.08 236.929ZM310.08 325.419L312.967 322.532L310.08 319.645L307.193 322.532L310.08 325.419ZM181.273 0V108.728H182.273V0H181.273ZM181.42 109.081L309.727 237.283L310.433 236.575L182.127 108.374L181.42 109.081ZM309.58 236.929V322.532H310.58V236.929H309.58Z"
          fill="white"
        />
        <g filter="url(#filter1_i)">
          <path
            d="M93.4883 337.703V0H129.979V370.794L93.4883 337.703Z"
            fill="#319CC9"
          />
        </g>
        <g filter="url(#filter2_i)">
          <path
            d="M481.098 868.717L421.905 809.47V715.876L481.098 775.123V868.717Z"
            fill="#16C79A"
          />
        </g>
        <path
          d="M481.098 739.73L421.905 680.483V586.889L481.098 646.136V739.73Z"
          stroke="#319CC9"
          stroke-width="3"
        />
        <path
          d="M481.098 610.742L421.905 551.495V457.901L481.098 517.148V610.742Z"
          fill="white"
        />
        <path
          d="M-61.8926 96.5244L8.27589 166.28V185.459L-61.8926 116.288V96.5244Z"
          fill="#319CC9"
        />
        <path
          d="M-61.8926 142.215L8.27589 211.971V231.15L-61.8926 161.979V142.215Z"
          fill="#319CC9"
        />
        <path
          d="M-61.8926 187.906L8.27589 257.662V276.841L-61.8926 207.669V187.906Z"
          stroke="#319CC9"
          stroke-width="3"
        />
        <path
          d="M-61.8926 233.598L8.27589 303.353V322.532L-61.8926 253.361V233.598Z"
          fill="#319CC9"
        />
        <path
          d="M521.502 96.5244L451.333 166.28V185.459L521.502 116.288V96.5244Z"
          fill="#319CC9"
        />
        <path
          d="M521.502 142.215L451.333 211.971V231.15L521.502 161.979V142.215Z"
          fill="#319CC9"
        />
        <path
          d="M521.502 187.906L451.333 257.662V276.841L521.502 207.669V187.906Z"
          stroke="#319CC9"
          stroke-width="3"
        />
        <path
          d="M521.502 233.598L451.333 303.353V322.532L521.502 253.361V233.598Z"
          fill="#319CC9"
        />
        <defs>
          <filter
            id="filter0_i"
            x="-57.8809"
            y="642.127"
            width="117.233"
            height="271.321"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-16" dy="-10" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter1_i"
            x="83.4883"
            y="-10"
            width="46.4908"
            height="380.794"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-12" dy="-35" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter2_i"
            x="405.904"
            y="705.876"
            width="75.1929"
            height="162.841"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-16" dy="-10" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
        </defs>
      </svg>
    </Fragment>
  );
};

export default SvgLine4;
