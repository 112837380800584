import React, { Component, Fragment, useEffect } from "react";
import { $ } from "react-jquery-plugin";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "./../../component/dashboard/sidebar/index";
import Navbar from "./../../component/dashboard/navbar/index";
import Category from "./../../component/dashboard/category/index";
import Game from "./../../component/dashboard/card/game";
import GameList from "./../../component/dashboard/card/gameList";
import Pagination from "./../../component/dashboard/paginaton/index";
import Donut from "./../../component/dashboard/statistic/donut";
import NeedAction from "../../component/dashboard/card/needAction";
import Footer from "./../../component/dashboard/footer/index";
import UserContext from "../../context/UserContext";
import Loading from "../../component/dashboard/card/loading";
import ModalStatus from "../../component/dashboard/modal/modalStatus";

export default class index extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
      loading: true,
      approved: 0,
      submitted: 0,
      needAction: 0,
      rejected: 0,
      inQa: 0,
      vendor: [],
      listGame: null,
      postListGame: null,
      gameNeed: [],
      offset: 0,
      data: [],
      perPage: 9,
      currentPage: 0,
      pageCount: 0,
      modalStatus: false,
      selectStatusGame: "Submitted",
      selectSlug: "",
      filter: {
        search: "",
        category: [],
        date: "all",
        status: [],
        vendor: [],
        onStay: null,
      },
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }
  componentDidMount() {
    this.getAllGame();
  }

  componentDidUpdate() {}

  getAllGame = async () => {
    const cookies = new Cookies();
    await axios
      .get(process.env.REACT_APP_API + `game/all-data`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          const data = res.data.data.gameDetail;
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          const listGame = slice.map((object) => (
            <Fragment>
              <div className="col-xl-4 col-lg-3 col-sm-6 mb-4">
                <Game
                  toogleModalStatus={(e, a) => this.toogleModalStatus(e, a)}
                  typeStatus={"select"}
                  item={object}
                />
              </div>
            </Fragment>
          ));
          const postListGame = slice.map((object) => (
            <Fragment>
              <GameList
                toogleModalStatus={(e, a) => this.toogleModalStatus(e, a)}
                typeStatus={"select"}
                item={object}
              />
            </Fragment>
          ));

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listGame: listGame,
            postListGame: postListGame,
            loading: false,
            gameNeed: res.data.data.gameNeed,
            approved: res.data.data.approved,
            submitted: res.data.data.submitted,
            needAction: res.data.data.needAction,
            rejected: res.data.data.rejected,
            inQa: res.data.data.inQa,
            vendor: res.data.data.vendor,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  getFilterData = () => {
    const cookies = new Cookies();
    this.setState({
      loading: true,
    });
    axios
      .post(process.env.REACT_APP_API + `game/filter`, this.state.filter, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          const data = res.data.data;
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          const listGame = slice.map((object) => (
            <Fragment>
              <div className="col-xl-4 col-lg-3 col-sm-6 mb-4">
                <Game
                  toogleModalStatus={(e, a) => this.toogleModalStatus(e, a)}
                  typeStatus={"select"}
                  item={object}
                />
              </div>
            </Fragment>
          ));
          const postListGame = slice.map((object) => (
            <Fragment>
              <GameList
                toogleModalStatus={(e, a) => this.toogleModalStatus(e, a)}
                typeStatus={"select"}
                item={object}
              />
            </Fragment>
          ));

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listGame: listGame,
            postListGame: postListGame,
            loading: false,
            totalGame: data.length,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  updateStatus = async (e) => {
    const cookies = new Cookies();
    await axios
      .post(
        process.env.REACT_APP_API + `game/status/${this.state.selectSlug}`,
        {
          status: e,
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.get("jwt"),
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(
        (res) => {
          toast.success("Status updated successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          this.getFilterData();
          this.setState({
            modalStatus: false,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  async toogleModalStatus(e, a) {
    await this.setState({
      modalStatus: !this.state.modalStatus,
      selectSlug: a ? a : "",
      selectStatusGame: e ? e : this.state.selectStatusGame,
    });
  }

  ClearFilter = () => {
    var x = document.getElementsByClassName("checkbox");
    for (var i = 0; i <= x.length; i++) {
      if (x[i] != undefined) {
        x[i].checked = false;
      }
    }
    const cookies = new Cookies();
    this.setState({
      loading: true,
    });
    axios
      .get(process.env.REACT_APP_API + `game/all-data/approved`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          let gameInput = { ...this.state.filter };
          gameInput["date"] = "all";
          gameInput["category"] = [];
          gameInput["status"] = [];
          gameInput["vendor"] = [];

          const data = res.data.data.gameDetail;
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          const listGame = slice.map((object) => (
            <Fragment>
              <div className="col-xl-3 col-lg-4 col-sm-6 mb-4">
                <Game
                  toogleModalStatus={(e, a) => this.toogleModalStatus(e, a)}
                  typeStatus={"select"}
                  item={object}
                />
              </div>
            </Fragment>
          ));
          const postListGame = slice.map((object) => (
            <Fragment>
              <GameList
                toogleModalStatus={(e, a) => this.toogleModalStatus(e, a)}
                typeStatus={"select"}
                item={object}
              />
            </Fragment>
          ));

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listGame: listGame,
            postListGame: postListGame,
            loading: false,
            totalGame: data.length,
            filter: gameInput,
          });
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  onChangeComplete = async (e) => {
    let gameInput = { ...this.state.filter };

    function checkCompability(compability) {
      return compability === e.target.value;
    }

    if (e.target.value != undefined) {
      if (gameInput[e.target.name].find(checkCompability) == e.target.value) {
        gameInput[e.target.name].splice(
          gameInput[e.target.name].indexOf(e.target.value),
          1
        );
      } else {
        gameInput[e.target.name].push(e.target.value);
      }
      this.getFilterData();
    }
  };

  onChangeDate = (e) => {
    let gameInput = { ...this.state.filter };
    gameInput["date"] = e;
    this.setState({
      filter: gameInput,
    });
    this.getFilterData();
  };

  onChangeSearch = async (e) => {
    let gameInput = { ...this.state.filter };
    gameInput["search"] = e;
    await this.setState({
      filter: gameInput,
    });
    this.getFilterData();
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAllGame();
      }
    );
  };

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  render() {
    const gameLoading = [];

    for (var i = 0; i < 9; i++) {
      gameLoading.push(
        <div className="col-xl-4 col-lg-3 col-sm-6 mb-4">
          <Loading status="card" />
        </div>
      );
    }

    const gameListLoading = [];

    for (var i = 0; i < 9; i++) {
      gameListLoading.push(<Loading status={"lineProfile"} />);
    }

    const needActionLoading = [];

    for (var i = 0; i < 5; i++) {
      needActionLoading.push(<Loading status="need" />);
    }

    const category = (
      <Category
        toogleShowing={(a) => this.toogleShowing(a)}
        showing={this.state.showing}
        statusMenu="active"
        vendor={this.state.vendor}
        loading={this.state.loading}
        onChangeComplete={(e) => this.onChangeComplete(e)}
        onChangeDate={(e) => this.onChangeDate(e)}
        status={this.state.filter.status.length}
        filter={this.state.filter}
        ClearFilter={this.ClearFilter}
      />
    );

    return (
      <Fragment>
        <ToastContainer />
        <div className="overflow-hidden dashboard">
          {this.state.loading != true ? (
            <ModalStatus
              modalStatus={this.state.modalStatus}
              toogleModalStatus={() => this.toogleModalStatus()}
              status={this.state.selectStatusGame}
              loading={this.state.loading}
              updateStatus={(e) => this.updateStatus(e)}
            />
          ) : null}
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Games"}
                onSearch={(e) => this.onChangeSearch(e)}
                valSearch={this.state.filter.search}
              />
              <div className="container min-height-100">
                <div className="mt-4">
                  <div className="row">
                    <div className="order-xl-1 order-12 col-xl-9">
                      <div className="d-none d-xl-block">{category}</div>

                      {this.state.showing == "grid" ? (
                        <div>
                          {" "}
                          <h5 className="my-4 semi-bold font__size--16 text__16-1024">
                            In Progress (
                            {this.state.approved +
                              this.state.submitted +
                              this.state.needAction +
                              this.state.rejected +
                              this.state.inQa}
                            )
                          </h5>
                          <div className="row">
                            {this.state.loading
                              ? gameLoading
                              : this.state.listGame}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-4">
                              <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                                In Progress (
                                {this.state.approved +
                                  this.state.submitted +
                                  this.state.needAction +
                                  this.state.rejected +
                                  this.state.inQa}
                                )
                              </h5>
                            </div>
                            <div className="col-2">
                              <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                                Vendor
                              </h5>
                            </div>
                            <div className="col">
                              <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                                Category
                              </h5>
                            </div>
                            <div className="col">
                              <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                                Date Added
                              </h5>
                            </div>
                            <div className="col">
                              <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                                Status
                              </h5>
                            </div>
                            <div className="col-1 pl-0">
                              <h5 className="my-4 semi-bold font__size--13 text__16-1024">
                                Action
                              </h5>
                            </div>
                          </div>
                          {this.state.loading
                            ? gameListLoading
                            : this.state.postListGame}
                        </div>
                      )}

                      <div className="text-center mt-3">
                        {this.state.loading ? (
                          gameListLoading
                        ) : (
                          <nav
                            aria-label="Page navigation example"
                            className="d-inline-block wrapper__paginate-rect"
                          >
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={
                                "page-link semi-bold fonr__size--14 color__black"
                              }
                              activeClassName={"active"}
                              previousClassName={"page-item"}
                              nextClassName={"page-item"}
                              previousLinkClassName={
                                "page-link semi-bold fonr__size--14 color__black"
                              }
                              nextLinkClassName={
                                "page-link semi-bold fonr__size--14 color__black"
                              }
                            />
                          </nav>
                        )}
                      </div>
                    </div>
                    <div className="order-1 order-xl-12 col-xl-3">
                      <div className="d-xl-none mb-4">{category}</div>
                      <div className="wrapper__statistic bg__white">
                        <h5 className="semi-bold color__darkblue font__size--16 text__16-1024 mb-4">
                          Game Statistic
                        </h5>
                        <Donut
                          approved={this.state.approved}
                          submitted={this.state.submitted}
                          needAction={this.state.needAction}
                          rejected={this.state.rejected}
                          inQa={this.state.inQa}
                        />

                        <div className="mt-4">
                          <div className="d-flex justify-content-between">
                            <ul className="list__statistic">
                              <li>
                                <div className="d-flex justify-content-start">
                                  <div className="circle tosca"></div>
                                  <span className="semi-bold font__size--10 opacity__5 lh__4 ml-2">
                                    Approved ({this.state.approved})
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex justify-content-start">
                                  <div className="circle darkblue"></div>
                                  <span className="semi-bold font__size--10 opacity__5 lh__4 ml-2">
                                    Submitted ({this.state.submitted})
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex justify-content-start">
                                  <div className="circle purple"></div>
                                  <span className="semi-bold font__size--10 opacity__5 lh__4 ml-2">
                                    Need Action ({this.state.needAction})
                                  </span>
                                </div>
                              </li>
                            </ul>
                            <ul className="list__statistic">
                              <li>
                                <div className="d-flex justify-content-start">
                                  <div className="circle red"></div>
                                  <span className="semi-bold font__size--10 opacity__5 lh__4 ml-2">
                                    Rejected ({this.state.rejected})
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex justify-content-start">
                                  <div className="circle yellow"></div>
                                  <span className="semi-bold font__size--10 opacity__5 lh__4 ml-2">
                                    in QA ({this.state.inQa})
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="d-xl-block d-none">
                        <h3 className="semi-bold font__size--16 text__16-1024 color__darkblue mt-4 mb-3">
                          Games that Need Action{" "}
                        </h3>

                        {this.state.loading
                          ? needActionLoading
                          : this.state.gameNeed.map(function (object, i) {
                              return <NeedAction item={object} />;
                            })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
