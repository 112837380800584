import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

import Status from "./../button/Status";
const Index = (props) => {
  const [categoty, setCategoty] = useState(false);
  const [date, setDate] = useState(false);
  const [status, setStatus] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [listVendor, setListVendor] = useState();
  const [loading, setLoading] = useState(true);

  const [filter, setFilter] = useState(true);

  const filterCategory = [
    {
      logo: "images/crossed 1.png",
      label: "Action",
    },
    {
      logo: "images/compass 1.png",
      label: "Adventure",
    },
    {
      logo: "images/college-graduation 1.png",
      label: "Educational",
    },
    {
      logo: "images/extension 1.png",
      label: "Puzzle",
    },
    {
      logo: "images/checkered-flag 1.png",
      label: "Racing",
    },
    {
      logo: "images/ball-of-basketball 1.png",
      label: "Sport",
    },
    {
      logo: "images/baby-book 1.png",
      label: "Story",
    },
    {
      logo: "images/chess 1.png",
      label: "Strategy",
    },
  ];

  const filterStatus = [
    "Need Action",
    "Approved",
    "Submitted",
    "Rejected",
    "in QA",
  ];

  const filterDate = [
    {
      name: "All Time",
      value: "all",
    },
    {
      name: "Today",
      value: "today",
    },
    {
      name: "This Week",
      value: "week",
    },
    {
      name: "This Month",
      value: "month",
    },
  ];

  const filterVendor = [
    "Enver Studio",
    "Gameloft",
    "Pebblepic Inc",
    "Cyrus Studio",
    "Artemist Inc",
  ];

  const getVendor = async () => {
    const cookies = new Cookies();
    await axios
      .get(process.env.REACT_APP_API + `game/filter/vendor`, {
        headers: {
          Authorization: "Bearer " + cookies.get("jwt"),
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(
        (res) => {
          setListVendor(res.data.data);
          setLoading(false);
        },
        (err) => {
          console.log("error ", err);
        }
      );
  };

  useEffect(() => {
    // getVendor();
  });

  return (
    <Fragment>
      <div className="wrapper__category d-lg-flex justify-content-between align-items-center">
        {props.statusMenu != "active" ? (
          <Fragment>
            <div
              onClick={() => setFilter(!filter)}
              className="wrapper__filter-drop d-md-none d-flex justify-content-between align-items-center py-3"
            >
              <h5 className="semi-bold font__size--14">Filter</h5>
              <img
                src="./../images/fffds.png"
                className={"arrow " + (filter ? "show" : "")}
                alt=""
              />
            </div>
            <div
              className={
                "input__select d-flex justify-content-start align-items-center " +
                (filter ? "show" : "")
              }
            >
              <div className="grid position-relative">
                <div
                  className="select pointer"
                  onClick={() => setCategoty(!categoty)}
                >
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <span className="semi-bold font__size--12">Category</span>
                    <img
                      src={process.env.REACT_APP_URL + "images/asa.png"}
                      className="ml-2"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    "wrapper__list-menu-filter " + (categoty ? "active" : "")
                  }
                >
                  {filterCategory.map(function (object, i) {
                    return (
                      <div className="list d-flex justify-content-start align-items-center">
                        <input
                          type="checkbox"
                          className={"checkbox"}
                          id={object.label}
                          name="category"
                          value={object.label}
                          onClick={props.onChangeComplete}
                        />
                        <label
                          onClick={(e) => props.onChangeComplete(e)}
                          htmlFor={object.label}
                          className="d-flex justify-content-start align-items-center ml-2"
                        >
                          <img
                            src={process.env.REACT_APP_URL + object.logo}
                            className="object-fit-content mr-2"
                            alt=""
                          />{" "}
                          <span className="semi-bold font__size--12 text">
                            {object.label}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="grid position-relative">
                <div className="select pointer" onClick={() => setDate(!date)}>
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <span className="semi-bold font__size--12">Date</span>
                    <img
                      src={process.env.REACT_APP_URL + "images/asa.png"}
                      className="ml-2"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    "wrapper__list-menu-filter status " + (date ? "active" : "")
                  }
                >
                  {filterDate.map(function (object, i) {
                    return (
                      <div
                        className={
                          "list pointer " +
                          (props.filter.date == object.value ? "choice" : null)
                        }
                        onClick={(e) => props.onChangeDate(object.value)}
                      >
                        <span className="semi-bold font__size--12 text">
                          {object.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              {props.page == "mygame" ? (
                <div className="grid position-relative">
                  <div
                    className="select pointer"
                    onClick={() => setStatus(!status)}
                  >
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <span className="semi-bold font__size--12">
                        Status{" "}
                        <span className="color__blue">({props.status})</span>
                      </span>
                      <img
                        src={process.env.REACT_APP_URL + "images/asa.png"}
                        className="ml-2"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className={
                      "wrapper__list-menu-filter status " +
                      (status ? "active" : "")
                    }
                  >
                    {filterStatus.map(function (object, i) {
                      return (
                        <div className="list d-flex justify-content-start align-items-center">
                          <input
                            type="checkbox"
                            className={"checkbox"}
                            id={object}
                            name="status"
                            value={object}
                            onClick={props.onChangeComplete}
                          />
                          <label
                            onClick={props.onChangeComplete}
                            htmlFor={object}
                            className=" ml-2"
                          >
                            <Status status={object} />
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              <div className="grid position-relative">
                <div
                  className="select pointer"
                  onClick={() => setVendor(!vendor)}
                >
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <span className="semi-bold font__size--12">Vendor</span>
                    <img
                      src={process.env.REACT_APP_URL + "images/asa.png"}
                      className="ml-2"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    "wrapper__list-menu-filter vendor status " +
                    (vendor ? "active" : "")
                  }
                >
                  {props.vendor.map(function (object, i) {
                    return (
                      <div className="list d-flex justify-content-start align-items-start">
                        <input
                          type="checkbox"
                          id={"vendor" + i}
                          name="vendor"
                          value={object.name}
                          className="input_filter checkbox"
                          onClick={props.onChangeComplete}
                        />
                        <label
                          htmlFor={"vendor" + i}
                          onClick={props.onChangeComplete}
                          className="text-capitalize m-0 lh-1 font__size--14 text__14-1024 ml-2"
                        >
                          {object.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="grid">
                {props.filter.category.length +
                  props.filter.status.length +
                  props.filter.vendor.length >
                0 ? (
                  <Fragment>
                    <div
                      className="pointer remove__filter"
                      onClick={props.ClearFilter}
                    >
                      <img
                        src={process.env.REACT_APP_URL + "images/sas.png"}
                        alt=""
                      />
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="grid position-relative">
            <div className="select pointer" onClick={() => setStatus(!status)}>
              <div className="d-flex justify-content-between w-100 align-items-center">
                <span className="semi-bold font__size--12">
                  Status <span className="color__blue">({props.status})</span>
                </span>
                <img
                  src={process.env.REACT_APP_URL + "images/asa.png"}
                  className="ml-2"
                  alt=""
                />
              </div>
            </div>
            <div
              className={
                "wrapper__list-menu-filter status " + (status ? "active" : "")
              }
            >
              {filterStatus.map(function (object, i) {
                return (
                  <div className="list d-flex justify-content-start align-items-center">
                    <input
                      type="checkbox"
                      className={"checkbox"}
                      id={object}
                      name="status"
                      value={object}
                      onClick={props.onChangeComplete}
                    />
                    <label
                      onClick={props.onChangeComplete}
                      htmlFor={object}
                      className=" ml-2"
                    >
                      <Status status={object} />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {props.grid != false ? (
          <div className="showing d-none d-lg-block">
            <div className="d-flex justify-content-end align-items-center">
              <div
                className={
                  "pointer mr-3 " + (props.showing == "grid" ? "active" : "")
                }
                onClick={() => props.toogleShowing("grid")}
              >
                <img
                  src={process.env.REACT_APP_URL + "images/sadsdasad.png"}
                  alt=""
                />
              </div>
              <div
                className={
                  "pointer " + (props.showing == "list" ? "active" : "")
                }
                onClick={() => props.toogleShowing("list")}
              >
                <img
                  src={process.env.REACT_APP_URL + "images/sadasdsa.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Index;
