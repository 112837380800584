import React, { Fragment, useState, useEffect } from "react";
import Status from "../button/Status";

const ModalStatus = (props) => {
  const [statusData, setStatusData] = useState([
    "Need Action",
    "Approved",
    "Submitted",
    "Rejected",
    "in QA",
  ]);
  const [showStatus, setShowStatus] = useState(false);
  const [selected, setSelected] = useState("");
  const SelectedStatus = (e) => {
    setSelected(e);
    setShowStatus(!showStatus);
  };
  useEffect(() => {
    // setSelected(props.status);
  }, []);
  return (
    <Fragment>
      <div
        className={
          "modal wrapper__modal-app fade " + (props.modalStatus ? "show" : "")
        }
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              {props.modalValidation ? (
                <h5 className="font__size--16 semi-bold mb-3">
                  delete in progress...
                </h5>
              ) : (
                <Fragment>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="font__size--16 semi-bold">Change Status</h5>
                    <img
                      onClick={() => props.toogleModalStatus()}
                      src={process.env.REACT_APP_URL + "images/vfaaw.png"}
                      className="pointer"
                      alt=""
                    />
                  </div>
                  <div className="wrapper__select-status position-relative">
                    <div
                      onClick={() => setShowStatus(!showStatus)}
                      className={
                        "input pointer d-flex justify-content-between align-items-center " +
                        (showStatus ? "active" : null)
                      }
                    >
                      <div className="">
                        {props.loading != true ? (
                          <Status status={selected != "" ? selected : props.status} />
                        ) : null}
                      </div>
                      <img
                        src={process.env.REACT_APP_URL + "images/asa.png"}
                        alt=""
                        className="arrow"
                      />
                    </div>
                    <div className="dropdown">
                      {statusData.map((obj, i) => {
                        return (
                          <div
                            className="text-right pointer"
                            onClick={() => SelectedStatus(obj)}
                          >
                            <Status status={obj} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    onClick={(e) => props.updateStatus(selected)}
                    className="btn btn__blue color__white semi-bold text-center shadow w-100 mt-3"
                  >
                    Update
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModalStatus;
