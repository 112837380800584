import React, { Component, Fragment } from "react";
import { $ } from "react-jquery-plugin";
import { NavLink } from "react-router-dom";
import axios from "axios";

import Sidebar from "./../../../component/dashboard/sidebar/index";
import Navbar from "./../../../component/dashboard/navbar/index";
import Footer from "./../../../component/dashboard/footer/index";
import SvgLine from "./svgLine";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menu: false,
      showing: "grid",
      search: false,
    };
  }
  componentDidMount() {}

  componentDidUpdate() {}

  toogleSidenav = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  toogleShowing(a) {
    this.setState({
      showing: a,
    });
  }

  toogleSearch() {
    this.setState({
      search: !this.state.search,
    });
  }

  render() {
    return (
      <Fragment>
        <div className="overflow-hidden dashboard">
          <div className="wrapper__dashboard position-relative">
            <Sidebar
              menu={this.state.menu}
              toogleSidenav={() => this.toogleSidenav()}
            />
            <div className="content">
              <Navbar
                search={this.state.search}
                toogleSearch={() => this.toogleSearch()}
                toogleSidenav={() => this.toogleSidenav()}
                breadcrumb={"Games"}
                type={"list"}
              />
              <div className="container min-height-100">
                <div className="min-height-100 w-100 d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <SvgLine />
                    <h5 className="font__size--24 text__24-1024 bold mt-4">
                      Oops! looks like you have lost
                    </h5>
                    <p className="font__size--14 text__14-1024 opacity__7">
                      Sorry, the page you are looking for cannot be found
                    </p>
                    <NavLink to="/" className="d-inline-block semi-bold font__size--14 text__14-1024 color__gray-5 btn btn__blue shadow">
                      Go Back
                    </NavLink>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
